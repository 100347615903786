import React, { Fragment, memo} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { ToastContainer} from 'react-toastify'
import Notifier from './Notifier';
import NavBar from './components/NavBar';
import Support from './components/Support';
import MyTournaments from './components/Lichess/Tournaments/MyTournaments';
import Tournament from './components/Lichess/Tournaments/Tournament';
import BuyLichessTickets from './components/Lichess/BuyTickets';
import Account from './components/Lichess/Account';
import { useStyles } from './components/MuiStyles.js';
import Payouts from './components/Lichess/Payouts/Payouts';
import Footer from './components/Footer';
import Landing from './components/Landing';
import 'react-toastify/dist/ReactToastify.css';
import CreateTournament from './components/Lichess/Tournaments/CreateTournament';
import Tournaments from './components/Lichess/Tournaments/Tournaments';
import PayoutChartPage from './components/Lichess/Payouts/PayoutChartPage';
import StateModifier from './StateModifier';
import CreateWager from './components/Lichess/Wagers/CreateWager';
import WagerLobby from './components/Lichess/Wagers/Lobby';
import Wager from './components/Lichess/Wagers/Wager';
import MyWagers from './components/Lichess/Wagers/MyWagers';
import PlayButton from './components/PlayButton';
import Lobby from './components/Lichess/Lobby'
import GeoLocation from './legal/GeoLocation';
import RegionUnsupportedDialog from './legal/RegionUnsupported';
import './Atoms';
import Policies from './components/Policies.js';
import RedirectToExternal from './components/RedirectToExternal.js';
import PoliciesPage from './components/PoliciesPage.js';
import LiveWagerLobby from './components/Lichess/LiveWagering/LiveWagerLobby.js';
import TournamentAsync from './components/Lichess/TournamentsAsync/TournamentAsync.js';
import MyTournamentsAsync from './components/Lichess/TournamentsAsync/MyTournamentsAsync.js';

function App() {

  const classes = useStyles();

  

  
  return (
   
    <Router>

      <Fragment>
    
        <div  className='App'>

          <NavBar/>

          <div className={classes.AppBody}>

            <Routes>
              <Route path='/' exact={true} element={<Landing/>}/>

              <Route path='/lobby' element={<Lobby/>}/>

              <Route path='/support' element={<Support/>}/>
              <Route path='/policies' element={<PoliciesPage/>}/>
              <Route path='/dispute-form' element={<RedirectToExternal link={'https://forms.gle/8GbavDDiCzEfGHA87'} />}/>

              <Route path='/tournaments' exact={true} element={<Tournaments/>}/>
              <Route path='/tournament/:tournamentId' element={<Tournament />} />
              <Route path='/flex/:tournamentId' element={<TournamentAsync/>} />
          
              <Route path='/create-tournament'  element={<CreateTournament/>}/>
              <Route path='/payouts/chart' element={<PayoutChartPage />} />
              <Route path='/payouts/chart/:tickets' element={<PayoutChartPage/>} />
              <Route path='/payouts/chart/:tickets/:bonusTickets' element={<PayoutChartPage/>} />

              <Route path='/account' element={<Account/>}/>
              <Route path='/payouts' element={<Payouts/>}/>
              <Route path='/myTournaments'  element={<MyTournaments/>}/>
              <Route path='/myFlexTournaments'  element={<MyTournamentsAsync/>}/>

              <Route path='/create-wager'  element={<CreateWager/>}/>
              <Route path='/wager-lobby'  element={<WagerLobby/>}/>
              <Route path='/wager/:wagerId' element={<Wager />} />
              <Route path='/myWagers' element={<MyWagers />} />


              <Route path='/live' element={<LiveWagerLobby />} />


            </Routes>

          </div>

        </div>

        <Footer/>

        <BuyLichessTickets/>

        <Notifier/>

        <StateModifier/>

        <ToastContainer
            position='bottom-right'
            autoClose={20000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            theme='dark'
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <PlayButton/>

        <GeoLocation/>

        <RegionUnsupportedDialog/>


      </Fragment>
      
    </Router>

  );

}

export default memo(App);
