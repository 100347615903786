
import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    infoContainer:{
        backgroundColor:'#2196F3',
        // backgroundColor:'#1976d2',
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'left', 
        borderRadius:5 ,
        alignItems:'center',   
        paddingLeft:15,
        paddingRight:15,
        paddingTop:5,
        paddingBottom:5
    },
    infoContainerIcon:{
        color:'white', 
        marginRight:10
    },
    infoContainerText:{
        textAlign:'left', 
        color:'white',
        fontWeight:'bold', 
        fontSize:14,
    }
}));


export default function InfoBar({pText, width, maxWidth}){

    const classes = useStyles();
  
    return(

        <div className={classes.infoContainer} style={{width:width, maxWidth:maxWidth}}>
            <InfoIcon className={classes.infoContainerIcon}/>
            <p className={classes.infoContainerText}>{pText}</p>
        </div>

    );
   
}
