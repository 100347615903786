/** @author David Goldstein */
import Emitter from './Emitter.js';
import firebase from './FirebaseClient.js';

const {Magic} = require('magic-sdk');

const magic_key = 'pk_live_632CA57202317990';

export const magic = new Magic(magic_key);

export const handleLogin = async (email) => {
  
  try{

    if (!email) return;

    const didToken = await magic.auth.loginWithMagicLink({ email });
    const auth =  firebase.functions().httpsCallable('authV2');
    let result = (await auth({ didToken })).data;
    await firebase.auth().signInWithCustomToken(result.token);

  }

  catch(e) {console.log(e)}

}

export const handleLogout = async () => {
  await magic.user.logout();
  await firebase.auth().signOut();
  Emitter.emit('LOGGED-OUT', Date.now());
}


