import { toast } from 'react-toastify';

export function copyLink(link, notify=false, message=''){
    navigator.clipboard.writeText(link);
    if(notify) toast(<b>{message}</b>, {type:'success', autoClose:5000});
}

export function openInNewTab (url){
    window.open(url, '_blank', 'noopener,noreferrer');
}

export function pad(n) {
    return (n < 10) ? ("0" + n) : n;
}

export function numberWithCommas(x) {
    if (x == null)return '';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function roundToCent(num) {

    let v = Math.round(num * 100) / 100;
  
    v = v.toFixed(2);
   
    return v;
  
} 

export function adjustedPrice(toRecieve, feePercent, feeFixed){


    let adjusted = (toRecieve + feeFixed) / (1 - feePercent);
  
    adjusted = roundToCent(adjusted);
  
    return Number(adjusted);
  
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trim(str){
    let s = String(str);
    s = s.trim();
    return s;
}

export function errorNotification(message){
    toast(<b>{message}</b>, {type:'error', autoClose:5000});
}

export function detectAndReplaceLinks(text) {
    // Regular expression to match URLs starting with bit.ly/, discord.io/, or t.me/
    const urlRegex = /(\b(?:https?|ftp|file):\/\/|bit\.ly\/|discord\.io\/|t\.me\/)[\S]+/gi;

    // Replace URLs with hyperlinked HTML
    const linkedText = text.replace(urlRegex, function (url) {
        if (url.startsWith("bit.ly/") || url.startsWith("discord.io/") || url.startsWith("t.me/")) {
            return '<a href="https://' + url + '" target="_blank">' + url + '</a>';
        } else {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        }
    });

    return linkedText;
}

export const countryCodeToFlag = {
    '86': '🇨🇳', // China
  '91': '🇮🇳', // India
  '1': '🇺🇸', // United States
  '62': '🇮🇩', // Indonesia
  '92': '🇵🇰', // Pakistan
  '55': '🇧🇷', // Brazil
  '234': '🇳🇬', // Nigeria
  '880': '🇧🇩', // Bangladesh
  '7': '🇷🇺', // Russia
  '81': '🇯🇵', // Japan
  '52': '🇲🇽', // Mexico
  '84': '🇻🇳', // Vietnam
  '20': '🇪🇬', // Egypt
  '49': '🇩🇪', // Germany
  '98': '🇮🇷', // Iran
  '90': '🇹🇷', // Turkey
  '243': '🇨🇩', // DR Congo
  '44': '🇬🇧', // United Kingdom
  '33': '🇫🇷', // France
  '39': '🇮🇹', // Italy
  '27': '🇿🇦', // South Africa
  '82': '🇰🇷', // South Korea
  '34': '🇪🇸', // Spain
  '95': '🇲🇲', // Myanmar
  '255': '🇹🇿', // Tanzania
  '380': '🇺🇦', // Ukraine
  '54': '🇦🇷', // Argentina
  '213': '🇩🇿', // Algeria
  '251': '🇪🇹', // Ethiopia
  '51': '🇵🇪', // Peru
  '66': '🇹🇭', // Thailand
  '60': '🇲🇾', // Malaysia
  '63': '🇵🇭', // Philippines
  '65': '🇸🇬', // Singapore
  '94': '🇱🇰', // Sri Lanka
  '212': '🇲🇦', // Morocco
  '31': '🇳🇱', // Netherlands
  '40': '🇷🇴', // Romania
  '46': '🇸🇪', // Sweden
  '41': '🇨🇭', // Switzerland
  '47': '🇳🇴', // Norway
  '48': '🇵🇱', // Poland
  '56': '🇨🇱', // Chile
  '57': '🇨🇴', // Colombia
  '58': '🇻🇪', // Venezuela
  '233': '🇬🇭', // Ghana
  '254': '🇰🇪', // Kenya
  '211': '🇸🇸', // South Sudan
  '249': '🇸🇩', // Sudan
  '256': '🇺🇬', // Uganda
  '260': '🇿🇲', // Zambia
  '263': '🇿🇼', // Zimbabwe
  '218': '🇱🇾', // Libya
  '216': '🇹🇳', // Tunisia
  '961': '🇱🇧', // Lebanon
  '962': '🇯🇴', // Jordan
  '964': '🇮🇶', // Iraq
  '966': '🇸🇦', // Saudi Arabia
  '971': '🇦🇪', // United Arab Emirates
  '972': '🇮🇱', // Israel
  '973': '🇧🇭', // Bahrain
  '974': '🇶🇦', // Qatar
  '976': '🇲🇳', // Mongolia
};