import React from 'react';
import { getMyLichessTournamentPayouts } from '../../../Firebase';
import PayoutCard from './PayoutCard';
import RowsLoading from '../../RowsLoading';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from '@mui/material';
import ErrorCard from '../ErrorCard';
import useUser from '../../../hooks/useUser';
import WarningBar from '../../WarnningBar';

export default function Payouts(){

    const pageSize = 10;

    const [user, auth, userLoading] = useUser();

    const [loading, setLoading] = React.useState(false);
    const [payouts, setPayouts] = React.useState({items:[], totalValue:0});

    const [pages, setPages] = React.useState([[]]);
    const [pageNum, setPageNum] = React.useState(0);


    async function init() {
        setLoading(true);
        setPayouts(await getMyLichessTournamentPayouts());
        setLoading(false);
    }

    React.useEffect(()=>{
        if (user != null) init();
    },[user])


    React.useEffect(()=>{

        if (payouts != -1){

            let items = [...payouts.items];

            var _pages = [];
    
            for (let i = 0; i < items.length; i += pageSize) {
    
                var rows = [];
    
                const chunk = items.slice(i, i + pageSize);
    
                for(var j = 0; j < chunk.length; j++){
                    rows.push(
                        <div style={{marginBottom:20}}>
                    <PayoutCard key={`PayoutCard-${i}-${j}`} _payout={chunk[j]}/>
                    </div>);
                }
    
                _pages.push(rows);
         
            }
    
            if(_pages.length > 0) setPages(_pages);

        }
        
    },[payouts])


    function EmptyPayouts(){
        return(
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <p style={{fontWeight:'bold', fontSize:14}}>None</p>
            </div>
        );
    }



    function handleClickNextPage(){
        if(pageNum + 1 <= pages.length-1) setPageNum(pageNum + 1);
    }

    function handleClickPreviousPage(){
        if(pageNum - 1  >= 0) setPageNum(pageNum - 1);
    }


    return (


        <div style={{padding:20}}>


            {(userLoading || loading) &&

            <RowsLoading height={100}/>

            }
        

            {(!userLoading && !loading && user)&& <>
                {(payouts == -1) ? <ErrorCard/>:

                    <>

                        {(payouts.items.length  > 0) ? 
                            <>
                            

                                <div style={{display:'flex', marginBottom:10, flexDirection:'row', justifyContent:'right', alignItems:'center'}}>

                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'right'}}>
                                        <p style={{fontSize:12, marginRight:10}}>{`${pageNum*pageSize+1}-${pageNum*pageSize + pages[pageNum].length} of ${payouts.items.length}`}</p>
                                        <IconButton onClick={handleClickPreviousPage}><KeyboardArrowLeftIcon/></IconButton>
                                        <IconButton onClick={handleClickNextPage}><KeyboardArrowRightIcon/></IconButton>
                                    </div>
                                </div>
                            
                            
                                {pages[pageNum]}
                            </>
                                :
                            <EmptyPayouts/>
                        }
                    </>
                }
            </>}

            {(!userLoading && !loading && !user) &&

                <WarningBar pText={'Please login'}/>
            }

        </div>       
    )
    

}
