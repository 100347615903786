import firebase from './FirebaseClient';

const auth = firebase.auth();
const google_provider = new firebase.auth.GoogleAuthProvider();

export const handleGoogleLogin = async () => {
  
  try {

    const result = await auth.signInWithPopup(google_provider);


  } catch (e) {

    console.log(e);


  }

}

