import React from 'react'



export default function PlayerBadge({title, username, rating}){

    return(

        <div style={{display:'flex', 
        flexDirection:'row', 
        justifyContent:'center',  
        alignItems:'center'}}>
            {(title != '') && <p className='descrip5' style={{fontSize:14,padding:0, margin:0, fontWeight:'bold', marginRight:5}}>{title}</p>}
            <p style={{fontSize:14, fontWeight:'bold', color:'white', padding:0, margin:0, marginRight:5}}>{username}</p>
            <p style={{fontSize:12, padding:0, margin:0, fontWeight:'normal', color:'#D3D3D3'}}>{rating}</p>
        </div>


    )

}

