import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database'
import 'firebase/functions';
import Emitter from './Emitter';

// Firebase configuration variables loaded from environment variables
const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
}

// If Firebase isn't already initialized, initialize it using the above credentials.
if (!firebase.apps.length) {
  const app = firebase.initializeApp(clientCredentials);
}

firebase.auth().onAuthStateChanged(function(user) {
  if (user)   Emitter.emit('FIREBASE-AUTH', Date.now());
  if (!user)  Emitter.emit('FIREBASE-NO-AUTH', Date.now());
});

export default firebase;
