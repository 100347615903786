import React, {memo} from 'react';
import clockIcon from '../../../art/chess-clock.svg';
import { Button, CircularProgress, Divider, Dialog } from '@mui/material';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { pad, copyLink, numberWithCommas,openInNewTab } from '../../../utils';
import { getLichessTournamentPassword, lichessTournamentsDB } from '../../../Firebase';
import { useLichessTournamentCardStyles } from '../../MuiStyles';
import Emitter from '../../../Emitter';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';
import GavelIcon from '@mui/icons-material/Gavel';
import JoinTournamentDialogue from './JoinTournamentDialogue';
import PayoutChart from '../Payouts/PayoutChart';
import TournamentIcon from '../Icons/TournamentIcon';
import useUser from '../../../hooks/useUser';
import { errorNotification } from '../../../utils';
import { lichessTournamentContainsUsername } from '../../../Lichess/api';
import { TOURNAEMNT_PAYOUT_WAITING_PERIOD } from '../../../Lichess/tournamentForm';

function TournamentCard({tournament}) {

  const tid = tournament['id'];

  const classes = useLichessTournamentCardStyles();

  const [user, auth, userLoading] = useUser();

  const [openJoinDialogue, setOpenJoinDialogue] = React.useState(false);
  const [openPayoutChart, setOpenPayouChart] = React.useState(false);

  const [enrolled, setEnrolled] = React.useState(false);

  const [joined, setJoined] = React.useState(false);


  const [passwordLoading, setPasswordLoading] = React.useState(true);
  const [password, setPassword] = React.useState('');

  const [playerUsername, setPlayerUsername] = React.useState('');

  const [tournamentStatus, setTournamentStatus] = React.useState(tournament['status']);

  const [numberEnrolled, setNumberEnrolled] = React.useState(tournament['users'].length);

  const TOURNAMENT_URL = 'https://lichess.org/tournament/' + tournament['id'];


  async function initPassword(){
    setPasswordLoading(true);
    setPassword(await getLichessTournamentPassword(tournament['id']));
    setPasswordLoading(false);
  }

  async function initStatus(){

    let complete = Date.now() >= tournament['endDate'];
    let status = tournament['status'];


    if(status == 'ACTIVE' && complete) setTournamentStatus('WAITING');
    if(status == 'ACTIVE' && !complete) setTournamentStatus('ACTIVE');

    if(status != 'ACTIVE') setTournamentStatus(status);

    if(status != 'REFUND' && tournament['disputed'] == true) setTournamentStatus('DISPUTED');


  }


  async function withdrawCheck(){
    let contains = await lichessTournamentContainsUsername(tournament['id'], playerUsername);
    setJoined(contains);
  }


  React.useEffect(()=>{

    Emitter.on('TOURNAMENT-JOIN-SUCCESS', () => {
      activate();
      window.location.reload();
    })

    return () => {Emitter.off('TOURNAMENT-JOIN-SUCCESS')};


},[])



  React.useEffect(()=>{

    if (playerUsername != ''){
      withdrawCheck();
    }

  },[playerUsername])


  async function activate(){


    if(user) {
      let _enrolled = tournament['users'].includes(user.uid);
      if (_enrolled){
        
        setEnrolled(true);
        
        let u = tournament['userMap'][user.uid];
        
        setPlayerUsername(u);
        initPassword();
      
        let joinedMap = tournament.joinedMap;

        if (joinedMap){
          let j = joinedMap[u];
          if (j == true) setJoined(true);
        }

      }
    }


  }

  React.useEffect(()=>{

    activate();


  },[user, tournament])


  React.useEffect(()=>{

    initStatus();

  },[tournament])


  React.useEffect(()=>{

    const subscribe = lichessTournamentsDB.doc(tid).onSnapshot((snapshot) => {
        let t = snapshot.data();
        setNumberEnrolled(t['users'].length);
        
    });

    return ()=>{
        subscribe();
    }

  },[tid])



  function handleClickJoin(){

    if(auth){
      setOpenJoinDialogue(true)
    } else {
      Emitter.emit('NO-AUTH', Date.now());
    }

  }

  function TitleText(){
    
    var name = '';


    if(tournament['perf'] == 'blitz') name = 'Super Blitz';
    if(tournament['perf'] == 'chess960') name =  'Chess 960';

    // if(tournament['tag']=='endurance')name = name + ' - Endurance';

    if(tournament['tag'] == 'user') name = tournament['name'];

    return name;

  }

  function TicketCountText(){

    if(tournament){

      let count = tournament['tickets'];

      if (count == 0) return 'Free Entry'
      if (count == 1) return 'Entry Fee $1'
      if(count > 1) return `Entry Fee $${tournament['tickets']}`;

    }


  }

  function ClockText(){
    if(tournament) return  `${tournament['clockTime']}+${tournament['clockIncrement']}`
  }

  function TimeText() {

    if(tournament){
     
      const currentDate = new Date();
      const startDate = new Date(tournament.startDate);
      const endDate = new Date(tournament.endDate);
    
      const hoursStart = startDate.getHours();
      const minutesStart = pad(startDate.getMinutes());
      const start = hoursStart + ':' + minutesStart;

      const hoursEnd = endDate.getHours();
      const minutesEnd = pad(endDate.getMinutes());
      const end = hoursEnd + ':' + minutesEnd;


      let timeStart = (new Date(tournament.startDate));
      let date = timeStart.toLocaleDateString();

      let currentYear = currentDate.getFullYear();

      // let date = timeStart.toLocaleDateString();
      if (timeStart.getFullYear() === currentYear) {
        const currentYearStr = '/' + currentYear.toString();
        date = date.replace(currentYearStr, '');
      }
            
      return start + ' on ' + date;

    }

  }
  
  function UsernameText(){

    let name = playerUsername;
    if (name.length > 20) return name.slice(0,19) + '...';
    return name;

  }

  function MinParticipantsText(){

    let minParticipants = tournament['minParticipants'];
    if (minParticipants == null) minParticipants = 4;

    let maxParticipants = tournament['maxParticipants'];
    if (maxParticipants == null) maxParticipants = 123456789;

    let spotsRemaining = maxParticipants - numberEnrolled;

    if (spotsRemaining == 0) {
      
      return 'Full'


    } else if (numberEnrolled >= minParticipants){

      return ''

    } else if (spotsRemaining < 10){

      return `Spots remaining ${spotsRemaining}`

    }
  

    return `Minimum Players ${minParticipants}`;

    // return `Minimum ${minParticipants} Players `;


  }

  function EnrolledText(){

    return <p className={classes.subText} style={{marginBottom:0}}>{`Enrolled ${numberEnrolled}`}</p>


  }

  function ClockIcon({size}){


    return(

      <img src={clockIcon} width={size} height={size}/>

    );

  }

  function clickEntryCode(){



    if (!passwordLoading && password != ''){


      copyLink(password, true, 'Entry code copied to clipboard');


    } else {

      errorNotification('Please try again');

    }


  }

 

  function Prizes(){


    let enablePayoutChart = true;
    let fundDescription = ''

    
    let bonusTickets = tournament['bonusTickets'];
    if (bonusTickets == null) bonusTickets = 0;


    if (tournament['tickets'] > 0) {

      if (bonusTickets > 0) {
        fundDescription = 'Prize Pool + Bonus Funds';
      } else {
        fundDescription =  'Prize Pool'
      }

    } else {

      if (bonusTickets > 0) {
        fundDescription = 'Fixed Prizes';
      } else {
        fundDescription = 'No Prizes';
        enablePayoutChart = false;
      }

    }


    let color = '#36AA3E';

    let value = `$${numberWithCommas((numberEnrolled * tournament['tickets'] + bonusTickets))}`;

    if (tournamentStatus == 'ACTIVE' && tournament['tickets'] > 0 && numberEnrolled < tournament.minParticipants){
      // color = '#FF6A00';
      value = `$${numberWithCommas((tournament.minParticipants * tournament['tickets'] + bonusTickets))}`;
    }


    return(


      <div onClick={()=>{if(enablePayoutChart)setOpenPayouChart(true);}} style={{width:'80%', marginBottom:15, display:'flex', justifyContent:'flex-start', flexDirection:'column',  textAlign:'left'}}>
              
      <p style={{padding:0, marginTop:0, fontSize:20, color:color}}>{value}</p>
      
      <div  style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        <p className={classes.subText}>{fundDescription}</p>
        {enablePayoutChart && <InfoIcon style={{textDecoration:'none', color:'gray', height:16,width:16, marginTop:-4.2, marginLeft:3}}/>}
      </div>
  
      {EnrolledText()}
  
    </div>
  


    )


  }



  const [timeRemainingToPayout, setTimeRemainingToPayout] = React.useState('');

  function startTimerPayout() {

    setInterval(function () {

        let timeRemaining = ((tournament['endDate'] + (TOURNAEMNT_PAYOUT_WAITING_PERIOD * 60 * 60 * 1000 + 60000)) - Date.now());

        if(timeRemaining < 1000){
          setTimeRemainingToPayout('00:00');
            return;
        }

        timeRemaining = Math.trunc(timeRemaining/1000);

        let hours = parseInt(timeRemaining / (60 * 60), 10);
        let minutes = parseInt((timeRemaining / 60) % 60, 10);
        let seconds = parseInt(timeRemaining % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        if (hours < 1){
          setTimeRemainingToPayout(minutes+':'+seconds);
        } else {
            hours = hours < 10 ? "0" + hours : hours;
            setTimeRemainingToPayout(hours+':'+minutes+':'+seconds);
        }    

    }, 1000);
  }

  React.useEffect(()=>{
          
    startTimerPayout();

  },[tournament])


  return (

    <>


      <div className='card' style={{display:'flex', marginBottom:20, backgroundColor:'white',  flexDirection:'column'}}>

    

        <Link to={`/tournament/${tournament['id']}`} style={{textDecoration:'none'}}>
          
          <div className={classes.cardHeaderContainer}>
                    
            <div className={classes.cardHeaderContainerLeft}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <TournamentIcon size={38} perf={tournament['perf']} variant={tournament['variant']} />
              </div>
              <div className={classes.cardHeaderLeftTextContainer}>
                <p style={{fontSize:15}}>{TitleText()}</p>
                <p className={classes.subText}>{TicketCountText()}</p>
              </div>
            </div>
            <div className={classes.cardHeaderContainerRight}>
                <p style={{padding:0, margin:0, fontWeight:'bold', color:'gray', fontSize:18}}>{ClockText()}</p> 
                <p style={{minWidth:70, padding:0, margin:0, fontSize:11, marginTop:5}}>{`${tournament['minutes']} Minutes`}</p>
            </div>

          </div>

        </Link>

        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Prizes/>
          <div>
          </div>
        </div>

        <div className={classes.cardBody} style={{minHeight:70}}>

          {enrolled && 

            <>
              {tournamentStatus == 'REFUND' && 

                <div style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'space-between', alignItems:'flex-end'}}>
                  {(tournament.refundDescription != null && tournament.refundDescription != '') ?<p style={{width:'100%', fontSize:11}}><i>{tournament.refundDescription}</i></p>
                  :<p>{''}</p>}
                  <p className={classes.refundText}>REFUND</p>
                </div>
              }

              {tournamentStatus == 'WAITING' && 
                  // <>
                  //   <p></p>
                  //   <Button disabled={true} variant='contained' >
                  //     <CircularProgress style={{marginRight:10}} size={20}/>
                  //     <b>WAITING PERIOD</b>
                  //   </Button>

                  // </>
                    <div style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'space-between', alignItems:'flex-end'}}>
                      <p></p>
                      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-end'}}>
                          <Button style={{width:90, minHeight:20}} disabled={true} variant='contained' >
                              <b>{timeRemainingToPayout}</b>
                          </Button>
                          <p style={{padding:0, margin:0, fontSize:10, textAlign:'right', marginTop:8,  color:'#6c6d6f'}}>{'BOT DETECTION ACTIVE'}</p>
                      </div>
                    </div>
              }

              {tournamentStatus == 'ACTIVE' && 

                <div style={{display:'flex', flexDirection:'column',  width:'100%'}}>


                  {!joined ? 
                  
                    <>
              
                      <div onClick={()=>openInNewTab(TOURNAMENT_URL)} style={{display:'flex', marginBottom:10, flexDirection:'row', justifyContent:'right', alignItems:'center'}}>
                        {/* <p style={{fontSize:15, textAlign:'right', width:'100%'}}><i>{`Playing as ${UsernameText()}`}</i></p> */}
                        <p style={{fontSize:15, textAlign:'right', width:'100%'}}><i>{`Playing as ${UsernameText()}`}</i></p>

                        <LaunchOutlinedIcon style={{marginLeft:5,marginTop:-5, width:20, height:20}}/>
                      </div>

                      <Button className='btn-grad0' variant='contained' style={{height:38.5}} disabled={passwordLoading} onClick={clickEntryCode}>
                          <FileCopyIcon size={20} style={{marginRight:5, width:20, height:20}}/>
                          <b>Entry Code</b>
                      </Button>

                    </>
                      :

                      <Button className='btn-grad0' style={{width:'100%', marginTop:10, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:38.5}} variant='contained' onClick={()=>openInNewTab(TOURNAMENT_URL)}>
                          {/* <b style={{marginTop:2}}>{`Playing as ${UsernameText()}`}</b> */}
                          <b style={{marginTop:2}}>{`Playing as ${UsernameText()}`}</b>

                          <LaunchOutlinedIcon style={{marginLeft:5, marginTop:-2, width:20, height:20}}/>
                      </Button>
                  
                }



                 
                </div>

              }

              {tournamentStatus == 'COMPLETE' && 
                <p className={classes.completeText}>COMPLETE</p>
              }

              {tournamentStatus == 'DISPUTED' && 
                <div style={{display:'flex', width:'100%',  flexDirection:'row', alignItems:'flex-end', justifyContent:'space-between'}}>

                    <p style={{fontWeight:'normal', fontSize:12, paddingRight:10, width:'100%'}}> Please wait for more details</p>
                    
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'right', alignItems:'center'}}>

                      <GavelIcon  style={{color:'red', width:21, height:22, marginRight:5}} />
                      <p className={classes.refundText}>DISPUTED</p>

                    </div>

                </div>
              }
            
            </>

          }

          {!enrolled &&
              <>
                {tournamentStatus == 'ACTIVE' ? 
                  <>
                  <p></p>
                    <Button onClick={handleClickJoin}  style={{width:100}} color='primary' variant='outlined'>
                      <b>Join</b>
                    </Button>
                  </>
                  :
                  <p className={classes.completeText}>CLOSED</p>
                }
              </>
          }

        </div>

          <Divider style={{marginTop:15}}/>

          <div style={{ marginTop:15, display:'flex', justifyContent:'space-between', flexDirection:'row', alignItems:'flex-end'}}>

            <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end'}}>
              <p style={{margin:0, padding:0, fontSize:12, fontWeight:'bold'}}>{TimeText()}</p>
            </div>
            
            <div style={{display:'flex', flexDirection:'row', justifyContent:'right', alignItems:'flex-end'}}>
              <p style={{margin:0, padding:0, fontSize:12, fontWeight:'bold'}}>{MinParticipantsText()}</p>
            </div>
            
          </div>




      </div>


      <Dialog fullWidth open={openPayoutChart} onClose={()=>setOpenPayouChart(false)}>

        <PayoutChart entryTickets={tournament['tickets']} bonusTickets={tournament['bonusTickets']} n={numberEnrolled} min={tournament['minParticipants']} showTickets={true} max={250} disableSlider={tournamentStatus != 'ACTIVE'} formula={tournament['payoutFormula']} />

      </Dialog> 

      <JoinTournamentDialogue tournament={tournament} openJoinDialogue={openJoinDialogue} setOpenJoinDialogue={setOpenJoinDialogue}/>

    </>

  );


}

export default memo(TournamentCard);
