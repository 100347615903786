import React, { memo, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { atom_ipLocation } from '../Atoms';
import { refreshTime, runLocationDetector } from './ipgeo';
import useUser from '../hooks/useUser';
import { updateGeoLocation } from '../Firebase';

function GeoLocation() {

    const [user, loading, error] = useUser();
    
    const [locationData, setLocationData] = useRecoilState(atom_ipLocation);

    async function init(user){

        if (!user) return;

        let now = Date.now()

        if (locationData.locationData == null || now > (locationData.timestamp + refreshTime)){

            let data = await runLocationDetector();
            
            if (data != null) {
                setLocationData(data)
                await updateGeoLocation(locationData);
            }

        }

    }

    useEffect(() => {

        init(user);

    }, [user]);


    return (
        <></>
    );
}

export default GeoLocation;
