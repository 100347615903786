
let API_KEY = 'aa9afa9ba14343cbbc3972b29e2d30cb';

let url = `https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}`;

export const refreshTime = 7 * 86400 * 1000;

export async function runLocationDetector(){

    let locationData = null;
    let timestamp = Date.now();

    await fetch(url).then(response => response.json()).then(data => {

        locationData = data;
       
    })
    .catch(error => {
        console.error(error);
    })

    if (locationData == null) return null;

    return {
        locationData,
        timestamp
    }


}