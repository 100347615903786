import React from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import { atom_lichessTicketCount, atom_lichessTicketModal, atom_paymentFees } from '../../Atoms';
import {Dialog} from '@material-ui/core/'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import PaypalButtons from './PaypalButtons';
import { getLichessPromotion, lichessPromotion } from '../../Firebase';
import { Button, CircularProgress, Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import { useBuyLichessTicketStyles } from '../MuiStyles';
import { adjustedPrice, numberWithCommas } from '../../utils';
import DoneIcon from '@mui/icons-material/Done';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import useUser from '../../hooks/useUser';
import { toast } from 'react-toastify';
import PaypalLogo from '../PaypalLogo';



export default function BuyTickets(){


    const classes = useBuyLichessTicketStyles();

    const [user, auth, userLoading] = useUser();

    const [openModal, setOpenModal] = useRecoilState(atom_lichessTicketModal);

    const [loading, setLoading] = React.useState(false); 

    const [count, setCount] = useRecoilState(atom_lichessTicketCount);

    const fees = useRecoilValue(atom_paymentFees);

    const [confirmPrice, setConfirmPrice] = React.useState(false);


    function handleChangeCount(v){
        if(v <= 0) v = 1;
        if(v >= 100) v = 99;
        setCount(v);
    }

    function price(discount=0){
        let p = count * (100-discount)/100
        p = p.toFixed(2);
        return '$' + numberWithCommas(p);
    }

    function processingFees(){
        let p = count;
        let adjsuted = adjustedPrice(p, fees.paypalFeePercent, fees.paypalFeeFixed);
        p = adjsuted - p;
        p = p.toFixed(2);
        return '$' + numberWithCommas(p);
    }

    function priceAndFees(){
        let p =  count;
        p = adjustedPrice(p, fees.paypalFeePercent, fees.paypalFeeFixed);
        p = p.toFixed(2);
        return '$' + numberWithCommas(p);
    }

    function discoutProcessingFees(percent){
        let p = count * (100-percent)/100;
        let adjsuted = adjustedPrice(p, fees.paypalFeePercent, fees.paypalFeeFixed);
        p = adjsuted - p;
        p = p.toFixed(2);
        return '$' + numberWithCommas(p);
    }

    function discountPriceAndFee(percent){
        let p = count * (100-percent)/100;
        p = adjustedPrice(p, fees.paypalFeePercent, fees.paypalFeeFixed);
        p = p.toFixed(2);
        return '$' + numberWithCommas(p);
    }
  

    const [promoField, setPromoField] = React.useState('');
    const [promo, setPromo] = React.useState(null);

    function handleChangePromoField(e){
        setPromoField(e.target.value);
    }
    
    async function handleApplyPromo(){

        if(promoField != ''){
            setLoading(true);

            let p = await getLichessPromotion(promoField);

            if (p == -1) {            
                toast(<b>Please try again.</b>, {type:'error', autoClose:5000});
            } else if (p == null) {
                toast(<b>This promo code does not exist.</b>, {type:'error', autoClose:5000});
                setPromoField('')
            } else {
                setPromo(p);
            }

            setLoading(false);
        }

    }


    function getPromoValue(){
        let ticketCount = promo['ticketCount'];
        let value = ticketCount;
        let text = '$' + value.toFixed(2);
        return text;
    }


    function handleClose(){
        setOpenModal(false);
        setCount(10);
        setPromoField('');
        setPromo(null);
        setConfirmPrice(false);
    }


    async function handleRedeemPromotion(){

        if (promo['id']) {
            handleClose();
            await lichessPromotion(promo['id']);
        }

    }


    function handleClickDone(){

        if (promoField==''){

            setConfirmPrice(true);

        } else {

            handleApplyPromo();

        }

    }
   

    return(
      
        <>
            {loading ?
                
                <Dialog  PaperProps={{style:{padding:20, width:100, height:100, backgroundColor: 'transparent', boxShadow: 'none'}}} open={openModal && loading}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <CircularProgress style={{color:'silver'}} size={60} />
                    </div>
                </Dialog>

                :

                <Dialog fullWidth  open={openModal} onClose={handleClose} 
                
                    PaperProps={{
                        style:{
                        borderTopRightRadius:7,
                        borderTopLeftRadius:7,
                        borderBottomLeftRadius:7,
                        borderBottomRightRadius:7
                        }
                    }} >

                    <div  className={classes.container} >

                        <div className='logo-wrap' style={{marginBottom:0}}>
                            <div className='logo-wrap-content'>

                                {(promo == null) ? <>

                                    <div className={classes.counterRow}>
                                        
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-end'}}>
                                        <PaypalLogo width={70}/>
                                            {/* <p style={{fontWeight:'bold', margin:0, padding:0, fontSize:12, }}>Deposit</p> */}
                                          
                                        </div>

                                        <div className='flexRowCentered' >
                                            {!confirmPrice &&  <IconButton disabled={confirmPrice} className={classes.iconButton} 
                                                onClick={()=>handleChangeCount(count-1)}
                                            >
                                                <RemoveIcon className={classes.icon} />
                                            </IconButton>}

                                            <div className={classes.ticketCountContainer}>
                                                <p className={classes.ticketCounter}>{count}</p>
                                            </div>
                                            
                                            {!confirmPrice && <IconButton  className={classes.iconButton}
                                                onClick={()=>handleChangeCount(count+1)}
                                            >
                                                <AddIcon className={classes.icon}/>
                                            </IconButton>}
                                        </div>

                                    </div>

                                    <Divider/>

                                    <div className={classes.receiptBody}>
                            
                                        <div className={classes.receiptItem}>
                                            <p className={classes.text}>Deposit Value</p>
                                            <p className={classes.text}>{price()}</p>
                                        </div>
                                        
                                        <div className={classes.receiptItem}>
                                            <p className={classes.text}>Processor fee</p>
                                            <p className={classes.text}>{processingFees()}</p>
                                        </div>

                                    </div>


                                    <Divider/>

                                    <div className={classes.postReceipt}>
                                        <p className={classes.text}>Total</p>
                                        <p className={classes.text}>{priceAndFees()}</p>
                                    </div>

                                    </>

                                    :

                                    <>

                                        {promo['type'] == 'discount' &&
                                            <>
                                            {/* <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                                <p className={classes.text}>CODE</p>
                                                <p className={classes.text}>{promo['id']}</p>
                                            </div> */}

                                                <div >
                                                                                
                                                    <div className={classes.receiptItem}>
                                                        <p className={classes.text}>Deposit Amount</p>
                                                        <p className={classes.text}>{price()}</p>
                                                    </div>
                                                    
                                                    <div className={classes.receiptItem}>
                                                        <p className={classes.text}>Processor fee</p>
                                                        <p className={classes.text}>{discoutProcessingFees(promo['percent'])}</p>
                                                    </div>

                                                                                  
                                                    <div className={classes.receiptItem}>
                                                        <p className={classes.text} style={{color:'red'}}>Discount</p>
                                                        <p className={classes.text}  style={{color:'red'}}>{price(promo['percent'])}</p>
                                                    </div>

                                                    <Divider style={{marginTop:20, marginBottom:0}}/>

                                                    <div className={classes.receiptItem} style={{marginBottom:20}}>
                                                        <p className={classes.text}>You Pay</p>
                                                        <p className={classes.text}>{discountPriceAndFee(promo['percent'])}</p>
                                                    </div>

                                                </div>


                                                

                                              
                                            </>

                                        }


                                        {promo['type'] == 'free' &&
                                            <>
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                                <p className={classes.text}>CODE</p>
                                                <p className={classes.text}>{promo['id']}</p>
                                                </div>

                                                <Divider/>

                                                <div className={classes.receiptBody}>

                                                {/* <div className={classes.receiptItem}>
                                                    <p className={classes.text}>Tickets</p>
                                                    <p className={classes.text}>{promo['ticketCount']}</p>
                                                </div> */}
                                                
                                                <div className={classes.receiptItem}>
                                                    <p className={classes.text}>Value</p>
                                                    <p className={classes.text}>{getPromoValue()}</p>
                                                </div>

                                            </div>
                                            </>

                                        }

                                       
                                    
                                    </>
                                
                                }

                            
                            </div>
                        </div>

                        {!confirmPrice && <div style={{display:'flex',flexDirection:'row', justifyContent:'center'}}>

                     
                        <TextField 
                            
                            disabled={promo!=null}
                            value={promoField}
                            onChange={handleChangePromoField}
                            variant='filled'
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>
                                        <IconButton   disabled={promo!=null} onClick={handleApplyPromo}>
                                            <AddCircleOutlineIcon/>
                                        </IconButton>
                                    </InputAdornment>,
                                }}

                                            
                                label='Promo Code' style={{width:'100%', marginTop:0, marginBottom:20,}} >


                        </TextField>

                        </div>}


                       {!auth?
                            <div style={{borderRadius:7, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'row', height:40}}>
                                <p style={{marginTop:-3, fontSize:14, fontWeight:'normal', color:'#6c6d6f', color:'' }}>Please login before proceeding.</p>
                            </div>
                            :

                            <>
                            
                                {(promo == null)?
                                    <>
                                        {confirmPrice ?  
                                            <PaypalButtons count={count} handleClose={handleClose}/>
                                                :
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                                <Button  onClick={handleClose} style={{marginRight:10}} color='primary' variant='outlined'>
                                                    <CloseIcon/>
                                                </Button>
                                                <Button  onClick={handleClickDone} color='primary' variant='contained'>
                                                    <DoneIcon/>
                                                </Button>
                                            </div>
                                        }
                                    </>
                                    :

                                    <>

                                        {promo['type'] == 'free' &&
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                                <Button onClick={handleRedeemPromotion} style={{height:40}}  color='primary' variant='contained'>
                                                    <b>REDEEM</b>
                                                </Button>
                                            </div>
                                        }

                                        {promo['type'] == 'discount' &&

                                            <>
                                            {confirmPrice ?  
                                                <PaypalButtons count={count} handleClose={handleClose} discountCode={promo['id']}/>
                                                    :
                                                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                                    <Button  onClick={handleClose} style={{marginRight:10}} color='primary' variant='outlined'>
                                                        <CloseIcon/>
                                                    </Button>
                                                    <Button  onClick={()=>setConfirmPrice(true)} color='primary' variant='contained'>
                                                        <DoneIcon/>
                                                    </Button>
                                                </div>
                                            }
                                            </>
                                        }


                                    </>
                                    

                                }

                            </>

                           
                        }

                    </div>

                </Dialog>
            }
        </>
        
    );
}
