import React from "react";
import Chessboard from 'chessboardjsx';

export default function ChessBoard({fen, size, draggable}) {
    // Custom piece set (use URLs to your desired chess pieces)
    // const pieceTheme = piece => {
    //     const pieceName = piece.slice(-1); // Gets the piece type (e.g., 'P', 'R')
    //     return `https://example.com/path/to/pieces/${pieceName}.png`;
    // };

    // Custom colors for the board
    // const lightSquareStyle =  // Light square color
    const darkSquareStyle = { backgroundColor: 'rgba(181, 136, 99, 0.9)' }; // Dark square color

    return (

       
                <Chessboard
                    position={fen}
                    width={(size > 500)?500:size}
                    draggable={draggable}
                    // boardStyle={{
                    //     borderRadius: '10px',
                    //     boxShadow: '0 5px 15px rgba(0, 0, 0, 0.6)',
                    // }}
                    // darkSquareStyle={ {backgroundColor: '#6e7176' }}
                    // lightSquareStyle={ {backgroundColor: '#dce3ed' }}

                    // darkSquareStyle={ {backgroundColor: '#4d5e39' }}
                    // lightSquareStyle={ {backgroundColor: '#9ab07d' }}

                    // darkSquareStyle={ {backgroundColor: '#6464ff' }}
                    // lightSquareStyle={ {backgroundColor: '#9abcfc' }}

//                     darkSquareStyle={ {backgroundColor: '#00969b'} }
// lightSquareStyle={ {backgroundColor: '#f2f4f8'} }


//                     darkSquareStyle={ {backgroundColor: '#6464ff'} }
// lightSquareStyle={ {backgroundColor:'#f2f4f8' } }
                    
                    

                
                />
         
    );
}
