import { OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce';
import Emitter from '../Emitter';
export const lichessHost = 'https://lichess.org';
export const scopes = ['tournament:write challenge:write bot:play board:play team:write'];
export const clientId = 'lichess-api-demo';
export const clientUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname.replace(/\/$/, '') || '/'}`;

class Auth {
    
    oauth = new OAuth2AuthCodePKCE({
        authorizationUrl: `${lichessHost}/oauth`,
        tokenUrl: `${lichessHost}/api/token`,
        clientId,
        scopes,
        redirectUrl: clientUrl,
        onAccessTokenExpiry: refreshAccessToken => refreshAccessToken(),
        onInvalidGrant: console.warn,
    });

    me;

    async init() {
        try {
            const accessContext = await this.oauth.getAccessToken();
        if (accessContext) await this.authenticate();
        } catch (err) {
            console.error(err);
        }
        if (!this.me) {
            try {
                const hasAuthCode = await this.oauth.isReturningFromAuthServer();
                if (hasAuthCode) await this.authenticate();
            } catch (err) {
                console.error(err);
            }
        }

        Emitter.emit('LICHESS-OAUTH-SET', Date.now());
    }

    async login(windowLocation) {
        
        await this.logout();

        let redirectUrl = `${windowLocation.protocol}//${windowLocation.host}${windowLocation.pathname.replace(/\/$/, '') || '/'}`;
        
        this.oauth = new OAuth2AuthCodePKCE({
            authorizationUrl: `${lichessHost}/oauth`,
            tokenUrl: `${lichessHost}/api/token`,
            clientId,
            scopes,
            redirectUrl: redirectUrl,
            onAccessTokenExpiry: refreshAccessToken => refreshAccessToken(),
            onInvalidGrant: console.warn,
        });

        await this.oauth.fetchAuthorizationCode();

        Emitter.emit('LICHESS-OAUTH-SET', Date.now());

    }

    async logout() {
        if (this.me) await this.me.httpClient(`${lichessHost}/api/token`, { method: 'DELETE' });
        localStorage.removeItem('oauth2authcodepkce-state');
        Emitter.emit('LICHESS-OAUTH-SET', Date.now());
        this.me = undefined;
    }

    authenticate = async () => {
        const httpClient = this.oauth.decorateFetchHTTPClient(window.fetch);
        const res = await httpClient(`${lichessHost}/api/account`);
        const me = {
        ...(await res.json()),
        httpClient,
        };
        if (me.error) throw me.error;
        this.me = me;

        Emitter.emit('LICHESS-OAUTH-SET', Date.now());

    }

    
}


export const AUTH = new Auth();


AUTH.init();