import React, {memo} from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { createTournamentEntryOrder, createTournamentEntryOrder2, handleTournamentEntryOrder } from '../../Firebase';
import { useRecoilState } from 'recoil';
import { atom_lichessTicketCount } from '../../Atoms';

function PaypalButtons({handleClose, discountCode}) {

  const [count, setCount] = useRecoilState(atom_lichessTicketCount);

  function Button({c, handleClose}){

    async function createOrder(data, actions){
      let orderID = await createTournamentEntryOrder2(c, discountCode);
      return orderID
    }

    async function onApprove(data, actions){
      handleClose();
      await handleTournamentEntryOrder(data.orderID)
    }

    async function onError(err){
      handleClose();
      console.log(err);
    }


   

    return(
  
        <PayPalButtons 
      
            createOrder={createOrder}
    
            onApprove={onApprove}
    
            onError={onError}
            
          />
    
    );

  } 

  return(
    <Button c={count} handleClose={handleClose} />
  );


}

export default memo(PaypalButtons);
