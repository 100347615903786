import React from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '@mui/material';
import { atom_lichessTicketModal } from '../../Atoms';
import ticketsIcon from '../../art/tickets.svg';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function BuyTicketsBtn({fullWidth, height, icon, fontSize}){


    const [showTicketModal, setShowTicketModal] = useRecoilState(atom_lichessTicketModal);

    function handleClickBuy(){
        setShowTicketModal(true);
    }

    return(

        <Button  variant={'contained'} onClick={handleClickBuy} style={{height:height, width:(fullWidth)?'100%':'', backgroundColor:'#36AA3E'}}  >
           {/* {icon && <img style={{marginRight:10, marginTop:-.4}} src={ticketsIcon} width={20} height={20}/>} */}
           <AttachMoneyIcon/>
            <b style={{fontSize:fontSize?fontSize:14}}>Deposit</b>
        </Button>
        
    );
}
