import React from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Skeleton } from '@mui/material';
import { atom_lichessOauthToken } from '../../Atoms';
import { getMyAccount } from '../../Lichess/api';
import Icon from './Icons/Icon';
import iconBlue from '../../art/LichessBlue.png'
import {AUTH} from '../../Lichess/auth';


export default function LichessButton({fullWidth}){

    const lichessOauthToken = useRecoilValue(atom_lichessOauthToken);

    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [username, setUsername] = React.useState('');

    async function getUsername(){

        setLoading(true);
        if (lichessOauthToken != ''){
            let account = await getMyAccount(lichessOauthToken);
            if(account) setUsername(account.id);
        }
        setLoading(false);

    }

    React.useEffect(()=>{

        if (!loading) getUsername();


    },[lichessOauthToken])



    async function handleClickLogin(){
        await AUTH.login(window.location);
    }

    async function handleClickLogout(){
        await AUTH.logout();
    }

 
    return(


        <>

        {loading ? 

            <Skeleton height={50} variant='rectangular' style={{borderRadius:5}} />

                :


                <>

                { (lichessOauthToken == '') ? 

                        <Button onClick={handleClickLogin} color='primary' style={{width:'100%', height:50}} variant={'outlined'}  >
                            <img src={iconBlue} style={{width:35, height:35}}/>
                            <b style={{marginLeft:10}}>{'Login'}</b>
                        </Button>
                                :

                        <Button onClick={handleClickLogout}  style={{width:'100%', height:50}} variant={'outlined'}  >
                            <img src={iconBlue} style={{width:35, height:35}}/>
                            <b style={{marginLeft:10}}>{username}</b>

                        </Button>
                }

                </>


        }
        
        
        
        
        
        </>
     
    );
}
