import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  AppBody:{
    display:'flex',  
    flexDirection:'column', 
    justifyContent:'center', 
    // padding:20, 
  },
  LoginButtonCircularProgress:{
    color:'#04f8f9'
  }
}));

export const useLandingStyles = makeStyles((theme) => ({
  headerBody:{
    marginTop:10, 
    marginBottom:20,  
    display:'flex', 
    justifyContent:'space-between', 
    flexDirection:'row',
    alignItems:'center',
    width:'100%', maxWidth:700,

  },
  headerBodyCol:{
    marginTop:10, 
    marginBottom:20,  
    display:'flex', 
    justifyContent:'space-between', 
    flexDirection:'column',
    alignItems:'center',
    width:'100%', maxWidth:700,

  },
  headerLeft:{
    display:'flex', 
    flexDirection:'column', 
  },
  titleText:{
    color:'white', 
    margin:0, 
    padding:0,
    marginBottom:10, 
    fontSize:27,
    textAlign:'left',
    fontWeight:'bold'
  },
  titleSubText:{
    color:'#6c6d6f', 
    fontWeight:'bold', 
    margin:0, 
    padding:0, 
    marginBottom:5, 
    fontSize:13, 
    textAlign:'left',
  },
  statsRow:{
    display:'flex', 
    height:100, 
    flexDirection:'row', 
    justifyContent:'center',
    width:'100%', maxWidth:700
  },
  statCardContainer:{
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center'
  },
  statCardValue:{
    padding:0,
    margin:0, 
    fontSize:22, 
    // color:'#7599eb',
    color:'#1f2022'

  },
  callToActionContainer:{
    display:'flex', 
    marginTop:45, 
    marginBottom:45, 
    alignItems:'center', 
    flexDirection:'row', 
    justifyContent:'center'
  }
}));

export const useNavBarStyles = makeStyles((theme) => ({
  toolbar:{
    height:70,
    flexDirection:'row', 
    justifyContent:'space-between',
    backgroundColor:'#1f2022',
  }
}));

export const useAccountStyles = makeStyles((theme) => ({
  sectionHeaderText:{
    fontSize:14, 
    fontWeight:'bold'
  },
  sectionItem:{
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center',
    alignItems:'center'
  },
  valueText:{
    fontSize:17, 
    color:'#4899d3'
  },
  valueText2:{
    fontSize:17, 
    color:'#907aeb'
  },
  descriptionText:{
    fontSize:12
  }
}));

export const useBuyLichessTicketsCardStyles = makeStyles((theme) => ({
  leftContainer:{
    display:'flex', 
    textAlign:'left', 
    alignItems:'left', 
    marginLeft:14, 
    flexDirection:'column',
    // backgroundColor:'green',
    paddingRight:12
  },
  title:{
    fontSize:18,
    fontWeight:'bold'
  },
  subTitle:{
    color:'gray', 
    fontSize:12
  }
}));

export const useBuyLichessTicketStyles = makeStyles((theme) => ({
  container:{
    padding:15
  },
  title:{
    fontSize:22, 
    textAlign:'center',
    fontWeight:'bold'
  },
  input:{
    color:'blue',
    width:100,
    height:30,
  },
  price:{
    marginBottom:40, 
    marginTop:40, 
    textAlign:'center', 
    fontSize:20, 
    color:'gray',
    fontWeight:'bold'
  },
  counterRow:{
    display:'flex',   
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'space-between',
    height:50,  
  },
  text:{
    fontSize:14, 
    fontWeight:'bold'
  },
  icon:{
    color:'gray'
  },
  iconButton:{
    width:25, 
    height:25
  },
  ticketCountContainer:{
    marginLeft:5, 
    marginRight:5, 
    justifyContent:'center', 
    alignItems:'center', 
    width:50, 
    height:25,
    border:'1px solid #1776d2', 
    borderRadius:5, 
    display:'flex', 
    flexDirection:'row'
  },
  ticketCounter: {
    fontSize:14, 
    color:'gray', 
    fontWeight:'bold'
  },

  receiptBody:{
    height:90, 
    width:'100%', 
    display:'flex', 
    flexDirection:'column', 
    alignItems:'center', 
    justifyContent:'flex-start'
  },
  receiptItem:{
    height:30,  
    width:'100%', 
    display:'flex', 
    flexDirection:'row',
    alignItems:'flex-start', 
    justifyContent:'space-between'
  },
  postReceipt:{
    height:35,  
    marginBottom:15, 
    width:'100%',
    display:'flex', 
    flexDirection:'row', 
    alignItems:'flex-start', 
    justifyContent:'space-between'
  }
}));

export const useFooterStyles = makeStyles((theme) => ({
  footer:{
    backgroundColor:'#f6f8fc', 
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'space-between',  
    paddingRight:15, 
    paddingLeft:15
  },
  topRow:{
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'space-between'
  },
  topRowLeft:{
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'space-between'
  },
  secureText:{
    fontSize:14, 
    fontWeight:'bold', 
    fontColor:'gray'
  },
  body:{
    display:'flex',
    marginTop:5, 
    alignItems:'center', 
    flexDirection:'column', 
    justifyContent:'space-between'
  },
  socialRow:{
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'center'
  },
  pagesRow:{
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'center'
  },
  pageText:{
    margin:0, 
    padding:0, 
    fontSize:12, 
    fontWeight:'normal'
  },
  companyText:{
    margin: 0,  
    padding: 0, 
    marginTop:10, 
    fontSize: 10,
    marginBottom:15,
  },
  versionText:{
    margin: 0,  
    padding: 0,  
    fontSize:10, 
    marginTop:7, 
    marginBottom:10
  }
}));

export const useMenuStyles = makeStyles((theme) => ({
  listContainer:{
    display:'flex',
    flexDirection:'column',
    // justifyContent:'space-between',
    backgroundColor: theme.palette.grey[900], 
    height: '100%'
  },
  list:{
    width:300, 
    backgroundColor:theme.palette.grey[900]
  },
  listItem:{
    height:50
  },
  listItemText:{
    color:theme.palette.grey[400]
  },
  listItemIcon:{
    color:'white'
  },
  p:{
    fontWeight:'bold',
    color:'white'
  },
  link:{
      textDecoration:'none'
  }
}));

export const useSupportStyles = makeStyles((theme) => ({
  container:{
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center',
    maxWidth:850
  },
  titleText:{
    padding:0, 
    margin:0, 
    marginBottom:20, 
    marginTop:30, 
    fontSize:20, 
    fontWeight:'bold'
  },
  bodyText:{
    padding:0, 
    margin:0, 
    marginTop:20, 
    fontSize:14
  },
  emailText:{
    padding:0, 
    margin:0, 
    marginTop:20, 
    fontSize:14, 
    fontWeight:'bold'
  },
  addressRowContainer:{
    display:'flex', 
    marginTop:40,  
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'space-around'
  },
  addressContainer:{
    display:'flex', 
    flexDirection:'column',
    justifyContent:'center', 
    alignItems:'center'
  },
  addressRow1:{
    margin: 0, 
    padding: 0, 
    fontSize:12, 
    marginBottom:3 
  },
  addressRow2:{
    margin: 0, 
    padding: 0, 
    fontSize:12
  },
  addressDescription:{
    margin: 0, 
    padding: 0, 
    fontSize:10, 
    marginTop:10, 
    fontWeight:'bold'  
  }
 
}));

export const useLichessTournamentCardStyles = makeStyles((theme, enrolled) => ({
  cardContainer:{
    display:'flex', 
    height:enrolled?210:175, 
    marginTop:40, 
    backgroundColor:'white',  
    flexDirection:'column'
  },
  cardHeaderContainer:{
    display:'flex',
    flexDirection:'row', 
    justifyContent:'space-between', 
    alignItems:'flex-start',
    marginBottom:15,
    // backgroundColor:'yellow'

  },
  cardHeaderContainerLeft:{
    display:'flex', 
    flexDirection: 'row',
  },
  cardHeaderLeftTextContainer:{
    display:'flex', 
    flexDirection:'column',
    textAlign:'left', 
    marginLeft:15, 
    justifyContent:'flex-start',
  },
  cardHeaderContainerRight:{
    textAlign:'right',
  },
  subText:{
    fontSize:12, 
    color:'gray',
    fontWeight:'bold'
  },
  timeText:{
    fontSize:14,
    width:100,
    textAlign:'right'
  },
  prizeValueText:{
    fontSize:20, 
    color:'#36AA3E'
  },
  dialogueContainer:{
    display:'flex', 
    flexDirection:'column', 
    alignItems:'center', 
    padding:20,
  },
  dialogueTitle:{
    fontWeight:'bold', 
    fontSize:20
  },
  dialogueAgreementText:{
  },
  dialogueTextField:{
    width:'75%'
  },
  dialogueButtonContainer:{
    marginTop:20,
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'center', 
    alignItems:'center'
  },
  refundText:{
    width:'100%', 
    fontSize:20, 
    fontWeight:'bold', 
    color:'red', 
    textAlign:'right'
  },
  completeText:{
    fontSize:20, 
    width:'100%',  
    fontWeight:'bold', 
    textAlign:'right', 
    color:'gray'
  },
  cardBody:{
    textAlign:'left', 
    justifyContent:'space-between', 
    display:'flex', 
    alignItems:'flex-end', 
    flexDirection:'row',
  }
}));

export const useLichessResultsStyles = makeStyles((theme) => ({
  rowLeftContainer:{
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'center',  
    alignItems:'center'
  },
  rankText:{
    fontSize:10, 
    color:'gray', 
    marginRight:10
  }
 
}));



///////////////////////////////////////////////////////////////





export const useLichessTournamentsStyles = makeStyles((theme) => ({
  leftContainer:{
    display:'flex', 
    textAlign:'left', 
    alignItems:'left', 
    marginLeft:14, 
    flexDirection:'column'
  },
  title:{
    fontSize:21,
    fontWeight:'bold'
  }
}));

