import React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const getRandomGradient = () => {
  const randomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const gradient = `linear-gradient(45deg, ${randomColor()}, ${randomColor()})`;
  return {
    backgroundImage: gradient,
  };
};

const randomAvatarGradient = getRandomGradient(); // Generate random gradient once

const AvatarStack = ({width, maxWidth, height, avatars, maxCount, spacing, avatarSize, orientation = 'vertical' }) => {
  if (!avatars || avatars.length === 0) {
    return null;
  }

  const handleAvatarHover = (index) => {
    // Implement hover effect here if needed
  };

  const isVertical = orientation === 'vertical';
  const stackDirection = isVertical ? 'column-reverse' : 'row-reverse';
  const containerStyle = {
    height:height,
    width:width,
    maxWidth:maxWidth,
    backgroundColor: 'gray',
    padding: 5,
    borderRadius: 5,
    // backgroundColor:'#1f2022',
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0,
  };

  return (
    <div style={containerStyle}>
      <Stack direction={stackDirection} spacing={spacing}>
        {avatars.map((avatar, index) => (
          <Avatar
            key={index}
            alt={`Avatar ${index}`}
            src={avatar || undefined} // Use undefined for empty avatar URL
            onMouseEnter={() => handleAvatarHover(index)}
            sx={{
              zIndex: avatars.length - index, // Bring the leftmost avatar to the top
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.2)',
              },
              border: '1px solid white', // Add a 1-pixel thick white border
              width: avatarSize, // Set the avatar size
              height: avatarSize, // Set the avatar size
              backgroundImage: (avatar || avatar === '') ? randomAvatarGradient.backgroundImage : 'none', // Use the same random gradient if avatar URL is empty or empty string
            }}
          />
        ))}
      </Stack>
    </div>
  );
};

export default AvatarStack;
