import React from 'react';
import { useParams } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getLichessTournament } from '../../../Firebase';
import Emitter from '../../../Emitter';
import TournamentCard from './TournamentCard';
import Results from './Results';
import { Skeleton } from '@mui/material';
import { detectAndReplaceLinks } from '../../../utils';
import WarningBar from '../../WarnningBar';


export default function Tournament() {

    let { tournamentId } = useParams();

    const [user, auth, userLoading] = useUser();

    const [loading, setLoading] = React.useState(true);
    const [tournament, setTournament] = React.useState(null);

    async function init(){
        setLoading(true);
        setTournament(await getLichessTournament(tournamentId));
        setLoading(false);
    }

    React.useEffect(()=>{

        window.scrollTo(0, 0);

        init();

        Emitter.on('TOURNAMENT-JOIN-SUCCESS', () => {
            init();
        })

        return () => {Emitter.off('TOURNAMENT-JOIN-SUCCESS')};


    },[tournamentId])


   
  

    return (

        <div style={{padding:20}}>   

            {loading && 
      
                <Skeleton style={{borderRadius:5, marginBottom:20}} variant='rounded'  height={200} />

            }

            {(!loading && tournament==null) && <WarningBar pText={'No Tournament'}/>}


            {(!loading && tournament!=null) && 
                <> 
                    {(tournament['status'] == 'NO-PLAYERS') ? <WarningBar pText={'Tournament Cancelled'}/> :
                    
                        <>      
                            {/* <div style={{display:'flex', flexDirection:'row', justifyContent:'left'}}>

                                <p>{DateText()}</p>
                            
                            </div> */}
                            <TournamentCard tournament={tournament} user={user} auth={auth} showDate={true} hideResultsBtn={true}/>

                            {(tournament.description != '' && tournament.description != null) &&
                                <div className='card' style={{display:'flex', flexDirection:'row', justifyContent:'left'}}>
                                    <p dangerouslySetInnerHTML={{ __html: detectAndReplaceLinks(tournament.description) }} style={{textAlign:'left', padding:0, margin:0, fontWeight:'normal', fontSize:14}}> 

                                   
                                    
                                    </p>
                                </div>
                            }
                            {/* {(tournament['status'] != 'REFUND' && tournament['users'].length > 0 ) &&  <Results tournament={tournament}/>} */}
                            { (tournament && tournament['users'].length > 0 ) &&  <Results tournament={tournament}/>}
                            {/* {(tournament['status'] != 'COMPLETE' && tournament['users'].length > 0 ) &&  <Results tournament={tournament}/>} */}

                        </>
                    }
                </>
            }

        </div>

    );

}

