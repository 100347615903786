import React from 'react';
import { podium, FORMULAS_MAP} from '../../../PayoutDist';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Slider } from '@mui/material';
import { numberWithCommas } from '../../../utils';




ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {

    const bodyLines = tooltip.body.map(b => b.lines);
  
    const tableBody = document.createElement('tbody');

    bodyLines.forEach((body, i) => {

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      
      const text = document.createTextNode('$' + body);

    
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);

    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    // tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};




export default function PayoutChart({width, height, entryTickets, n, max, bonusTickets, min, disableSlider, formula, fixed}) {

  if (!width) width = '100%';
  if (!height) height= 250;

  // if ((height && width) && (width > height)) width = height;
  
  const [count, setCount] = React.useState(2);
  const [dist, setDist] = React.useState([]);

  function initCount(){
    if (n < (min || 4)){
      setCount(min);
    } else {
      setCount(n);
    }
  }

  function titleText(){

    if (formula == 'amped' || formula == 'async'){
      return 'All Skill Levels Can Win a Payout'
    } else if (formula == 'winner-takes-all'){
      return 'Winner Takes All'
    } else if (formula == 'top-3'){
      return 'Top 3'
    } else {
      return 'Tournament Payouts by Rank'
    }


  }


  React.useEffect(()=>{
    initCount();
  },[n])


  React.useEffect(()=>{

    let dist = FORMULAS_MAP[formula] || podium;

    let podiumArray = null;

    if (bonusTickets != null && bonusTickets > 0){
      let t0 = count * entryTickets;
      let t1 = bonusTickets;

      podiumArray = dist(t0 + t1);

    } else {
      let t0 = count * entryTickets;
      podiumArray =  dist(t0);
    }

    if (fixed) podiumArray = dist(bonusTickets)
   
    setDist(podiumArray);

  },[count, formula])


  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
      
    },
    responsive: true,
    plugins: {
      legend: {
          display:false,
          position: 'right'
      },
      title: {
        display: true,
        text: titleText()
      },
      tooltip: {
        enabled:false,
        position: 'nearest',
        external: externalTooltipHandler
      }
    },
    scales: {
      x: {
          ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                  if (dist.length ==  1){
                    if(index == 0 || index == ticks.length-1) return '$' + value;
                  } else {
                    return '$' + value;
                  }
              }
          },
          grid:{
            display:(n > 1)?true:false
          },
      },
      y:{
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value, index, ticks) {
              if (formula == 'amped'){

                if (index <= 1) return `Rank ${1 + index}`
                if (index == 2) return 'Lucky Rank'
                if (index == 3) return 'Most Games Played'
                if (index == 4) return 'Biggest Comeback'
                if (index == 5) return 'Most Checkmates'
                if (index == 6) return 'Captured Pieces PPG'

              } else if (formula == 'async') {
                
                if (index == 0) return `Most Opponents Defeated`
                if (index == 1) return 'Lucky Rank'
                if (index == 2) return 'Most Games Played'
                if (index == 3) return 'Biggest Comeback'
                if (index == 4) return 'Most Checkmates'
                if (index == 5) return 'Captured Pieces PPG'

              }else {

                return (1 + index)
              }
          }
          
      },
        grid:{
          display:false
        },
        title:{
          display:false,
          text:'Rank',
          padding:1
        }
      }
    }
  }




  const handleSliderChange = (event, newValue) => {
    setCount(newValue);
  };


 
  function maxSlider(){

    if((n + 50 )< max) return max;
    return n + 50;
  

  }


  function SubText(){


    var text = `Players ${numberWithCommas(count)} , `;

    if (entryTickets == 0) text += 'Free Entry';
    if (entryTickets > 0) text += `Entry Fee $${entryTickets}`;
    if (bonusTickets > 0) text += ` , Bonus Funds $${bonusTickets}`;


    if (fixed) text = '';


    return text;

  }

  return (
      
    <div style={{padding:20, paddingTop:15, display:'flex',flexDirection:'column', alignItems:'center',}}>

        <div style={{height:height, width:width, maxWidth:690, alignItems:'center', justifyContent:'center'}}>

            <Bar 
            
            
            options={options} data={{
                labels: Array.from({length: dist.length}, (_, i) => i + 1),
                datasets: [
                {
                    data: dist.map(function(x) { return x * (1); }),
                    borderColor: 'rgba(23,118,210,1)',
                    backgroundColor:  'rgba(23,118,210,.5)',

                }
                ]
            }} 
            />

        </div>


        {!(disableSlider == true) && !(fixed == true) && <div style={{width:'80%', marginTop:10}}>
        <Slider
            color='primary'
            min={min || 10} 
            max={maxSlider()} 
            value={count} 
            onChange={handleSliderChange} 
        />

        </div>}

        <p style={{padding:0, margin:0, marginTop:10, color:'#767676', fontSize:12, fontWeight:'bold'}}>{SubText()}</p>

    </div>
  
  );


}
