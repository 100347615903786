import React from 'react';
import { FaDiscord, FaTwitter} from 'react-icons/fa'

import { openInNewTab } from '../../utils'

export default function Socials({size, margin, marginTop}) {

    let twitterLink = 'https://twitter.com/amped_chess';
    let discordInvite = 'https://discord.gg/trcb7DGXGS';

    return (

        <div style={{marginTop:marginTop, display:'flex', flexDirection:'row', justifyContent:'left', alignItems:'center'}}>
            <FaDiscord onClick={()=>openInNewTab(discordInvite)} style={{width:size, height:size, color:'white'}}  />
            {/* <FaTwitter  style={{width:size, height:size, marginLeft:margin, color:'white'}}  onClick={()=>openInNewTab(twitterLink)} /> */}
        </div>

    );
}
