import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core/';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {handleLogout} from '../MagicV2';
import duelIconWhite from '../art/duel-white.svg';
import {Link} from "react-router-dom";
import { useMenuStyles } from './MuiStyles';
import AddIcon from '@mui/icons-material/Add';
import BuyTicketsBtn from './Lichess/BuyTicketsBtn';
import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

export default function MenuDrawer({open, onClose}){
    
    const classes = useMenuStyles();

    return(
        <React.Fragment key={'right'}>

            <Drawer anchor={'right'} open={open} onClose={onClose} >
                
                <div role="presentation" className={classes.listContainer} onClick={onClose} onKeyDown={onClose}>
                    
                    
                    <List className={classes.list}>

                        <Link to='/' className={classes.link}>
                            <ListItem button key={'Menu-Home'} className={classes.listItem}>
                                <ListItemIcon><HomeIcon className={classes.listItemIcon}/></ListItemIcon>
                                <ListItemText primary={<p className={classes.p}>Hub</p>} className={classes.listItemText}/>
                            </ListItem>
                        </Link>

                        <Link to='/account' className={classes.link}>
                            <ListItem button key={'Menu-Account'} className={classes.listItem}>
                                <ListItemIcon><AccountBalanceIcon className={classes.listItemIcon}/></ListItemIcon>
                                <ListItemText primary={<p className={classes.p}>Account</p>} className={classes.listItemText}/>
                            </ListItem>
                        </Link>

                        <Link to='/myFlexTournaments' className={classes.link}>
                            <ListItem button key={'Menu-My-Lichess-Tournaments-Flex'} className={classes.listItem}>
                                <ListItemIcon><WorkspacePremiumIcon className={classes.listItemIcon}/></ListItemIcon>
                                <ListItemText primary={<p className={classes.p}>Flex Plays</p>} className={classes.listItemText}/>
                            </ListItem>
                       </Link>

                        <Link to='/myTournaments' className={classes.link}>
                            <ListItem button key={'Menu-My-Lichess-Tournaments'} className={classes.listItem}>
                                <ListItemIcon><EmojiEventsIcon className={classes.listItemIcon}/></ListItemIcon>
                                <ListItemText primary={<p className={classes.p}>Arenas</p>} className={classes.listItemText}/>
                            </ListItem>
                       </Link>

                       <Link to='/myWagers' className={classes.link}>
                            <ListItem button key={'Menu-My-Lichess-Wagers'} className={classes.listItem}>
                                <ListItemIcon><img style={{marginRight:10, color:'white'}} src={duelIconWhite} width={22} height={22}/></ListItemIcon>
                                <ListItemText primary={<p className={classes.p}>Wagers</p>} className={classes.listItemText}/>
                            </ListItem>
                       </Link>

                       <Link to='/payouts' className={classes.link}>
                            <ListItem button key={'Menu-Payouts'} className={classes.listItem}>
                                <ListItemIcon><MonetizationOnIcon className={classes.listItemIcon}/></ListItemIcon>
                                <ListItemText primary={<p className={classes.p}>Payouts</p>} className={classes.listItemText}/>
                            </ListItem>
                       </Link>

                        <ListItem button key={'Menu-Logout'} onClick={handleLogout} className={classes.listItem}>
                            <ListItemIcon><ExitToAppIcon className={classes.listItemIcon}/></ListItemIcon>
                            <ListItemText primary={<p className={classes.p}>Logout</p>} className={classes.listItemText}/>
                        </ListItem>  

                    </List>

                    <div style={{display:'flex', padding:15, flexDirection:'column', justifyContent:'center'}}>
                        <BuyTicketsBtn height={40} icon={true} fullWidth={true}/>


                        {/* <Link to='/create-wager' style={{textDecoration:'none'}}>
                        <Button  variant={'contained'} style={{ height:40, marginTop:10, backgroundColor:'#36AA3E', width:'100%'}} >
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',  alignItems:'center'}}>
                               <AddIcon style={{width:22, height:22, marginRight:5, marginTop:-2.3 }}/> 
                                <b>Create Wager</b>
                            </div>
                        </Button>
                        </Link> */}

                        <Link to='/create-tournament' style={{textDecoration:'none'}}>
                        <Button  variant={'contained'} style={{height:40, marginTop:10, backgroundColor:'#36AA3E', width:'100%'}} >
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'right',  alignItems:'center'}}>
                                {/* <AddIcon style={{width:22, height:22, marginRight:5, marginTop:-2.3 }}/> */}
                                <b>Host Tournament</b>
                            </div>
                        </Button>
                        </Link>

                       

                    </div>


                   
                </div>


            </Drawer>
        </React.Fragment>
       
    );

}