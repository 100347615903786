import React, { useEffect, useState } from 'react';
// import firebase from '../FirebaseClient'; // Adjust the import path as needed
import PersonIcon from '@mui/icons-material/Person';

const Enrolled = ({count, uids}) => {
    
    useEffect(() => {

       
    }, [count, uids]);


    useEffect(()=>{


    },[])

    return (

        <>
        
        
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
                <p style={{ padding: 0, margin: 0, marginRight: 5, color: 'black', fontSize: 14, fontWeight: 'bold' }}>{count}</p>
                <PersonIcon  style={{color:"black"}} />
                {/* <div style={{top:'25%', left:0, position:'fixed'}}>
                    <AvatarStack  height={200} spacing={-.2} avatars={onlineUsers} avatarSize={40}  maxCount={3} orientation='vertical' />
                </div> */}
            </div>
        
        </>
       
    );
};

export default Enrolled;