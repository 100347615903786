import React from 'react';
import Emitter from './Emitter';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


let showSandboxBadge =  process.env.REACT_APP_ENV != 'PROD';
let showPublicBetaBadge =  false;


// let showGameHub =  true;

export default function Notifier(){

    const navigate = useNavigate();

    React.useEffect(()=>{

        Emitter.on('NO-AUTH', () => {
            toast(<b>Please login before proceeding</b>, {type:'error', autoClose:5000});
        })

        Emitter.on('LOGGED-OUT', () => {
            toast(<b>Logged out</b>, {type:'success', autoClose:5000});
            navigate('/');
        })

        Emitter.on('MISC-ERROR', (code) => {
            toast(<b>{ `Error (${code}). Please contact support if this problem persists.`}</b>, {type:'error', closeButton:true, autoClose:false});
        })

        if(showSandboxBadge) {
            toast(<b>PAYPAL IN SANDBOX</b>, {type:'info', closeButton:false, autoClose:false});
            showSandboxBadge = false;
        }

        if(showPublicBetaBadge) {
            toast(<b>Public Beta</b>, {type:'warning', closeButton:true, autoClose:false});
            showPublicBetaBadge = false;
        }

        // if (showGameHub){
        //     toast(<b>Public Beta</b>, {type:'info', autoClose:false});
        //     showGameHub = false;
        // }

    
        
        return ()=>{
            Emitter.off('NO-AUTH');
            Emitter.off('LOGGED-OUT');
            Emitter.off('MISC-ERROR');
        }
          
        
    },[])

    return(<></>);

}