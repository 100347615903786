import React, {memo} from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";


import bullet_ from '../../../art/LichessIcons/bullet.gif';
import blitz_ from '../../../art/LichessIcons/blitz.gif';
import rapid_ from '../../../art/LichessIcons/rapid.gif';
import classical_ from '../../../art/LichessIcons/classical.gif';

import antiChess_ from '../../../art/LichessIcons/antiChess.gif';
import atomic_ from '../../../art/LichessIcons/atomic.gif';
import chess960_ from '../../../art/LichessIcons/chess960.gif';
import crazyhouse_ from '../../../art/LichessIcons/crazyhouse.gif';
import horde_ from '../../../art/LichessIcons/horde.gif';
import kingOfHill_ from '../../../art/LichessIcons/kingOfHill.gif';
import racingKings_ from '../../../art/LichessIcons/racingKings.gif';
import threeCheck_ from '../../../art/LichessIcons/threeCheck.gif';
// const bullet_ = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Fbullet.gif?alt=media&token=f6c27b67-a0c9-4eba-92de-be916bc3dcf1';
// const blitz_ = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Fblitz.gif?alt=media&token=ec3dc1c8-207e-430b-bab9-2c46d2d5e923';
// const rapid_ =  'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Frapid.gif?alt=media&token=d4119c3f-d6a0-4b2c-a9b6-65a12b96eb9f';
// const classical_ =  'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Fclassical.gif?alt=media&token=d3b8f755-cae5-430a-b9ca-dd7eab3ecff6';
// const antiChess_ =  'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2FantiChess.gif?alt=media&token=99755a78-87e3-4938-aeed-8ad57f585f54';
// const atomic_  = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Fatomic.gif?alt=media&token=d5be90d7-e2d0-4fc3-a16b-6effc29032af';
// const chess960_ = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Fchess960.gif?alt=media&token=c7f24d37-590f-4534-aa76-ec9ec07aa53e';
// const crazyhouse_  = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Fcrazyhouse.gif?alt=media&token=920bb487-37d7-4e32-882b-909a5c3ed81d';
// const horde_ = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2Fhorde.gif?alt=media&token=a5d0089e-3785-4333-9fe6-112cafb86d60';
// const kingOfHill_ = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2FkingOfHill.gif?alt=media&token=dd08d62e-26db-4ad8-bf26-963370323ad6';
// const racingKings_ = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2FracingKings.gif?alt=media&token=9d01c6cb-0ea1-48fb-b3ec-f2b834423aee';
// const threeCheck_ = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/Lichess3DAnimatedIcons%2FthreeCheck.gif?alt=media&token=3c7d0bc0-620c-47ef-886e-530f70d85e5d';


function TournamentIcon({perf, variant, size}){

    // if(variant == 'standard'){

    //   if(perf == 'bullet') return <LazyLoadImage src={bullet_} width={size} height={size} style={{marginBottom:5}} />
    //   if(perf == 'blitz') return  <LazyLoadImage src={blitz_} width={size} height={size} style={{marginBottom:5}} />
    //   if(perf == 'rapid') return  <LazyLoadImage src={rapid_} width={size} height={size} style={{marginBottom:5}} />
    //   if(perf == 'classical') return <LazyLoadImage src={classical_} width={size} height={size} style={{marginBottom:5}} />

    // } else {

    //   if (variant == 'antichess') return <LazyLoadImage src={antiChess_} width={size} height={size} style={{marginBottom:5}} />
    //   if (variant == 'atomic') return <LazyLoadImage src={atomic_} width={size} height={size} style={{marginBottom:5}} />
    //   if (variant == 'chess960')  return <LazyLoadImage src={chess960_} width={size} height={size} style={{marginBottom:5}} />
    //   if (variant == 'crazyhouse') return  <LazyLoadImage src={crazyhouse_} width={size} height={size} style={{marginBottom:5}} />
    //   if (variant == 'horde') return  <LazyLoadImage src={horde_} width={size} height={size} style={{marginBottom:5}} />
    //   if (variant == 'kingOfHill')  return  <LazyLoadImage src={kingOfHill_} width={size} height={size} style={{marginBottom:5}} />
    //   if (variant == 'racingKings') return  <LazyLoadImage src={racingKings_} width={size} height={size} style={{marginBottom:5}} />
    //   if (variant == 'threeCheck') return  <LazyLoadImage src={threeCheck_} width={size} height={size} style={{marginBottom:5}} />

    // }

    if(variant == 'standard' || variant == null){

      if(perf == 'bullet') return <img src={bullet_} width={size} height={size} style={{marginBottom:5}} />
      if(perf == 'blitz') return  <img src={blitz_} width={size} height={size} style={{marginBottom:5}} />
      if(perf == 'rapid') return  <img src={rapid_} width={size} height={size} style={{marginBottom:5}} />
      if(perf == 'classical') return <img src={classical_} width={size} height={size} style={{marginBottom:5}} />

    } else {

      if (variant == 'antichess') return <img src={antiChess_} width={size} height={size} style={{marginBottom:5}} />
      if (variant == 'atomic') return <img src={atomic_} width={size} height={size} style={{marginBottom:5}} />
      if (variant == 'chess960')  return <img src={chess960_} width={size} height={size} style={{marginBottom:5}} />
      if (variant == 'crazyhouse') return  <img src={crazyhouse_} width={size} height={size} style={{marginBottom:5}} />
      if (variant == 'horde') return  <img src={horde_} width={size} height={size} style={{marginBottom:5}} />
      if (variant == 'kingOfHill')  return  <img src={kingOfHill_} width={size} height={size} style={{marginBottom:5}} />
      if (variant == 'racingKings') return  <img src={racingKings_} width={size} height={size} style={{marginBottom:5}} />
      if (variant == 'threeCheck') return  <img src={threeCheck_} width={size} height={size} style={{marginBottom:5}} />

    }

}

export default memo(TournamentIcon);
