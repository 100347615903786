// NOT GUARANTEED TO BE SAME AS BACKEND

export var FORMULAS_MAP = {
  'amped': amped,
  'podium': podium,
  'winner-takes-all': winnerTakesAll,
  'top-3': top3,
  'async': async,
}

export function podium(count){

  var podiumArray = [count]

  if (count <= 4){

    podiumArray = [count];

  } else if (count <= 10) {

    let first = Math.trunc(count * .6);
    let second = Math.trunc(count * .2);
    let third = Math.trunc(count * .2);

    let sum = first + second + third;

    let diff = count - sum;

    if(diff > 0) {

      second = second + diff;

    }

    podiumArray = [first, second, third]

  } else if (count <= 50) {


    let first = Math.trunc(count * .5);
    let second = Math.trunc(count * .2);
    let third = Math.trunc(count * .2);
    let fourth = Math.trunc(count * .1);

    let sum = first + second + third + fourth;

    let diff = sum - count;

    if(diff < 0)  first -= diff;
    if(diff > 0)  second +=  diff;


    podiumArray = [first, second, third, fourth]


  } else if (count <= 100) {

    let first = Math.trunc(count * .5);
    let second = Math.trunc(count * .2);
    let third = Math.trunc(count * .1);
    let fourth = Math.trunc(count * .1);
    let fifth = Math.trunc(count * .1);

    let sum = first + second + third + fourth + fifth;

    let diff = sum - count;

    if(diff < 0)  first -= diff;
    if(diff > 0)  second +=  diff;


    podiumArray = [first, second, third, fourth, fifth]

  } else if (count <= 200) {

    let first = Math.trunc(count * .4);
    let second = Math.trunc(count * .3);
    let third = Math.trunc(count * .1);
    let fourth = Math.trunc(count * .1);
    let fifth = Math.trunc(count * .1);

    let sum = first + second + third + fourth + fifth;

    if((count - sum) > 0) {

      third = third + (count - sum);

    }

    podiumArray = [first, second, third, fourth, fifth]

  } else {

    let first = Math.trunc(count * .3);
    let second = Math.trunc(count * .2);
    let third = Math.trunc(count * .1);
    let fourth = Math.trunc(count * .1);
    let fifth = Math.trunc(count * .075);
    let sixth = Math.trunc(count * .075);
    let seventh = Math.trunc(count * .05);
    let eighth = Math.trunc(count * .05);
    let nineth = Math.trunc(count * .025);
    let tenth = Math.trunc(count * .025);

    let sum = first + second + third + fourth + fifth + sixth + seventh + eighth + nineth + tenth;

    let diff = count - sum;

    if(diff > 0) {

      first = first + diff;

    }

    podiumArray = [first, second, third, fourth, fifth, sixth, seventh, eighth, nineth, tenth];

  }

  return podiumArray;

}

export function amped(count){

  let first = Math.trunc(count*.21)
  let second = Math.trunc(count*.19)
  let award1 = Math.trunc(count*.12)
  let award2 = Math.trunc(count*.12)
  let award3 = Math.trunc(count*.12)
  let award4 = Math.trunc(count*.12)
  let award5 = Math.trunc(count*.12)

  let sum = first + second + award1 + award2 + award3 + award4 + award5;

  let diff = count - sum;

  if(diff > 0) {

    first = first + diff;

  }
  
  return [first, second, award1, award2, award3, award4, award5]

}

export function top3(count){

  var podiumArray = [count]

  if (count <= 4) {

    podiumArray = [count];

  } else {

    let first = Math.trunc(count * .5);
    let second = Math.trunc(count * .3);
    let third = Math.trunc(count * .2);

    let sum = first + second + third;

    let diff = count - sum;

    if(diff > 0) {

      second = second + diff;

    }

    podiumArray = [first, second, third]

  }

  return podiumArray;

}

export function winnerTakesAll(count){

  return [count];

}


export function async(count){

  let first = Math.trunc(count*.20)
  let award1 = Math.trunc(count*.16)
  let award2 = Math.trunc(count*.16)
  let award3 = Math.trunc(count*.16)
  let award4 = Math.trunc(count*.16)
  let award5 = Math.trunc(count*.16)

  let sum = first + award1 + award2 + award3 + award4 + award5;

  let diff = count - sum;

  if(diff > 0) {

    first = first + diff;

  }
  
  return [first, award1, award2, award3, award4, award5]

}
