import React, {useMemo, memo} from 'react';
import { getMyLichessTournaments } from '../../../Firebase';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TournamentCard from './TournamentCard';
import useUser from '../../../hooks/useUser';
import RowsLoading from '../../RowsLoading';
import Emitter from '../../../Emitter';
import UserTournamentsRows from './UserTournamentRows';


function MyTournaments() {


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [user, auth, userLoading] = useUser();

    const [loading, setLoading] = React.useState([]);
    const [tournaments, setTournaments] = React.useState([]);


    React.useEffect(()=>{
        window.scrollTo(0, 0);
    },[])


    async function init() {
        if(user && auth){
            setLoading(true);
            setTournaments(await getMyLichessTournaments());
            setLoading(false);
        }
    }


    React.useEffect(()=>{

    
        init();


    },[user, auth])

    React.useEffect(()=>{

        init();

        Emitter.on('TOURNAMENT-JOIN-SUCCESS', () => {
            init();
        })
     
        return () => {Emitter.off('TOURNAMENT-JOIN-SUCCESS')};


    },[])


    function Empty(){

        return (

            <div  style={{display:'flex',flexDirection:'row', alignItems:'center', borderRadius:7, justifyContent:'center'}}>

                <p style={{fontWeight:'bold', fontSize:14}}>None</p>

            </div>

        );


    }


    function TournamentRows({select, t}){

        let upcoming = [];
        let completed = [];
        let created = [];

        let data = t.data;
        let dataCreated = t.created;

        for(var i = 0; i < data.length; i++){

            let card = <TournamentCard key={`tcard-${data[i]['id']}`} tournament={data[i]} user={user} auth={auth} userLoading={userLoading}/>;

            if(Date.now() >= data[i]['endDate']){
                completed.push(card);
            } else {
                upcoming.push(card);
            }

        }


        for(var i = 0; i < dataCreated.length; i++){

            let card = <TournamentCard key={`tcard-${dataCreated[i]['id']}`} tournament={dataCreated[i]} user={user} auth={auth} userLoading={userLoading}/>;
            created.push(card);

        }

        created.reverse()


        // if (dataCreated.length > 0) {
        //     // dataCreated.reverse()
        //     created = <UserTournamentsRows t={dataCreated} />
        // }

        // for(var i = 0; i < dataCreated.length; i++){


        //     let card = <TournamentCard key={`tcard-${dataCreated[i]['id']}`} tournament={dataCreated[i]} user={user} auth={auth} userLoading={userLoading}/>;

        //    created.push(card);

        // }

        if(completed.length > 0) completed.reverse();

        if (upcoming.length == 0) upcoming = Empty();
        if (completed.length == 0) completed = Empty();
        if (created.length == 0) created = Empty();

        return(
            <>
                {(select == 0) && upcoming}
                {(select == 1) && completed}
                {(select == 2) && created}
            </>
        )

    }


    function TTabs({select, handleChangeSelect }) {
        

        // TODO change text size

        const render = useMemo(() => {

            return(
             
                // <Tabs  style={{ borderRadius:10}} value={select} onChange={handleChangeSelect} >
                //     <Tab  style={{fontSize:(width < 460)?12:14}}  color='secondary' label='Upcoming'  />
                //     <Tab  style={{fontSize:(width < 460)?12:14}} label='Completed' />
                //     <Tab  style={{fontSize:(width < 460)?12:14}} label='Created' />
                // </Tabs>
                <Tabs  style={{ borderRadius:10}} value={select} onChange={handleChangeSelect} >
                    <Tab  style={{fontSize:12}}  color='secondary' label='Upcoming'  />
                    <Tab  style={{fontSize:12}} label='Completed' />
                    <Tab  style={{fontSize:12}} label='Created' />
                </Tabs>
            )
       
        }, []);
      
        return <>{render}</>;
    }

    return (

        <div style={{padding:20}}>

            <div style={{display:'flex', marginBottom:20, flexDirection:'row', justifyContent:'right'}}>

            <TTabs select={value} handleChangeSelect={handleChange}/>

            </div>
          

            {loading?
                <RowsLoading height={125}/>
                    :
                    
                <TournamentRows select={value} t={tournaments}/>
            }
     
        </div>

    );

}


export default memo(MyTournaments);
