
import React, { useEffect } from 'react';
import Emitter from '../../../Emitter';
import { Skeleton } from '@mui/material';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import {getTournamentResultsLichessAPI} from '../../../Lichess/api';
import { useLichessResultsStyles } from '../../MuiStyles';
import ErrorCard from '../ErrorCard';
import lichessBlue from '../../../art/LichessBlue.png';
import { openInNewTab } from '../../../utils';

export default function PlayerList({usernameArray}) {

    const classes = useLichessResultsStyles();

    const [resultsLoading, setResultsLoading] = React.useState(false);
    // const [results, setResults] = React.useState({data:[]});

    // async function initResults(){

    //     setResultsLoading(true);

    //     if(tournament['users'].length > 0 && tournament['results'].length == 0){

    //         let r = await getTournamentResultsLichessAPI(tournament['id']);
    //         if (r != -1) { 
    //             setResults({data:r['data']})
    //         } else {
    //             setResults({data:-1})
    //         }

    //     } else {

    //         setResults({data:tournament['results']});

    //     }

    //     setResultsLoading(false);

    // }

    React.useEffect(()=>{

        console.log(usernameArray);

    },[usernameArray])


    React.useEffect(()=>{

        // initResults();

        Emitter.on('TOURNAMENT-JOIN-SUCCESS', () => {
            // initResults();
        })

        return () => {Emitter.off('TOURNAMENT-JOIN-SUCCESS')};

    },[])

  
    function Rows({entries}){



        if(entries.length == 0) return ;

        let render = [];

        // let maxEntries = entries.length;

        // if (limit && limit < maxEntries) maxEntries = limit;

        console.log(entries);

        for(var i = 0; i < entries.length; i++){

            let entry = entries[i];


            let username = entry;
            // let rank = entry.rank;
            // let username = entry.username;
            // let title = entry.title || '';
            // let rating = entry.rating;
            // let score = entry.score;
            // let podium = entry.podium
            

            render.push(

                // <div key={'results-row-'+i} style={{height:25, alignItems:'center', backgroundColor:(i%2==0)?'white':'white', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>

                //     <div className={classes.rowLeftContainer}>
                //         <p style={{fontSize:10, color:'gray', marginRight:10}}>{rank}</p>
                //         {(title != '') && <p className='descrip5' style={{fontSize:14, fontWeight:'bold', marginRight:5}}>{title}</p>}
                //         {/* {(title == '') && <p className='descrip5' style={{fontSize:14, fontWeight:'bold', marginRight:5}}>{'FM'}</p>} */}

                //         <p style={{fontSize:14, fontWeight:'bold', color:'#1f2022', marginRight:5}}>{username}</p>
                //         <p style={{fontSize:12, fontWeight:'normal', color:'gray'}}>{rating}</p>
                //         {podium && <MilitaryTechIcon style={{width:19, height:19, marginLeft:3, marginTop:-3}}/>}
                //     </div>

                //     <div>
                //         <p style={{fontSize:15, color:'#907aeb'}}>
                //             {score}
                //         </p>
                //     </div>

                // </div>

                <div key={'results-row-'+i} style={{marginTop:(i!=0)?5:0, height:25, alignItems:'center', backgroundColor:(i%2==0)?'white':'white', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>

                    <div className={classes.rowLeftContainer}>
                        {/* <p style={{padding:0, margin:0, fontSize:10, color:'gray', marginRight:10}}>{i+1}</p> */}
                        {/* {(title != '') && <p className='descrip5' style={{fontSize:14, fontWeight:'bold', marginRight:5}}>{title}</p>} */}
                        {/* {(title == '') && <p className='descrip5' style={{fontSize:14, fontWeight:'bold', marginRight:5}}>{'FM'}</p>} */}

                        <p style={{padding:0, margin:0, fontSize:14, fontWeight:'bold', color:'#1f2022', marginRight:5}}>{username}</p>
                        {/* <p style={{fontSize:12, fontWeight:'normal', color:'gray'}}>{rating}</p> */}
                        {/* {podium && <MilitaryTechIcon style={{width:19, height:19, marginLeft:3, marginTop:-3}}/>} */}
                    </div>

                    <img  onClick={()=>openInNewTab(`https://lichess.org/@/${username}`)} width={20} height={20} src={lichessBlue}/>

                    {/* <div>
                        <p style={{fontSize:15, color:'#907aeb'}}>
                            {score}
                        </p>
                    </div> */}

                </div>
               
            )

        }

        return(
            <>
                {render}
            </>
        )

    }


    // useEffect(()=>{


    //     console.log(results);


    // },[results])


    return (

        <>
            {(resultsLoading) ?

                <Skeleton style={{borderRadius:5, marginTop:20}} variant='rounded'  height={255} />
                :
                <>
                    {/* {(results.data == -1) ?

                        <ErrorCard/>
                        
                        : */}

                        <>
                            {(usernameArray.length == 0)?<></>:

                                <div className={'card'}  style={{ display:'flex',  marginTop:20, backgroundColor:'white',  flexDirection:'column'}}>

                                    <div style={{display:'flex', flexDirection:'column'}}>

                                        {/* {hideCard && <p style={{textAlign:'left', padding:0, margin:0, marginBottom:10, fontSize:14}}>Podium</p>} */}
                                        <Rows entries={usernameArray}/>
                                    </div>

                                </div>

                            }
                        </>
                       
                    {/* } */}
                </>

            }

        </>
    );


}

