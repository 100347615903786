
import React from 'react';
import { Button, Dialog } from '@mui/material';
import { joinLichessTournamentAsync } from '../../../Firebase';
import LichessButton from '../LichessButton';
import { atom_lichessOauthToken } from '../../../Atoms';
import { useRecoilValue } from 'recoil';


export default function JoinDialogue({tournament, openJoinDialogue, setOpenJoinDialogue}) {

  const lichessOauthToken = useRecoilValue(atom_lichessOauthToken);
 
  async function handleJoinTournamnet(){
    setOpenJoinDialogue(false);
    await joinLichessTournamentAsync(tournament['id'], lichessOauthToken);
  }


  function AgreementText(){
    
    return (
      <p style={{padding:0, margin:0}}>

        <b>Flex Play Enroll </b>- Games where you have 100+ rating advantage will not be considered in your score.
      
      
       You may be disqualified and refuded for a win/loss record that deviates significantly from what is expected of your rating.

       By clicking "Join," you agree to all of Amped Chess's gaming <a href='/policies'>policies</a>. 

        {/* <b>WARNING</b> - Amped Chess does not take responsibility for players who misrepresent their skill level or who have unauthorized third parties make moves for them. */}

      </p>
    )

  }

  function handleClose(){
    setOpenJoinDialogue(false);
  }

  return (

    
        <Dialog fullWidth  open={openJoinDialogue} onClose={handleClose} 
        
            PaperProps={{
                style:{
                  borderTopRightRadius:7,
                  borderTopLeftRadius:7,
                  borderBottomLeftRadius:7,
                  borderBottomRightRadius:7,
                }
            }} >

            <div style={{padding:20, display:'flex', flexDirection:'column', alignItems:'center'}}>

                <AgreementText/>

                <div style={{width:'100%', marginTop:20}}>

                <LichessButton/>

                <Button  onClick={handleJoinTournamnet} disabled={lichessOauthToken==''}  style={{width:'100%', height:50, marginTop:10}} variant='contained'>
                  <b>JOIN</b>
                </Button> 
  
                </div>

            </div>

        </Dialog>

  );


}

