import React, { useState, useEffect, useRef } from 'react';
import ChessClock from './ChessClock';
import ChessBoard from './ChessBoard';
import useWindowSize from '../../../hooks/WindowDimensions';
import GameInfo from './GameInfo';


function TV() {

    const [challengeId, setChallengeId] = useState(null);

    const [timestamp, setTimestamp] = useState(null);

    const [whiteUsername, setWhiteusername] = useState(null);
    const [blackUsername, setBlackusername] = useState(null);

    const [whiteTitle, setWhiteTitle] = useState(null);
    const [blackTitle, setBlackTitle] = useState(null);

    const [whiteRating, setWhiteRating] = useState(null);
    const [blackRating, setBlackRating] = useState(null);

    const [currentFen, setCurrentFen] = useState('start');
    
    const [wc, SetWc] = useState(null);
    
    const [bc, setBc] = useState(null);
    
    const streamController = useRef(new AbortController());
    
    const {width} = useWindowSize()

    useEffect(() => {
        const fetchStream = async () => {
            try {
                const response = await fetch('https://lichess.org/api/tv/feed', {
                    signal: streamController.current.signal
                });

                const reader = response.body.getReader();
                const decoder = new TextDecoder();

              

                while (true) {
                    
                    const { done, value } = await reader.read();
                    if (done) break;

                    const textChunk = decoder.decode(value, { stream: true });
                    const lines = textChunk.split('\n');

                    for (const line of lines) {
                        
                        if (line) {

                            setTimestamp(Date.now());
                            
                            const gameData = JSON.parse(line);
                            
                            if (gameData.t == 'featured'){
                                let d = gameData.d
                                setChallengeId(d.id);
                                setWhiteusername(d.players[0].user.id);
                                setBlackusername(d.players[1].user.id);
                                setWhiteTitle(d.players[0].user.title);
                                setBlackTitle(d.players[1].user.title);
                                setWhiteRating(d.players[0].rating);
                                setBlackRating(d.players[1].rating);
                            }

                            if (gameData.t == 'fen') {

                                setCurrentFen(gameData.d.fen);
                                SetWc(gameData.d.wc);
                                setBc(gameData.d.bc);
                            }
                        }
                    }
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Stream connection failed:', error);
                } else {
                    console.error('Stream error', error);
                }
            }
        };

        fetchStream();

       
    }, []);

    return (
        <div style={{padding:20, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:"column" }}>
                <div className='card' style={{padding:0,   borderRadius: '10px',boxShadow: '0 5px 15px rgba(0, 0, 0, 0.6)',}}>
                    <ChessClock isWhite={false} time={bc} title={blackTitle} username={blackUsername} rating={blackRating} />
                    <ChessBoard
                        fen={currentFen}
                        size={width*.88}
                        draggable={false}
                    />
                    <ChessClock isWhite={true} time={wc} title={whiteTitle} username={whiteUsername} rating={whiteRating} />
                </div>

                <div style={{marginTop:15}}/>
                
                <GameInfo challengeId={challengeId} lastUpdate={timestamp}/>

            </div>

          

        </div>
    );
}

export default TV;
