import React from 'react';
import { useParams } from 'react-router-dom';
import PayoutChart from './PayoutChart';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';



export default function PayoutChartPage() {
    let { tickets, bonusTickets } = useParams();

    const [entryTickets, setEntryTickets] = React.useState(1);
    const [formula, setFormula] = React.useState('amped'); // default formula

    React.useEffect(() => {
        setEntryTickets(tickets ? tickets : 5);
    }, [tickets]);

    const handleFormulaChange = (event) => {
        setFormula(event.target.value);
    };

    

    return (
        <>
        <div style={{ padding: 20,}}>
            <PayoutChart
                entryTickets={entryTickets}
                n={125}
                max={250}
                showTickets={true}
                formula={formula}
                bonusTickets={bonusTickets}
                min={10}
            />
        </div>

        <div style={{paddingLeft:20, paddingRight:20, display:'flex', flexDirection:'row', justifyContent:'center'}}>
        <FormControl style={{width:'100%', maxWidth:690}}>
                <InputLabel id="formula-select-label">Formula</InputLabel>
                <Select
                    labelId="formula-select-label"
                    id="formula-select"
                    value={formula}
                    label="Formula"
                    onChange={handleFormulaChange}
                >
                    <MenuItem value="amped">Amped</MenuItem>
                    <MenuItem value="podium">Podium</MenuItem>
                    <MenuItem value="top-3">Top 3</MenuItem>
                    <MenuItem value="winner-takes-all">Winner Takes All</MenuItem>
                </Select>
            </FormControl>
            </div>
        

        </>
    );
}
