import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Link} from "react-router-dom";
import ChessLogoLoading from './ChessLogoLoading';
import useWindowSize from '../hooks/WindowDimensions';
import LoginModal from './LoginModal';
import Socials from './Lichess/Socials';
import ActiveUsers from './ActiveUsers';
import AvatarStack from './AvatarStack';

export default function NavBar() {

    const {width} = useWindowSize()

    function NameText({width}){


        if (width < 400) {

            return <></>

        } else if (width < 500) {

            return <p style={{marginLeft:15, fontSize:20, color:'white'}}><b>Amped</b> Chess</p>

        } else {

            // return <p style={{marginLeft:15, fontSize:20, color:'white'}}><b>Amped Chess</b> League</p>
            // return <p style={{marginLeft:15, fontSize:20, color:'white'}}><b>Amped Chess</b></p>
            return <p style={{marginLeft:15, fontSize:20, color:'white'}}><b>Amped </b> Chess</p>



        }


    }


    return (
       
        <AppBar   position='static'>
            <Toolbar  style={{ height:70, display:'flex', flexDirection:'row', justifyContent:'space-between', backgroundColor:'#1f2022'}}>
                
                    <Link to='/' style={{textDecoration:'none'}}>
                        <div style={{display:'flex',  flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                            <ChessLogoLoading size={59}/>
                            <NameText width={width}/>
                        </div>

                    </Link>
                    

                    <div style={{display:'flex', justifyContent:'right', flexDirection:'row'}}>
                        {/* <ActiveUsers/> */}
                        <Socials size={25} margin={15}/>
                        <div style={{marginRight:18}}></div>
                        <LoginModal/>
                    </div>


            </Toolbar>
        </AppBar>
      
    );
}
