import firebase from './FirebaseClient';
import Emitter from './Emitter';
import { toast } from 'react-toastify';
import { trim } from './utils';


export async function lastSeen() {

    try{
        let lastSeen = firebase.functions().httpsCallable('lastSeen');
        await lastSeen();
    } catch(err) {
        // console.log(err);
    }

}

export async function toggle_email_setting(field, value) {


    const notification = toast.loading(<b>{'Updating'}</b>);

    try{

        
        let toggle_email_setting = firebase.functions().httpsCallable('toggle_email_setting');
        let toggled = (await toggle_email_setting({field, value})).data

        console.log(toggled);

        if(toggled){
            toast.update(notification, { render: <b>Updated</b>, type: 'success', isLoading: false, autoClose:1500 });
        } else {
            toast.update(notification, { render: <b>Update Failed</b>, type: 'error', isLoading: false, autoClose:1500 });
        }


    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>Update Failed</b>, type: 'error', isLoading: false, autoClose:1500 });

    }

}

/** PROFILE */

export async function updateUsername(username){
    try{
        let updateUsername = firebase.app().functions('us-central1').httpsCallable('updateUsername');
        let response = (await updateUsername({username})).data;
        return response;
    } catch(err) {
        console.log(err);
    }
}

export async function updateAccountDetails(name, bio, country, website){
    try{
        let updateAccount = firebase.app().functions('us-central1').httpsCallable('updateAccountDetails');
        let response = (await updateAccount({name, bio, country, website})).data;
        return response;
    } catch(err) {
        console.log(err);
    }
}

export async function updateAvatar(downloadURL){
    try{
        let updateAvatar = firebase.app().functions('us-central1').httpsCallable('updateAvatar');
        let response = (await updateAvatar({downloadURL})).data;
        return response;
    } catch(err) {
        console.log(err);
    }
}


/** Amped Chess */

export async function getAmpedChessPayoutFees() {

    try{
        let getAmpedChessPayoutFees = firebase.functions().httpsCallable('getAmpedChessPayoutFees');
        let response = (await getAmpedChessPayoutFees()).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }

}

export async function getLichessPaymentFees() {

    try{
        let getLichessPaymentFees = firebase.functions().httpsCallable('getLichessPaymentFees');
        let response = (await getLichessPaymentFees()).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }

}

export async function createTournamentEntryOrder(count) {


    let response = null;

    try{
        let createLichessTicketOrder = firebase.functions().httpsCallable('createLichessTicketOrder');
        response = (await createLichessTicketOrder({count})).data;
    } catch(err) {
        console.log(err);
    }

    if (response == null) Emitter.emit('MISC-ERROR', 1);
    if (response == -1) Emitter.emit('MISC-ERROR', 1);
    
    return response;

}

export async function createTournamentEntryOrder2(count, discount_code) {


    let response = null;

    try{
        let createLichessTicketOrder2 = firebase.functions().httpsCallable('createLichessTicketOrder2');
        response = (await createLichessTicketOrder2({count, discount_code})).data;
    } catch(err) {
        console.log(err);
    }

    if (response == null) Emitter.emit('MISC-ERROR', 1);
    if (response == -1) Emitter.emit('MISC-ERROR', 1);
    if (response == -2) toast(<b>This amount exceeds the promo's value</b>, {type:'error', autoClose:5000});
    
    return response;

}




export async function handleTournamentEntryOrder(orderId){


    const notification = toast.loading(<b>{'Purchasing'}</b>);

    try{

        let handleLichessTicketOrder = firebase.functions().httpsCallable('handleLichessTicketOrder');
        let response = (await handleLichessTicketOrder({orderId})).data;
        if(response['status'] === 'COMPLETED'){
            toast.update(notification, { render: <b>Ticket Purchase Complete</b>, type: 'success', isLoading: false, autoClose:7000 });
        } else {
            toast.update(notification, { render: <b>Ticket Purchase Failed</b>, type: 'error', isLoading: false, autoClose:7000 });
        }
    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>Ticket Purchase Failed</b>, type: 'error', isLoading: false, autoClose:7000 });
    }

}

export async function userCreateLichessTournament(lichessToken, tournamentParams, bonusFundsCode){

    const notification = toast.loading(<b>{'Creating tournament'}</b>);

    let created = false;

    bonusFundsCode = trim(bonusFundsCode);

    try{
        let userCreateLichessTournament = firebase.functions().httpsCallable('userCreateLichessTournament');
        let response = (await userCreateLichessTournament({lichessToken, tournamentParams, bonusFundsCode})).data;
        
        if(response['created'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            Emitter.emit('CREATE-TOURNAMENT-SUCCESS', Date.now());
            created = true;

        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
        }

    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });
    }

    return created;

}

// export async function joinLichessTournament(id, lichessUsername){

export async function joinLichessTournament(id, lichessToken){

    // console.log(id, lichessToken)

    
    const notification = toast.loading(<b>{'Enrolling'}</b>);

    try{
        let joinLichessTournament = firebase.functions().httpsCallable('joinLichessTournament');
        // let response = (await joinLichessTournament({id, lichessUsername})).data;
        let response = (await joinLichessTournament({id, lichessToken})).data;


        if(response['joined'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            Emitter.emit('TOURNAMENT-JOIN-SUCCESS', Date.now());
            return true;
        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
        }

    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });

    }

    return false;

}

export async function getLichessTournamentPassword(tournamentId){
    
    try{
        let getLichessTournamentPassword = firebase.functions().httpsCallable('getLichessTournamentPassword');
        let response = (await getLichessTournamentPassword({tournamentId})).data;
        return response;

    } catch(err) {
        console.log(err);
        return 'Error';
    }

}

export async function getFutureLichessTournaments(){

    try{
        let getFutureLichessTournaments = firebase.functions().httpsCallable('getFutureLichessTournaments');;
        let response = (await getFutureLichessTournaments()).data;
        if(response['status'] == 200) return response['data'];
    } catch(err) {
        console.log(err);
        return [];
    }

}

export async function getLichessAsyncFutureTournaments(){

    try{
        let getLichessAsyncFutureTournaments = firebase.functions().httpsCallable('getLichessAsyncFutureTournaments');;
        let response = (await getLichessAsyncFutureTournaments()).data;
        if(response['status'] == 200) return response['data'];
    } catch(err) {
        console.log(err);
        return [];
    }

}

export async function getMyLichessTournaments(){

    try{
        let getMyLichessTournaments = firebase.functions().httpsCallable('getMyLichessTournaments');;
        let response = (await getMyLichessTournaments()).data;
        if(response['status'] == 200) return {data:response['data'], created:response['created']};
    } catch(err) {
        console.log(err);
        return  {data:-1, created:-1};
    }

}

export async function getMyLichessTournamentsAsync(){

    try{
        let getMyLichessTournamentsAsync = firebase.functions().httpsCallable('getMyLichessTournamentsAsync');;
        let response = (await getMyLichessTournamentsAsync()).data;
        if(response['status'] == 200) return {data:response['data']};
    } catch(err) {
        console.log(err);
        return  {data:-1};
    }

}

export async function getLichessTournament(tournamentId){

    try{
        let getLichessTournament = firebase.functions().httpsCallable('getLichessTournament');;
        let response = (await getLichessTournament({tournamentId})).data;
        return response
    } catch(err) {
        console.log(err);
        return null;
    }

}

export async function getLichessAsyncTournament(tournamentId){

    try{
        let getLichessAsyncTournament = firebase.functions().httpsCallable('getLichessAsyncTournament');;
        let response = (await getLichessAsyncTournament({tournamentId})).data;
        return response
    } catch(err) {
        console.log(err);
        return null;
    }

}

export async function getMyLichessTournamentPayouts(){

    try{
        let getMyLichessTournamentPayouts = firebase.functions().httpsCallable('getMyLichessTournamentPayouts');;
        let response = (await getMyLichessTournamentPayouts()).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }

}

export async function getLichessTournamentTickets(){

    try{
        let getLichessTournamentTickets = firebase.functions().httpsCallable('getLichessTournamentTickets');;
        let response = (await getLichessTournamentTickets()).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }
}

export async function getMyLichessTournamentStats(){

    try{
        let getMyLichessTournamentStats = firebase.functions().httpsCallable('getMyLichessTournamentStats');;
        let response = (await getMyLichessTournamentStats()).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }
}

export async function beginLichessTournamentPayout(pid, email, countryCode, phoneNumber){


    const notification = toast.loading(<b>{'Requesting payout'}</b>);

    try{
        let beginLichessTournamentPayout = firebase.functions().httpsCallable('beginLichessTournamentPayout');
        let response = (await beginLichessTournamentPayout({pid, email, countryCode, phoneNumber})).data;

        if(response['success'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            Emitter.emit('PAYOUT-SENT-SUCCESS', Date.now());
        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
        }


    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });
    }


}

export async function getLichessPayoutStatus(pid){

    try{
        let getLichessPayoutStatus = firebase.functions().httpsCallable('getLichessPayoutStatus');
        let response = (await getLichessPayoutStatus({pid})).data;
        return response;
    } catch(err) {
        console.log(err);
        return {status:null, message:'Interval server error(00).'}
    }

}

export async function getLichessPromotion(pid){

    try{
        let getLichessPromotion = firebase.functions().httpsCallable('getLichessPromotion');
        let response = (await getLichessPromotion({pid})).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }

}

export async function lichessPromotion(pid){

    const notification = toast.loading(<b>{'Redeeming'}</b>);

    try{
        let lichessPromotion = firebase.functions().httpsCallable('lichessPromotion');;
        let response = (await lichessPromotion({pid})).data;

        if(response['redeemed'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            Emitter.emit('PROMOTION-REDEEM-SUCCESS', Date.now());
        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
        }


    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });
        return null;
    }

}

export async function createLichessWagerSlip(lichessToken, wagerParams){

    const notification = toast.loading(<b>{'Creating wager slip'}</b>);

    let created = false;

    try{
        let createLichessWagerSlip = firebase.functions().httpsCallable('createLichessWagerSlip');
        let response = (await createLichessWagerSlip({lichessToken, wagerParams})).data;

        if(response['created'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            // Emitter.emit('CREATE-TOURNAMENT-SUCCESS', Date.now());
            created = true;

        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
        }

    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });
    }

    return created;

}

export async function getMyLichessWagerStats(){

    try{
        let getMyLichessWagerStats = firebase.functions().httpsCallable('getMyLichessWagerStats');;
        let response = (await getMyLichessWagerStats()).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }
}

export async function getActiveLichessWagers(){

    try{
        let getActiveLichessWagers = firebase.functions().httpsCallable('getActiveLichessWagers');;
        let response = (await getActiveLichessWagers()).data;
        if(response['status'] == 200) return response['wagers'];
    } catch(err) {
        console.log(err);
        return [];
    }

}

export async function getLichessWager(wagerId){

    try{
        let getLichessWager = firebase.functions().httpsCallable('getLichessWager');;
        let response = (await getLichessWager({wagerId})).data;
        return response;
    } catch(err) {
        console.log(err);
        return -1;
    }

}

export async function cancelLichessWager(wagerId){

    const notification = toast.loading(<b>{'Canceling wager'}</b>);

    let canceled = false;

    try{

        let cancelLichessWager = firebase.functions().httpsCallable('cancelLichessWager');;
        let response = (await cancelLichessWager({wagerId})).data;

        if(response['canceled'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            canceled = true;

        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
        }

    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });
    }

    return canceled;

}

export async function getMyLichessWagers(){

    try{
        let getMyLichessWagers = firebase.functions().httpsCallable('getMyLichessWagers');;
        let response = (await getMyLichessWagers()).data;
        if(response['status'] == 200) return response['data'];
    } catch(err) {
        console.log(err);
        return -1;
    }

}

export async function acceptLichessWager(lichessToken, wagerId){
    
    const notification = toast.loading(<b>{'Accepting'}</b>);

    try{
        let acceptLichessWager = firebase.functions().httpsCallable('acceptLichessWager');
        let response = (await acceptLichessWager({lichessToken, wagerId})).data;

        if(response['accepted'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            return true;
        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
            return false;
        }

    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });
        return false;

    }

}


export async function lichessIsBonusFundCodeValid(bid){
    
    const notification = toast.loading(<b>{'Validating code'}</b>);

    bid = trim(bid);

    try{
        let lichessIsBonusFundCodeValid = firebase.functions().httpsCallable('lichessIsBonusFundCodeValid');
        let response = (await lichessIsBonusFundCodeValid({bid})).data;

        if(response['valid'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            return true;
        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
            return false;
        }

    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });
        return false;

    }

}

export async function joinLichessTournamentAsync(id, lichessToken){

    // console.log(id, lichessToken)

    
    const notification = toast.loading(<b>{'Enrolling'}</b>);

    try{
        let joinLichessAsyncTournament = firebase.functions().httpsCallable('joinLichessAsyncTournament');
        // let response = (await joinLichessTournament({id, lichessUsername})).data;
        let response = (await joinLichessAsyncTournament({id, lichessToken})).data;


        if(response['joined'] == true){
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'success', isLoading: false, autoClose:7000 });
            Emitter.emit('TOURNAMENT-JOIN-SUCCESS', Date.now());
        } else {
            toast.update(notification, { render: <b>{response['message']}</b>, type: 'error', isLoading: false, autoClose:7000 });
        }

    } catch(err) {
        console.log(err);
        toast.update(notification, { render: <b>{'Internal server error (000).'}</b>, type: 'error', isLoading: false, autoClose:7000 });

    }

}

export let usersDB = firebase.firestore().collection('users');
export let lichessTournamentsDB = firebase.firestore().collection('chess').doc('lichess').collection('tournaments');
export let lichessTournamentsAsyncDB = firebase.firestore().collection('chess').doc('lichess').collection('tournaments_async');
export let lichessWagersDB = firebase.firestore().collection('chess').doc('lichess').collection('wagers');
export let lichessPayoutsDB = firebase.firestore().collection('chess').doc('lichess').collection('payouts');


/** Stats */

export async function getStats(doc, fields) {

    try{
        let getStats = firebase.functions().httpsCallable('getStats');
        let response = (await getStats({doc, fields})).data;
        return response;
    } catch(err) {
        console.log(err);
        return {};
    }

}


/** Legal */

export async function updateGeoLocation(locationData){

    try{
        let updateGeoLocation = firebase.functions().httpsCallable('updateGeoLocation');;
        let response = (await updateGeoLocation({locationData})).data;
    } catch(err) {
        console.log(err);
    }

}