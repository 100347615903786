

export const TOURNAEMNT_PAYOUT_WAITING_PERIOD = 48;






const VARIANTS = [
    {label:'Standard', value:'standard'}, 
    {label:'Chess960', value:'chess960'}, 
    {label:'Crazyhouse', value:'crazyhouse'}, 
    {label:'Antichess', value:'antichess'}, 
    {label:'Atomic', value:'atomic'}, 
    {label:'Horde', value:'horde'}, 
    {label:'Racing Kings', value:'racingKings'}, 
    {label:'King of the Hill', value:'kingOfTheHill'}, 
    {label:'Three-check', value:'threeCheck'}
]

const CLOCK = [
    {label:'1 minute', value:1}, 
    {label:'2 minutes', value:2}, 
    {label:'3 minutes', value:3}, 
    {label:'4 minutes', value:4}, 
    {label:'5 minutes', value:5}, 
    {label:'6 minutes', value:6}, 
    {label:'7 minutes', value:7}, 
    {label:'8 minutes', value:8}, 
    {label:'10 minutes', value:10}, 
    {label:'15 minutes', value:15}, 
    {label:'20 minutes', value:20}, 
    {label:'25 minutes', value:25}, 
    {label:'30 minutes', value:30}, 
    {label:'40 minutes', value:40}, 
    {label:'50 minutes', value:50}, 
    {label:'60 minutes', value:60}, 
]

const INCREMENT = [
    {label:'0 seconds', value:0}, 
    {label:'1 seconds', value:1}, 
    {label:'2 seconds', value:2}, 
    {label:'3 seconds', value:3}, 
    {label:'4 seconds', value:4}, 
    {label:'5 seconds', value:5}, 
    {label:'6 seconds', value:6}, 
    {label:'7 seconds', value:7}, 
    {label:'10 seconds', value:10}, 
    {label:'15 seconds', value:15}, 
    {label:'20 seconds', value:20}, 
    {label:'25 seconds', value:25}, 
    {label:'30 seconds', value:30}, 
    {label:'40 seconds', value:40}, 
    {label:'50 seconds', value:50}, 
    {label:'60 seconds', value:60}, 
]

const DURATION = [
    {label:'20 minutes', value:20}, 
    {label:'25 minutes', value:25}, 
    {label:'30 minutes', value:30}, 
    {label:'35 minutes', value:35}, 
    {label:'40 minutes', value:40}, 
    {label:'45 minutes', value:45}, 
    {label:'50 minutes', value:50}, 
    {label:'55 minutes', value:55}, 
    {label:'60 minutes', value:60}, 
    {label:'70 minutes', value:70}, 
    {label:'80 minutes', value:80}, 
    {label:'90 minutes', value:90}, 
    {label:'100 minutes', value:100}, 
    {label:'110 minutes', value:110}, 
    {label:'120 minutes', value:120}, 
    {label:'150 minutes', value:150}, 
    {label:'180 minutes', value:180}, 
    {label:'210 minutes', value:210}, 
    {label:'240 minutes', value:240}, 
    {label:'270 minutes', value:270}, 
    {label:'300 minutes', value:300}, 
    {label:'330 minutes', value:330}, 
    {label:'360 minutes', value:360}, 
    {label:'420 minutes', value:420}, 
    {label:'480 minutes', value:480}, 
    {label:'540 minutes', value:540}, 
    {label:'600 minutes', value:600}, 
    {label:'720 minutes', value:720}, 
]

const MIN_RATING = [
    {label:'None', value:'None'}, 
    {label:'1000', value:1000}, 
    {label:'1100', value:1100}, 
    {label: '1200', value:1200}, 
    {label:'1300', value:1300}, 
    {label:'1400', value:1400}, 
    {label:'1500', value:1500}, 
    {label:'1600', value:1600}, 
    {label:'1700', value:1700}, 
    {label:'1800', value:1800}, 
    {label:'1900', value:1900}, 
    {label:'2000', value:2000}, 
    {label:'2100', value:2100}, 
    {label:'2200', value:2200}, 
    {label:'2300', value:2300}, 
    {label:'2400', value:2400}, 
    {label:'2500', value:2500}, 
    {label:'2600', value:2600}
]

const MAX_RATING = [
    {label:'None', value:'None'}, 
    {label:'800', value:800}, 
    {label:'900', value: 900},
    {label:'1000', value:1000}, 
    {label:'1100', value:1100}, 
    {label:'1200', value:1200}, 
    {label:'1300', value:1300}, 
    {label:'1400', value:1400}, 
    {label:'1500', value:1500}, 
    {label:'1600', value:1600}, 
    {label:'1700', value:1700}, 
    {label:'1800', value:1800}, 
    {label:'1900', value:1900}, 
    {label:'2000', value:2000}, 
    {label:'2100', value:2100}, 
    {label:'2200', value:2200}, 
   
]

const DISTRIBUTIONS = [
    {label:'Amped', value:'amped'}, 
    {label:'Podium', value:'podium'}, 
    {label:'Top 3', value:'top-3'}, 
    {label:'Winner Takes All', value:'winner-takes-all'}, 
]

const PARTICIPANTS = [
    {label:'Allow Everyone', value:'everyone'}, 
    {label:'Team', value:'team'}, 
]


const MIN_PARTICIPANTS = [
    // {label:'5', value:5}, 
    // {label:'6', value: 6},
    // {label:'7', value:7}, 
    // {label:'8', value:8}, 
    // {label:'9', value:9}, 
    {label:'10', value:10}
]

const MAX_PARTICIPANTS = [
    {label:'None', value: 'None'}, 
    {label:'20', value: 20}, 
    {label:'50', value: 50}, 
    {label:'100', value: 100},
    {label:'250', value: 250}, 
    {label:'500', value: 500}, 
    {label:'1000', value: 1000}, 
]

const MIN_RATED_GAMES = [
    {label:'10', value: 10}, 
    {label:'15', value: 15}, 
    {label:'20', value: 20},
    {label:'30', value: 30}, 
    {label:'40', value: 40}, 
    {label:'50', value: 50}, 
    {label:'75', value: 75}, 
    {label:'100', value: 100}, 
    {label:'150', value: 150}, 
    {label:'200', value: 200}, 
]


const DEFAULT = {
    startDate: 0,
    name: '',
    description: '',
    variant: 'standard',
    clockTime: 3,
    clockIncrement: 0,
    duration: 60,
    minRating: 'None',
    maxRating: 'None',
    participants: 'everyone',
    teamId: '',
    tickets: 1,
    payoutFormula: 'amped',
    waiveLeagueFee: false,
    minParticipants: 10,
    maxParticipants: 'None',
    nbRatedGame: 15,
    private:false
}


export const options = {
    VARIANTS,
    CLOCK,
    INCREMENT,
    DURATION,
    MIN_RATING,
    MAX_RATING,
    DISTRIBUTIONS,
    PARTICIPANTS,
    MIN_PARTICIPANTS,
    MAX_PARTICIPANTS,
    DEFAULT,
    MIN_RATED_GAMES
}