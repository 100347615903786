
import React, {memo} from 'react';
import { Button, CircularProgress, Skeleton } from '@mui/material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { lichessPayoutsDB } from '../../../Firebase';
import PayoutDialogue from './PayoutDialogue';
import { Link } from 'react-router-dom';
import PayoutStatus from './PayoutStatus';
import { capitalizeFirstLetter, numberWithCommas } from '../../../utils';
import Emitter from '../../../Emitter';
import Tooltip from '@material-ui/core/Tooltip';

function PayoutCard({_payout}) {

    const pid = _payout['id'];
    
    const [payout, setPayout] = React.useState(_payout);

    function TitleText(){
    
        if(payout.type == 'tournament') return 'Arena Tournament';
        if(payout.type == 'wager') return `Wager Match`;
        if(payout.type == 'TOURNAMENT-HOST') return `Arena Tournament`;
        if(payout.type == 'tournament_async') return 'Flex Play Tournament';
    }

    function SubText(){
        if(payout.type == 'tournament') return 'Rank ' + payout.rank;
        // if(payout.type == 'wager') return  `${capitalizeFirstLetter(payout.username)} Wins`
        if(payout.type == 'wager') return  `${String(payout.username).toUpperCase()} Wins`

        if(payout.type == 'TOURNAMENT-HOST') return  `Tournament Host`

        if(payout.type == 'tournament_async') return 'Award Winner';

    }

    function DateText(){
        let date = new Date(payout.timestamp);
        return date.toLocaleDateString();
    }

    const [openRedeem, setOpenRedeem] = React.useState(false);

    const [timeRemaining, setTimeRemaining] = React.useState('');

    function startTimer() {

        setInterval(function () {

            let timeRemaining = (payout['activationTimestamp'] - Date.now());

            if(timeRemaining < 1000){
                setTimeRemaining(-1);
                return;
            }

            timeRemaining = Math.trunc(timeRemaining/1000);

            let hours = parseInt(timeRemaining / (60 * 60), 10);
            let minutes = parseInt((timeRemaining / 60) % 60, 10);
            let seconds = parseInt(timeRemaining % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            if (hours < 1){
                setTimeRemaining(minutes+':'+seconds);
            } else {
                hours = hours < 10 ? "0" + hours : hours;
                setTimeRemaining(hours+':'+minutes+':'+seconds);
            }    

        }, 1000);
    }

    const [status, setStatus]= React.useState(_payout['status']);

    React.useEffect(()=>{
        
        if (payout.status == 'WAITING') startTimer();

        if (status == 'REQUESTED' && payout.status == 'ACTIVE'){
            Emitter.emit('MISC-ERROR', 2);
        }

        setStatus(payout.status);

    },[payout])
    
    React.useEffect(()=>{

        const subscribe = lichessPayoutsDB.doc(pid).onSnapshot((snapshot) => {
            let p = snapshot.data();
            p['id'] = pid

            // console.log(payout.status, p.status);
            if (payout.status == 'REQUESTED' && p.status == 'ACTIVE'){
                Emitter.emit('MISC-ERROR', 2);
            }
            setPayout(p);
        });

        return ()=>{
            subscribe();
        }

    },[])

    
    const [openStatus, setOpenStatus] = React.useState(false);

    return (

        <>

            <div className='card' style={{ display:'flex', height:100, backgroundColor:'white',  justifyContent:'space-between', flexDirection:'column'}}>
                
                {payout.type == 'tournament' &&
                    <Link to={`/tournament/${payout['tournamentId']}`} style={{textDecoration:'none'}}>
                        <div  style={{display:'flex', flexDirection:'row',alignItems:'flex-start', justifyContent:'space-between'}}>
                            
                            <div style={{display:'flex', flexDirection:'column',   alignItems:'center', justifyContent:'center'}}>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center', textAlign:'left'}}>
                                    <p  style={{fontSize:16, marginRight:5}}>{TitleText()}</p>
                                    <LaunchOutlinedIcon style={{textDecoration:'none', width:16, height:16, marginTop:-3}}/>
                                </div>
                                <p style={{textAlign:'left', color:'gray', fontSize:12, width:'100%'}}>{SubText()}</p>
                            </div>
                            <p style={{fontSize:12}}>{ DateText()}</p>


                        </div>
                    </Link>
                }

                {payout.type == 'tournament_async' &&
                    <Link to={`/flex/${payout['tournamentId']}`} style={{textDecoration:'none'}}>
                        <div  style={{display:'flex', flexDirection:'row',alignItems:'flex-start', justifyContent:'space-between'}}>
                            
                            <div style={{display:'flex', flexDirection:'column',   alignItems:'center', justifyContent:'center'}}>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center', textAlign:'left'}}>
                                    <p  style={{fontSize:16, marginRight:5}}>{TitleText()}</p>
                                    <LaunchOutlinedIcon style={{textDecoration:'none', width:16, height:16, marginTop:-3}}/>
                                </div>
                                <p style={{textAlign:'left', color:'gray', fontSize:12, width:'100%'}}>{SubText()}</p>
                            </div>
                            <p style={{fontSize:12}}>{ DateText()}</p>


                        </div>
                    </Link>
                }

                {payout.type == 'TOURNAMENT-HOST' &&
                    <Link to={`/tournament/${payout['tournamentId']}`} style={{textDecoration:'none'}}>
                        <div  style={{display:'flex', flexDirection:'row',alignItems:'flex-start', justifyContent:'space-between'}}>
                            
                            <div style={{display:'flex', flexDirection:'column',   alignItems:'center', justifyContent:'center'}}>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center', textAlign:'left'}}>
                                    <p  style={{fontSize:16, marginRight:5}}>{TitleText()}</p>
                                    <LaunchOutlinedIcon style={{textDecoration:'none', width:16, height:16, marginTop:-3}}/>
                                </div>
                                <p style={{textAlign:'left', color:'gray', fontSize:12, width:'100%'}}>{SubText()}</p>
                            </div>
                            <p style={{fontSize:12}}>{ DateText()}</p>


                        </div>
                    </Link>
                }

                {payout.type == 'wager' &&
                    <Link to={`/wager/${payout['wagerId']}`} style={{textDecoration:'none'}}>
                        <div  style={{display:'flex', flexDirection:'row',alignItems:'flex-start', justifyContent:'space-between'}}>
                            
                            <div style={{display:'flex', flexDirection:'column',   alignItems:'center', justifyContent:'center'}}>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'left', alignItems:'center', textAlign:'left'}}>
                                    <p style={{ justifyContent:'left', fontSize:16, textAlign:'left', marginRight:5}}>{TitleText()}</p>
                                    {/* <p style={{fontWeight:'normal', fontSize:12, marginRight:5}}>{payout.wagerId.slice(0,8)}</p> */}
                                    <LaunchOutlinedIcon style={{textDecoration:'none', width:16, height:16, marginTop:-3}}/>
                                </div>
                                <p style={{textAlign:'left', color:'gray', fontSize:12, width:'100%'}}>{SubText()}</p>
                            </div>
                            <p style={{fontSize:12}}>{ DateText()}</p>


                        </div>
                    </Link>
                }

                <div style={{display:'flex', flexDirection:'row',alignItems:'flex-end', justifyContent:'space-between'}}>
                    
                    <p style={{fontSize:21, color:'#36AA3E'}}>{'$' +  numberWithCommas(payout.value)}</p>
                    
                    {(payout.status == 'WAITING') && 
                        <>
                           {(timeRemaining=='') ?
                                <Skeleton width={91} height={36} /> 
                                    :
                                <>
                                    {(timeRemaining==-1) ?
                                        <Button disabled={true} variant='contained' >
                                            <CircularProgress style={{marginRight:10}} size={20}/>
                                            <b>PENDING</b>
                                    `    </Button>
                                            :
                                        <Button disabled={true} variant='contained'>
                                            <b >{timeRemaining}</b>
                                        </Button>
                                    }
                                </>
                           }
                        </>
                    }

                    {(payout.status == 'ACTIVE') && 
                        <Button onClick={()=>setOpenRedeem(true)} color='primary' variant='outlined'>
                            <b>REDEEM</b>
                        </Button>
                    }

                    {/* {(payout.status == 'REQUESTED') && 
                    <Tooltip title="Your tooltip text here">
                            <Button disabled={false} color='primary' variant='contained'>
                                <b>REQUESTED</b>
                            </Button>
                            </Tooltip>
                    } */}

                    {(!['WAITING', 'ACTIVE'].includes(payout.status)) && 
                     // {(!['WAITING', 'ACTIVE', 'REQUESTED'].includes(payout.status)) && 


                        <>
                            <Button onClick={()=>setOpenStatus(true)} color='primary' variant='outlined'>
                                <b>STATUS</b>
                            </Button>
                        </>
                }
                
                </div>

            </div>


            <PayoutDialogue payout={payout} open={openRedeem} setOpen={setOpenRedeem}/>
            <PayoutStatus payout={payout} open={openStatus} setOpen={setOpenStatus}/>

            

        </>

    );


}

export default memo(PayoutCard);