// import React from 'react';

// const ChessClock = ({ whiteTime, blackTime }) => {
//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//   };

//   return (
//     <div className="chess-clock" style={{borderRadius:5, padding:13, backgroundColor:'#1f2022', display: 'flex', flexDirection: 'row' , justifyContent:'space-between'}}>
//       <div style={{marginRight:15, display:"flex", flexDirection:"row", alignItems:'center'}}>
//         <p style={{fontSize:18, padding:0, margin:0, color:'white', marginRight:10}}>White</p>
//         <p className="pulsing" style={{fontSize:18, padding:0, margin:0, }}>{formatTime(whiteTime)}</p> {/* Apply pulsing class here */}
//       </div>
//       <div style={{ display:"flex", flexDirection:"row", alignItems:'center'}}>
//         <p style={{fontSize:18, padding:0, margin:0, color:'white', marginRight:10}}>Black</p>
//         <p className="pulsing" style={{fontSize:18, padding:0, margin:0, }}>{formatTime(blackTime)}</p> {/* Apply pulsing class here */}
//       </div>
//     </div>
//   );
// };

// export default ChessClock;


import React from 'react';
import PlayerBadge from '../LiveWagering/PlayerBadge';

const ChessClock = ({ isWhite, time, title, username, rating }) => {
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (


    <>
   {isWhite &&<div className="chess-clock" style={{borderRadius:5, borderTopRightRadius:0, borderTopLeftRadius:0, padding:13, backgroundColor:'#1f2022', display: 'flex', flexDirection: 'row' , alignItems:'center', justifyContent:'space-between'}}>
      <div style={{ display:"flex", flexDirection:"row", alignItems:'center'}}>
        {/* <p style={{fontSize:15, padding:0, margin:0, color:'white', marginRight:10}}>White</p> */}
        <p className="pulsing" style={{fontSize:18, padding:0, margin:0, }}>{formatTime(time)}</p>
      </div>
      
        <PlayerBadge title={title} username={username} rating={rating} />
    </div>
    }

    {!isWhite &&<div className="chess-clock" style={{borderRadius:5, borderBottomLeftRadius:0, borderBottomRightRadius:0, padding:13, backgroundColor:'#1f2022', display: 'flex', flexDirection: 'row' , alignItems:'center', justifyContent:'space-between', borderTopRightRadius:5,}}>

    <PlayerBadge title={title} username={username} rating={rating} />
      <div style={{display:"flex", flexDirection:"row", alignItems:'center'}}>
        {/* <p style={{fontSize:15, padding:0, margin:0, color:'white', marginRight:10}}>Black</p> */}
        <p className="pulsing" style={{fontSize:18, padding:0, margin:0, }}>{formatTime(time)}</p>
      </div>
      
      
    </div>
    }

    </>
  );
};

export default ChessClock;
