import React, {memo, useState} from 'react';
import { Link } from 'react-router-dom';
import { getPerf, getUserPublic } from '../../../Lichess/api';
import { capitalizeFirstLetter, numberWithCommas } from '../../../utils';
import { useLichessTournamentCardStyles } from '../../MuiStyles';
import clockIcon from '../../../art/chess-clock.svg';
import TournamentIcon from '../Icons/TournamentIcon';
import { Button, Divider } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import useWindowSize from '../../../hooks/WindowDimensions';
import { pieces } from './utils';



function WagerRow({wager}) {


  const classes = useLichessTournamentCardStyles();

  const {width} = useWindowSize();


  function Risk(){
     return '$' + numberWithCommas(wager.tickets);
  }

  function Earn(){
    return '$' + numberWithCommas(2 * wager.tickets);
 }


  function ClockIcon({size}){


    return(

      <img src={clockIcon} width={size} height={size}/>

    );

  }

  function ClockText(){
    if(wager) return  `${wager['clockTime']}+${wager['clockIncrement']}`
  }

  const [timeRemaining, setTimeRemaining] = React.useState('');
  const [rating, setRating] = useState(null);
  const [title, setTitle] = useState('')
  const [perf, setPerf] = useState(null);

  function startTimer() {

      setInterval(function () {

          let timeRemaining = (wager['expireAt'] - Date.now());

          if(timeRemaining < 1000){
              setTimeRemaining('00:00');
              return;
          }

          timeRemaining = Math.trunc(timeRemaining/1000);

          let hours = parseInt(timeRemaining / (60 * 60), 10);
          let minutes = parseInt((timeRemaining / 60) % 60, 10);
          let seconds = parseInt(timeRemaining % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          if (hours < 1){
              setTimeRemaining(minutes+':'+seconds);
          } else {
              hours = hours < 10 ? "0" + hours : hours;
              setTimeRemaining(hours+':'+minutes+':'+seconds);
          }    

      }, 1000);
  }

  async function init(){

    let p = getPerf(wager.variant, wager.clockTime, wager.clockIncrement);

    setPerf(p);

    if (rating == null){
      let user = await getUserPublic(wager.username);
      if (user) {
        setRating(user['perfs'][p].rating);
        if(user['title'] != null && user['title'] != '') setTitle(user['title']);
      }
    }
  }


  React.useEffect(()=>{
        
    startTimer();
    init();

  },[wager])


 
 

  return (

      <div className='card' style={{display:'flex', marginBottom:20, backgroundColor:'white',  flexDirection:'column'}}>

          <Link to={`/wager/${wager['id']}`} style={{textDecoration:'none'}}>
            <div className={classes.cardHeaderContainer}>
                      
              <div className={classes.cardHeaderContainerLeft}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <TournamentIcon size={38} perf={perf} variant={wager.variant} />
                </div>
                <div className={classes.cardHeaderLeftTextContainer}>

                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                {(title != '') && <p className='descrip5' style={{fontSize:17, marginRight:5}}>{title}</p>}
                  <p style={{fontSize:18}}>{wager.username}</p>
                </div>

                  <div style={{height:14.5, alignItems:'flex-start'}}>
                    {!rating ?
                      <Skeleton width={45} height={14}/>
                        :
                      <p className={classes.subText}>{rating}</p>
                    }
                  </div>

                </div>
              </div>

              <div className={classes.cardHeaderContainerRight}>

                  {/* <div style={{display:'flex', alignItems:'center',  justifyContent:'right', flexDirection:'row'}}>
                    <p style={{marginRight:7, marginTop:4, fontWeight:'bold', color:'gray', fontSize:15}}>{ClockText()}</p>
                    <ClockIcon size={27}/>
                  </div>  */}

                   <div style={{display:'flex', textAlign:'right', alignItems:'flex-end',  justifyContent:'right', flexDirection:'column'}}>
                    <p style={{padding:0, margin:0, fontWeight:'bold', color:'gray', fontSize:18}}>{ClockText()}</p> 

                    {/* {(wager.whiteUsername != null)?<p style={{padding:0, margin:0, marginTop:5, fontSize:12}}>{pieces(wager)[wager.username]} Pieces</p>:
                    <p style={{padding:0, margin:0, marginTop:5, fontSize:12}}>{capitalizeFirstLetter(wager.color)} Pieces</p>} */}
                    {/* <ClockIcon size={27}/> */}
                    <p style={{padding:0, margin:0, marginTop:5, fontSize:12}}>{capitalizeFirstLetter(wager.color)} Pieces</p>

                  </div> 
                  
              </div>

            </div>

            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'flex-end', marginTop:10}}>

              <div style={{marginLeft:(width>420)?10:0, textAlign:'center', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                  <div style={{width:'50%', display:'flex',  alignItems:'center', justifyContent:'left', flexDirection:'column'}}>
                      <p style={{color:'#ff1744', textAlign:'center', fontSize:(width>450)?18:17, fontWeight:800, margin:0, padding:0, marginBottom:5}}>{Risk()}</p>
                      <p style={{fontWeight:500, color:'#6c6d6f', textAlign:'center', margin:0, padding:0}}>Risk</p>
                  </div>
                  <Divider orientation="vertical" flexItem style={{marginLeft:(width>450)?27:15, marginRight:(width>450)?27:15}} />
                  <div style={{width:'50%', display:'flex', alignItems:'center', justifyContent:'right', flexDirection:'column'}}>
                      <p style={{color:'#36AA3E', textAlign:'center', fontSize:(width>450)?18:17, fontWeight:800, margin:0, padding:0, marginBottom:5}}>{Earn()}</p>
                      <p style={{fontWeight:500, color:'#6c6d6f', textAlign:'center', margin:0, padding:0 }}>Win</p>
                  </div>

              </div>

              <>
                  {(wager.status == 'ACTIVE')?

                      <>
                       {timeRemaining!=''?
                          <Button disabled={true} style={{height:37}} variant='contained'>
                            EXPIRES{'\u00A0'}{'\u00A0'}<b>{timeRemaining}</b>
                          </Button>
                            :
                          <Skeleton height={40} width={100}/>
                        }
                      </>
                          :
                      // <p style={{fontSize:18, fontWeight:'bold', padding:0, margin:0, color:(wager.status == 'REFUND' || wager.status == 'NO-SHOW')?'#ff1744':'gray'}}>{wager.status}</p>
                      <p style={{fontSize:18, fontWeight:'bold', padding:0, margin:0, color:'gray'}}>{wager.status}</p>

                  }
              </>

             

            </div>

          </Link>
          
      </div>

  );


}

export default memo(WagerRow);
