import { useEffect, useState } from 'react';
import { useLichessTournamentCardStyles } from '../../MuiStyles';
import { getPerf } from '../../../Lichess/api';
import ColorsIcon from '../Icons/ColorsIcon';
import { getUserPublic } from '../../../Lichess/api';
import {memo} from 'react';
import { Skeleton } from '@mui/material';

function ScoreTable({p, whiteUsername, blackUsername, whiteScore, blackScore, whiteProb, blackProb, drawProb}){

    const classes = useLichessTournamentCardStyles();

    const [titleW, setTitleW] = useState(null);
    const [ratingW, setRatingW] = useState(null);

    const [titleB, setTitleB] = useState(null);
    const [ratingB, setRatingB] = useState(null);

    async function initOpponent(){

        if (p == null || whiteUsername == null || blackUsername == null) return;

        let w = await getUserPublic(whiteUsername);

        let b = await getUserPublic(blackUsername);
        
        if (w) {
            setRatingW(w['perfs'][p].rating);
            if(w['title'] != null && w['title'] != '') setTitleW(w['title']);
        }

        if (b) {
            setRatingB(b['perfs'][p].rating);
            if(b['title'] != null && b['title'] != '') setTitleB(b['title']);
        }

    }

    useEffect(()=>{

        initOpponent();

    },[whiteUsername, blackUsername])

    return(

        <div className='card' style={{maxWidth:690, width:'100%', marginBottom:20}}>
                
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>

                <div className={classes.cardHeaderLeftTextContainer} style={{marginLeft:0}}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <ColorsIcon color={'white'} size={17}/>
                        <p style={{ fontSize:16, padding:0, margin:0, marginLeft:10}}>{whiteUsername}</p>
                    </div>
                   
                </div>

                <p style={{padding:0, margin:0, fontSize:16}}>{whiteScore}</p>

            </div>

            <div style={{marginTop:15, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              
                <div className={classes.cardHeaderLeftTextContainer} style={{marginLeft:0}}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <ColorsIcon color={'black'} size={17} />
                        <p style={{fontSize:16, padding:0, margin:0, marginLeft:10}}>{blackUsername}</p>
                    </div>
                   
                </div>

                <p style={{padding:0, margin:0, fontSize:16}}>{blackScore}</p>


            </div>


        </div>

    );


}

export default memo(ScoreTable);