import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function RedirectToExternal({link}) {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = link;
        // Or you can use navigate to go back if the external link is not loaded
        // navigate(-1); // This will take the user back to the previous route
    }, [navigate]);

    return null; // Render nothing or some loading indicator
}

