
import React from 'react';
import {  Dialog, Skeleton } from '@mui/material';
import {  getLichessPayoutStatus } from '../../../Firebase';
import {Divider} from '@mui/material';
import { numberWithCommas } from '../../../utils';


export default function PayoutStatus({payout, open, setOpen}) {

  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState({status:'PENDING', message:''});
  const [noticeMessage, setNoticeMessage] = React.useState('');

  function handleClose(){
    setOpen(false);
  }

  function RecievedValue(){

    if (payout.waiveLeagueFee == true){
      return '$' + numberWithCommas(Number(((1-2/100)*payout['value']).toFixed(2)));
    } 

    return '$' + numberWithCommas(((1-payout['feePercent']/100)*payout['value']).toFixed(2));
  }

  function PlatformFeeValue(){

    if (payout.waiveLeagueFee == true){
      return '$' + numberWithCommas(Number((2/100)*payout['value']).toFixed(2));
    } 

    return '$' + numberWithCommas(((payout['feePercent']/100)*payout['value']).toFixed(2));
  }

  async function getStatus(){

    if (payout.status == 'COMPLETE'){
      setStatus({status:'COMPLETE', message:''});
    } else {
      setLoading(true);
      setStatus(await getLichessPayoutStatus(payout['id']));
      setLoading(false);
    }
   
  }



  React.useEffect(()=>{
    getStatus();
  },[payout])


  React.useEffect(()=>{
    if (payout.notice != null) setNoticeMessage(payout.notice);
  },[payout])



  return (

    <Dialog fullWidth  open={open} onClose={handleClose} 
        
      PaperProps={{
          style:{
          borderTopRightRadius:7,
          borderTopLeftRadius:7,
          borderBottomLeftRadius:7,
          borderBottomRightRadius:7,
          }
      }} >

      <div   style={{padding:15}}>
    
        <div className='logo-wrap'>
          <div className='logo-wrap-content' style={{marginBottom:10}}>

            <div style={{display:'flex',   height:50,  flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                <p style={{fontSize:14, fontWeight:'bold'}}>Payout</p>
            </div>

            <Divider/>

            <div style={{height:110, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start'}}>
    
              <div style={{height:30,  width:'100%', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-between'}}>
                  <p style={{fontSize:14, fontWeight:'bold'}}>Value</p>
                  <p style={{fontSize:14, fontWeight:'bold'}}>{'$' + numberWithCommas(payout['value'])}</p>
              </div>

              <div style={{height:30,  width:'100%', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-between'}}>
                  <p style={{fontSize:14, fontWeight:'bold'}}>{(payout.waiveLeagueFee)?'Processor Fee':`Fee`}</p>
                  {/* <p style={{fontSize:14, fontWeight:'bold'}}>{(payout.waiveLeagueFee)?'2%':payout['feePercent'] + '%'}</p> */}
                  <p style={{fontSize:14, fontWeight:'bold'}}>{PlatformFeeValue()}</p>

              </div>

              <div style={{height:50,   width:'100%', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-between'}}>
                <p style={{fontSize:14, fontWeight:'bold'}}>Recieved</p>
                <p style={{fontSize:14, fontWeight:'bold'}}>{RecievedValue()}</p>
              </div>

            </div>

            <Divider/>
        
          </div>

        </div>

        <div style = {{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
          <p style={{fontSize:14, fontWeight:'bold'}}>Status</p>
          {!loading?
            <p style={{fontSize:14, fontWeight:'bold'}}>{String(status.status)}</p>
            :
            <Skeleton width={90} height={30} />
          }
        </div>

        {(status.message.length > 0 || noticeMessage.length > 0) &&
          <div style = {{display:'flex',  flexDirection:'column', marginBottom:10, alignItems:'center', justifyContent:'center'}}>
            {status.message.length > 0 && <p style={{padding:0, margin:0, marginTop:15, fontSize:14, textAlign:'center'}}>{String(status.message)}</p>}
             {noticeMessage.length > 0 && <p style={{padding:0, margin:0, marginTop:15, fontSize:14, textAlign:'center'}}>{String(noticeMessage)}</p>}
          </div>
        }


      </div>


    </Dialog>

  );


}

