import React, {memo} from 'react';
import ErrorCard from './ErrorCard';
import { getLichessTournamentTickets} from '../../Firebase';
import { useAccountStyles } from '../MuiStyles';
import useUser from '../../hooks/useUser';
import WarningBar from '../WarnningBar';
import Switch from '@mui/material/Switch';
import { usersDB } from '../../Firebase';
import { toggle_email_setting } from '../../Firebase';
import { numberWithCommas } from '../../utils';



function Account() {

    const classes = useAccountStyles();

    const [user, auth, userLoading] = useUser();

    const [loadingTickets, setLoadingTickets] = React.useState(false);
    const [tickets, setTickets] = React.useState({redeemed:0,unredeemd:0});

    async function initTickets() {
        setLoadingTickets(true);
        setTickets(await getLichessTournamentTickets());
        setLoadingTickets(false);
    }

    React.useEffect(()=>{initTickets()},[])


    const [loadingEmailSettings, setLoadingEmailSettings] = React.useState(false);

    const [toggling, setToggling] = React.useState(false);

    const [settingOne, setSettingOne] = React.useState(false);
    const [settingTwo, setSettingTwo] = React.useState(false);
    const [settingThree, setSettingThree] = React.useState(false);

    const handleToggleOne = async () => {
        if (toggling) return;
        setToggling(true);
        await toggle_email_setting('chess_upcoming_tournaments', !settingOne);
        setToggling(false);
    };

    const handleToggleTwo = async () => {
        if (toggling) return;
        setToggling(true);
        await toggle_email_setting('chess_open_wagers', !settingTwo);
        setToggling(false);
    };

    const handleToggleThree = async () => {
        if (toggling) return;
        setToggling(true);
        await toggle_email_setting('deposit_promos', !settingThree);
        setToggling(false);
    };

    React.useEffect(()=>{

        if (user){

            setLoadingEmailSettings(true);

            const subscribe = usersDB.doc(user.uid).onSnapshot((snapshot) => {
                
                setLoadingEmailSettings(true);
                let u = snapshot.data();

                setSettingOne(u['chess_upcoming_tournaments']);
                setSettingTwo(u['chess_open_wagers']);
                setSettingThree(u['deposit_promos']);

                setLoadingEmailSettings(false)
            
            });


            return ()=>{
                subscribe();
            }

            

        }
    
    },[user])


    React.useEffect(()=>{
        window.scrollTo(0, 0);
    },[])


    return (
        <>

            {(user && !loadingTickets && !loadingEmailSettings) && <div style={{padding:20, justifyContent:'center', display:'flex', flexDirection:'row'}}>
                <div style={{width:'100%', maxWidth:800}}>

                    {/* TICKETS */}
                    <div style={{marginBottom:20}}>
                
                        <p className={classes.sectionHeaderText}>Balance</p>

                        <>
                            {(tickets == -1)?<ErrorCard height={44}/>:

                                <div className='card' style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around'}}>
                                                    
                                    <div className={classes.sectionItem}>
                                        <p className={classes.valueText}>{'$' +numberWithCommas(tickets['unredeemed'])}</p>
                                        <p className={classes.descriptionText}>Available</p>
                                    </div>

                                    <div className={classes.sectionItem}>
                                        <p className={classes.valueText}>{'$' + numberWithCommas(tickets['redeemed'])}</p>
                                        <p className={classes.descriptionText}>Spent</p>
                                    </div>

                                </div>  

                            }

                        </>

                    </div>    
                    
                    
                    {/* END TICKETS */}
                
                    
                    {/* Email Settings */}
                    <p className={classes.sectionHeaderText}>Email Notifications</p>
                    <div className='card' >
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'left'}}>
                            <div style={{display:'flex', flexDirection:'row',  alignItems:'center'}}>
                            <Switch checked={settingOne} onChange={handleToggleOne} />
                            <p style={{fontSize:12, margin:0, padding:0}}>Upcoming Tournaments</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row',  alignItems:'center'}}>
                            <Switch checked={settingTwo} onChange={handleToggleTwo} />
                            <p style={{fontSize:12, margin:0, padding:0}}>Open Wagers</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row',  alignItems:'center'}}>
                            <Switch checked={settingThree} onChange={handleToggleThree} />
                            <p style={{fontSize:12, margin:0, padding:0}}>Deposit Promotions</p>
                            </div>
                        </div>
                    </div>
                    



                    <div style={{width:'100%', marginTop:40, display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        {user && <p style={{textAlign:'center', fontSize:10, padding:0, margin:0}}>{user.uid}<br/><br/><b>Account id</b></p>}
                    </div>

                    
                    
                </div>
            </div>}

            {!user && !userLoading && <div style={{padding:20, justifyContent:'center', display:'flex', flexDirection:'row'}}>
                <div style={{width:'100%', maxWidth:800}}>
                   <WarningBar pText={'Login to view account'}/>
                </div>
            </div>}

            {(userLoading || loadingTickets || loadingEmailSettings) &&
                <div style={{padding:20, margin:0, display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <div className='c-skeleton' style={{borderRadius:5, height:300, maxWidth:800}}>   
                    </div>
                </div>
            }

        </>

       

    );

}


export default memo(Account);