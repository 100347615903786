import { LazyLoadImage } from "react-lazy-load-image-component";
import {memo} from 'react';

import duel from '../../../art/duel.webp';


function DuelIconn({size}){

    // return <LazyLoadImage src={duel} width={size} height={size} />
    return <img src={duel} width={size} height={size} />


}

export default memo(DuelIconn);