
// const CLOCK = [30, 10800]

// const INCREMENT = [0, 60]

export const WAGER_PAYOUT_WAITING_PERIOD = 24;

const VARIANTS = [
    {label:'Standard', value:'standard'}, 
    {label:'Chess960', value:'chess960'}, 
    {label:'Crazyhouse', value:'crazyhouse'}, 
    {label:'Antichess', value:'antichess'}, 
    {label:'Atomic', value:'atomic'}, 
    {label:'Horde', value:'horde'}, 
    {label:'Racing Kings', value:'racingKings'}, 
    {label:'King of the Hill', value:'kingOfTheHill'}, 
    {label:'Three-check', value:'threeCheck'}
]

const CLOCK = [
    {label:'1 minute', value:1}, 
    {label:'2 minutes', value:2}, 
    {label:'3 minutes', value:3}, 
    {label:'4 minutes', value:4}, 
    {label:'5 minutes', value:5}, 
    {label:'6 minutes', value:6}, 
    {label:'7 minutes', value:7}, 
    {label:'8 minutes', value:8}, 
    {label:'10 minutes', value:10}, 
    {label:'15 minutes', value:15}, 
    {label:'20 minutes', value:20}, 
    {label:'25 minutes', value:25}, 
    {label:'30 minutes', value:30}, 
    {label:'40 minutes', value:40}, 
    {label:'50 minutes', value:50}, 
    {label:'60 minutes', value:60}, 
]

const INCREMENT = [
    {label:'0 seconds', value:0}, 
    {label:'1 seconds', value:1}, 
    {label:'2 seconds', value:2}, 
    {label:'3 seconds', value:3}, 
    {label:'4 seconds', value:4}, 
    {label:'5 seconds', value:5}, 
    {label:'6 seconds', value:6}, 
    {label:'7 seconds', value:7}, 
    {label:'10 seconds', value:10}, 
    {label:'15 seconds', value:15}, 
    {label:'20 seconds', value:20}, 
    {label:'25 seconds', value:25}, 
    {label:'30 seconds', value:30}, 
    {label:'40 seconds', value:40}, 
    {label:'50 seconds', value:50}, 
    {label:'60 seconds', value:60}, 
]
  
const MIN_RATING = [
    {label:'None', value:'None'}, 
    {label:'1000', value:1000}, 
    {label:'1100', value:1100}, 
    {label:'1200', value:1200}, 
    {label:'1300', value:1300}, 
    {label:'1400', value:1400}, 
    {label:'1500', value:1500}, 
    {label:'1600', value:1600}, 
    {label:'1700', value:1700}, 
    {label:'1800', value:1800}, 
    {label:'1900', value:1900}, 
    {label:'2000', value:2000}, 
    {label:'2100', value:2100}, 
    {label:'2200', value:2200}, 
    {label:'2300', value:2300}, 
    {label:'2400', value:2400}, 
    {label:'2500', value:2500}, 
    {label:'2600', value:2600},
    {label:'2700', value:2700}, 
    {label:'2800', value:2800}, 
    {label:'2900', value:2900}, 
]

const MAX_RATING = [
    {label:'None', value:'None'}, 
    {label:'800', value:800}, 
    {label:'900', value: 900},
    {label:'1000', value:1000}, 
    {label:'1100', value:1100}, 
    {label:'1200', value:1200}, 
    {label:'1300', value:1300}, 
    {label:'1400', value:1400}, 
    {label:'1500', value:1500}, 
    {label:'1600', value:1600}, 
    {label:'1700', value:1700}, 
    {label:'1800', value:1800}, 
    {label:'1900', value:1900}, 
    {label:'2000', value:2000}, 
    {label:'2100', value:2100}, 
    {label:'2200', value:2200}, 
    {label:'2300', value:2300}, 
    {label:'2400', value:2400}, 
    {label:'2500', value:2500}, 
    {label:'2600', value:2600}, 
    {label:'2700', value:2700}, 
    {label:'2800', value:2800}, 
    {label:'2900', value:2900}, 
]

const COLOR = [
    {label:'Random', value:'random'}, 
    {label:'White', value:'white'}, 
    {label:'Black', value:'black'}
]


const EXPIRE = [
    {label:'15 minutes', value:.25}, 
    {label:'30 minutes', value:.5}, 
    {label:'45 minutes', value:.75}, 
    {label:'1 hour', value:1}, 
    {label:'1 hour 15 minutes', value:1.25}, 
    {label:'1 hour 30 minutes', value:1.5}, 
    {label:'1 hour 45 minutes', value:1.75}, 
    {label:'2 hours', value:2}, 
    {label:'2 hours 15 minutes', value:2.25}, 
    {label:'2 hours 30 minutes', value:2.5}, 
    {label:'2 hours 45 minutes', value:2.75}, 
    {label:'3 hours', value:3}
];


const DEFAULT = {
    variant: 'standard',
    clockTime: 3,
    clockIncrement: 0,
    minRating: 'None',
    maxRating: 'None',
    color:'random',
    tickets: 1,
    expire:.5,
    private:false
}


export const options = {
    VARIANTS,
    CLOCK,
    INCREMENT,
    EXPIRE,
    MIN_RATING,
    MAX_RATING,
    COLOR,
    DEFAULT
}


