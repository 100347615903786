import React, { memo, useEffect, useState } from 'react';
import TV from './TV';

function LiveWagerLobby() {

    // useEffect(() => {
    //     const fetchGameData = async () => {
    //         try {
    //             const response = await fetch(`https://lichess.org/game/export/${challengeId}?pgnInJson=true`, {
    //                 headers: { 'Accept': 'application/json' }
    //             });
    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! status: ${response.status}`);
    //             }
    //             const data = await response.json();

    //             if (data.pgn) {
    //                 chess.loadPgn(data.pgn);
    //                 setGameFen(chess.fen()); // Set the board position to the last move of the PGN
    //             }
    //         } catch (error) {
    //             console.error('Error fetching game data:', error);
    //         }
    //     };

    //     fetchGameData();
    //     const interval = setInterval(fetchGameData, 5000); // Refresh every 5 seconds

    //     return () => clearInterval(interval);
    // }, [challengeId]);

 

    return (
        <>
        <TV />
        </>

    );
    
}

export default memo(LiveWagerLobby);
