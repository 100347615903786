import { Divider, IconButton, MenuItem, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { openInNewTab } from '../../../utils';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
// import AddIcon from '@material-ui/icons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, InputAdornment, Switch } from '@mui/material';
import { options } from '../../../Lichess/tournamentForm';
import { useRecoilState, useRecoilValue } from 'recoil';
import ClearIcon from '@mui/icons-material/Clear';
import { atom_createLichessTournamentForm, atom_createLichessTournamentFormConfirm, atom_lichessOauthToken, atom_lichessTournamentBonusFundsCode, atom_payoutFees } from '../../../Atoms';
import LichessButton from '../LichessButton';
import { getAmpedChessPayoutFees, userCreateLichessTournament, lichessIsBonusFundCodeValid } from '../../../Firebase';
import { useNavigate } from "react-router-dom";
import RemoveIcon from '@mui/icons-material/Remove';
import InfoBar from '../../InfoBar';


let payoutMatrixUrl = 'https://ampedchess.com/payouts/chart';





function CreateTournament() {

    const navigate = useNavigate();

    const lichessOauthToken = useRecoilValue(atom_lichessOauthToken);
    const [tournamentForm, setTournamentForm] = useRecoilState(atom_createLichessTournamentForm);
    const [bonusFundsCode, setBonusFundsCode] = useRecoilState(atom_lichessTournamentBonusFundsCode)
    const [confirm, setConfirm] = useRecoilState(atom_createLichessTournamentFormConfirm);

    const [creating, setCreating] = useState(false);

    function resetForm(){
        let tf = {...options.DEFAULT}
        tf['startDate'] = Date.now()+3600*1000;
        setTournamentForm(tf);
        setBonusFundsCode('');
    }
        
    function handleClickCancel(){
        resetForm();
        setConfirm(false);
        window.scrollTo(0, 0);
    }

    async function handleClickCreate(){

        if (creating) return;

        setCreating(true);
        let created = await userCreateLichessTournament(lichessOauthToken, tournamentForm, bonusFundsCode);
        setCreating(false);

        if(created) {
            handleClickCancel();
            navigate('/myTournaments');
            
        } else {
            setConfirm(false);
            window.scrollTo(0, 0);
        }
        setCreating(false);

    }
 

    // Handle form changes

    const handleChangeDateTime = (newValue) => {
        var tf = {...tournamentForm}
        tf['startDate'] = newValue.valueOf();
        setTournamentForm(tf);
    }

    const handleChangeName = (event) => {
        var tf = {...tournamentForm}
        tf['name'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeDescription = (event) => {
        var tf = {...tournamentForm}
        tf['description'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeVariant = (event) => {
        var tf = {...tournamentForm}
        tf['variant'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeClock = (event) => {
        var tf = {...tournamentForm}
        tf['clockTime'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeIncrement = (event) => {
        var tf = {...tournamentForm}
        tf['clockIncrement'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeDuration = (event) => {
        var tf = {...tournamentForm}
        tf['duration'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeMinRating = (event) => {
        var tf = {...tournamentForm}
        tf['minRating'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeMaxRating = (event) => {
        var tf = {...tournamentForm}
        tf['maxRating'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeEntryTickets = (val) => {

        if (val >= 0){ 
            var tf = {...tournamentForm}
            tf['tickets'] = val
            setTournamentForm(tf);
        }


    }

    const handleChangePayoutFormula = (event) => {
        var tf = {...tournamentForm}
        tf['payoutFormula'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangewaiveLeagueFee = (event) => {
        var tf = {...tournamentForm}
        tf['waiveLeagueFee'] = event.target.checked;
        setTournamentForm(tf);
    }

    const handleChangeParticipants = (event) => {
        var tf = {...tournamentForm}
        tf['participants'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeTeamId = (event) => {
        var tf = {...tournamentForm}
        tf['teamId'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangePrivate = (event) => {
        var tf = {...tournamentForm}
        tf['private'] = event.target.checked;
        setTournamentForm(tf);
    }


   

    function ChangeTickets(){

        return(
            <div className='flexRowCentered' >
               <IconButton disabled={confirm} onClick={()=>handleChangeEntryTickets(tournamentForm.tickets -1)} style={{width:25, height:25, marginRight:5}}>
                    <RemoveIcon />
                </IconButton>
                
                <IconButton disabled={confirm} onClick={()=>handleChangeEntryTickets(tournamentForm.tickets + 1)} style={{width:25, height:25}}>
                    <AddIcon />
                </IconButton>
            </div> 

        )

    }


    const handleChangeMinimumParticipants = (event) => {
        var tf = {...tournamentForm}
        tf['minParticipants'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangMaximumParticipants = (event) => {
        var tf = {...tournamentForm}
        tf['maxParticipants'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeMinimumRatedGames = (event) => {
        var tf = {...tournamentForm}
        tf['nbRatedGame'] = event.target.value;
        setTournamentForm(tf);
    }



    // init form with time
    React.useEffect(()=>{

        if (tournamentForm == null) {
            resetForm();
        } else if (tournamentForm.startDate == 0) {
            resetForm();
        } else if (tournamentForm.startDate < Date.now()) {
            resetForm();
        }

    },[])




    const payoutFees = useRecoilValue(atom_payoutFees);


    function InfoText(){
        return`Waiving the ${payoutFees.tournamentFee}% tournament prize league fee costs $10.`;
    }




    function FeeExplanation(){

        if (tournamentForm.tickets > 0){


            return `There is a a ${payoutFees.tournamentFee}% fee on payouts. Fee proceeds are split 6:4 between the host and Amped Chess.`


        } else {

            return `This is a free tournament with no prize pool.`


        }
    
    }



    const handleChangeBonusCode = (event) => {
        let code = event.target.value;
        setBonusFundsCode(code);
    }
   
    
    async function clickAddBonusCode(){

        if (bonusFundsCode != '' && bonusFundsCode != null) {
            let valid = await lichessIsBonusFundCodeValid(String(bonusFundsCode));
            if (!valid) setBonusFundsCode('');
        }
        
    }


    return (


        <div style={{padding:20, marginBottom:60, display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <div style={{ display:'flex', maxWidth:850, flexDirection:'column',}}>

                
            
                <p style={{fontSize:22, fontWeight:'bold'}}>Create Prize Pool Arena Tournament</p>



                <Divider style={{marginBottom:20}} />


                <LocalizationProvider  style={{m:20}} dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            disabled={confirm}
                            label='Date & Time'
                            value={dayjs(tournamentForm.startDate)}
                            onChange={handleChangeDateTime}
                            renderInput={(params) => <TextField variant='outlined'{...params} />}
                        />
                    </LocalizationProvider>

                <TextField disabled={confirm} value={tournamentForm.name} onChange={handleChangeName} style={{marginTop:20}} label='Name' variant='outlined' />

                <TextField  disabled={confirm} value={tournamentForm.description} onChange={handleChangeDescription} style={{marginTop:20, marginBottom:20,}} minRows={4} maxRows={4} multiline label='Description' variant='outlined' />


                <p style={{fontWeight:'normal', fontSize:18}}>Details</p>
                <Divider />

                <div  >

                    <TextField  disabled={confirm} select value={tournamentForm.variant} onChange={handleChangeVariant} style={{width:152, marginTop:20, marginRight:10}} label='Variant' variant='outlined' >
                        {options.VARIANTS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={tournamentForm.clockTime} onChange={handleChangeClock} style={{width:152, marginTop:20, marginRight:10}} label='Clock' variant='outlined' >
                        {options.CLOCK.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={tournamentForm.clockIncrement} onChange={handleChangeIncrement} style={{width:152, marginTop:20, marginRight:10}} label='Increment' variant='outlined' >
                        {options.INCREMENT.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>


                    <TextField  disabled={confirm} select value={tournamentForm.duration} onChange={handleChangeDuration} style={{width:152, marginTop:20, marginRight:10}} label='Duration' variant='outlined' >
                        {options.DURATION.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField   disabled={confirm} select value={tournamentForm.minRating} onChange={handleChangeMinRating} style={{width:152, marginTop:20, marginRight:10}} label='Min Rating' variant='outlined' >
                        {options.MIN_RATING.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={tournamentForm.maxRating} onChange={handleChangeMaxRating} style={{width:152, marginTop:20, marginRight:10}} label='Max Rating' variant='outlined' >
                        {options.MAX_RATING.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={tournamentForm.nbRatedGame} onChange={handleChangeMinimumRatedGames} style={{width:152, marginTop:20, marginRight:10}} label='Min Rated Games' variant='outlined' >
                        {options.MIN_RATED_GAMES.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>




                </div>


                <p style={{marginTop:40, fontWeight:'normal', fontSize:18}}>Participants</p>
                <Divider style={{marginBottom:20, }}/>

                
                <TextField  disabled={confirm} label={'Restriction'} select value={tournamentForm.participants} onChange={handleChangeParticipants}  variant='outlined' >
                        {options.PARTICIPANTS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                 </TextField>

                {(tournamentForm.participants === 'team') && <TextField  disabled={confirm} value={tournamentForm.teamId} onChange={handleChangeTeamId} style={{marginTop:20}} label='Team Id' variant='outlined' />}


            


                <div style={{marginTop:20, display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <TextField style={{width:'50%', marginRight:5}}  disabled={confirm} label={'Minimum Players'} select value={tournamentForm.minParticipants} onChange={handleChangeMinimumParticipants}  variant='outlined' >
                            {options.MIN_PARTICIPANTS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                    </TextField>

                    <TextField style={{width:'50%', marginLeft:5}}   disabled={confirm} label={'Maxiumum Players'} select value={tournamentForm.maxParticipants} onChange={handleChangMaximumParticipants}  variant='outlined' >
                            {options.MAX_PARTICIPANTS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                    </TextField>
                </div>

  
                <TextField
                    disabled={confirm}
                        value={'Private Event'}
                        style={{marginTop:20, marginBottom:20, caretColor:'transparent'}}
                        variant='outlined'
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                
                        <Switch
                        disabled={confirm}
                        onChange={handleChangePrivate}
                        checked={tournamentForm.private}
                        color='primary'
                        inputProps={{ 'aria-label': 'controlled' }}
                        />
                            </InputAdornment>
                        ),
                        }}
        
                    />

                    <div style={{minHeight:24, padding:15, display:'flex', flexDirection:'row', alignItems:'center', border:'1px solid #c4c4c4', borderRadius:5}}>
                        <p style={{ margin:0, padding:0}}>
                        {'Private and team tournaments will not be displayed in the lobby. Share the link with your participants.'}
                        </p>
                    </div>



             




                <p style={{marginTop:40, fontWeight:'normal', fontSize:18}}>Prizes</p>
                <Divider style={{marginBottom:20, }}/>


                 
               
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}> 
            

                    <TextField disabled={confirm}  label='Entry Fee'    id="outlined-number"
                        value={'$'+tournamentForm.tickets}
                        style={{ caretColor:'transparent', marginRight:5, width:'100%'}}
                        variant='outlined'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <ChangeTickets/>
                                </InputAdornment>,
                        }}
                        InputLabelProps={{
                            shrink: true,


                    }}/>


                    <TextField 
                        style={{ caretColor:'transparent', marginLeft:5, width:'100%'}} 
                        disabled={confirm} 
                        label={'Payout Distribution'} 
                        select 
                        value={tournamentForm.payoutFormula} 
                        onChange={handleChangePayoutFormula}  
                        variant='outlined' >
                            
                        {options.DISTRIBUTIONS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                        ))}
                    </TextField>
                

                </div>


                
                <div style={{marginTop:20, minHeight:24, padding:15, display:'flex', flexDirection:'row', alignItems:'center', border:'1px solid #c4c4c4', borderRadius:5}}>
                    <p style={{ margin:0, padding:0}}>
                    {FeeExplanation()}
                    </p>
                </div>



                <p style={{marginTop:40, fontWeight:'normal', fontSize:18}}>Bonus Funds</p>
                <Divider style={{marginBottom:20, }}/>

                <TextField 
                    disabled={confirm} 
                    value={bonusFundsCode} 
                    onChange={handleChangeBonusCode} 
                    label='Code' 
                    variant='outlined'
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton onClick={clickAddBonusCode} position='end'>
                                    <AddCircleOutlineIcon style={{color:'#6c6d6f'}}/>
                                </IconButton>
                            </>
                        ),
                    }}
                />




                <div style={{marginTop:20, display:'flex', alignItems:'flex-end', flexDirection:'row', justifyContent:'space-between'}}>
                    <p style={{marginTop:40, fontWeight:'normal', fontSize:18}}>Confirm</p>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                        {confirm && <IconButton onClick={()=>setConfirm(false)}  variant='contained'>
                            <ClearIcon/>
                        </IconButton>}
                    </div>
                </div>


                <Divider style={{marginBottom:20}}/>


                <div style={{padding:15, border:'1px solid #c4c4c4', borderRadius:5}}>
                    <p style={{margin:0, padding:0, marginBottom:30}}>
                    <a onClick={()=>{openInNewTab(payoutMatrixUrl + `/${tournamentForm.tickets}`)}}>Visit the <u>payout chart</u> for exact prize amounts by rank. </a>
                    Manually canceling tournaments, altering their settings in Lichess, or disclosing the entry code seed before the tournament concludes will result in the loss of hosting abilities. Participants of these tournaments will have their entry fee refunded.
                </p>


                {!confirm ? 
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        
                        <Button  onClick={handleClickCancel} style={{marginRight:5, height:40, width:90}} variant='outlined'>
                            <b>Reset</b>
                        </Button> 
                        
                        <Button onClick={()=>setConfirm(true)}  style={{height:40, width:90}}   color='primary' variant='contained'>
                            <b>Accept</b>
                        </Button>
                    </div>
                        :
                    <>
                            
                        <LichessButton/>

                        <Button  onClick={handleClickCreate} disabled={lichessOauthToken==''}  style={{width:'100%', height:50, marginTop:10}} variant='contained'>
                            <AddIcon style={{marginRight:5}}/>
                            <b>CREATE</b>
                        </Button> 
                    
                    </>
                
                }


                </div>
                

            </div>
        </div>


    );

}


export default CreateTournament;