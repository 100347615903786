import React, {useState} from 'react';
import { Button, Dialog } from '@mui/material';
import DoneIcon from '@material-ui/icons/Done';
import { handleLogin } from '../MagicV2';
import { handleGoogleLogin, handleFacebookLogin } from '../GoogleSignIn';
import {  styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import CustomCircularProgress from './CustomCircularProgress';
import useUser from '../hooks/useUser';
import MenuButton from './MenuButton';
const logo = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/logo.gif?alt=media&token=dc4531cd-d549-4e4b-b98b-25e12de52c0f';


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#7dd2ff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7dd2ff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#7dd2ff',
      },
      '&:hover fieldset': {
        borderColor: '#7dd2ff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7dd2ff',
      },
    },
});

export default function LoginModal(){

    const [user, auth, loading] = useUser();

    const  [open, setOpen] = useState(false);

    const [loadingMagic, setLoadingMagic] = useState(false);

    const [email, setEmail] = React.useState('');

    const [openEmail, setOpenEmail] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    }

    const handleClose = () => {
      if(!loadingMagic){
        setOpen(false);
        setEmail('');
        setOpenEmail(false);
      }
    }

    const handleClickLogin = async () => {
        setLoadingMagic(true);
        setOpen(false);
        setEmail('');
        await handleLogin(email);
        setLoadingMagic(false);
    }

    const handleClickGoogle = async () => {
        setLoadingMagic(true);
        setOpen(false);
        setEmail('');
        await handleGoogleLogin();
        setLoadingMagic(false);
    }

  //   const handleClickFacebook = async () => {
  //     await handleFacebookLogin()
  // }

    return(

        <>

            {(loading || loadingMagic) ?
                <CustomCircularProgress size={40}/>
                    :
                <>
                    {!auth ?
                        <Button variant='outlined' style={{ borderColor:'white'}} onClick={handleOpen}>
                             <b style={{color:'white'}} >Login</b>
                        </Button>
                            :
                        <MenuButton/>
                    }
                </>
            }
            
            <Dialog  

                open={open}

                onClose={handleClose}
            
                PaperProps={{
                    style:{
                    borderTopRightRadius:13,
                    borderTopLeftRadius:13,
                    borderBottomLeftRadius:13,
                    borderBottomRightRadius:13,
                    border:'1px solid #332f2f'
                    }}
                }
                
            >
                <div style={{width:300 , padding:20, alignSelf:'center', backgroundColor:'#1f2022'}} align='center'>

                    <div align='center'>
                        <img src={logo} alt='logo' style={{width:130, height:130}}/>
                    </div>


                    {openEmail && <>
                        <div align='center'> 
                            <CssTextField
                              disabled={loadingMagic}
                            focused={true}
                            margin='dense'
                            id='email'
                            label='Email'
                            type='email'
                            fullWidth
                            variant='standard'
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                              InputProps={{
                                style:{
                                  color:'#7dd2ff',
                                  backgroundColor:'#1f2022'

                                }
                              }}
                            />
                        </div>

                        <div align="center" style={{marginTop:30}}>
                        <Button disabled={loadingMagic} onClick={()=>setOpenEmail(false)}  variant='outlined'  style={{marginRight:10, borderColor:'#7dd2ff'}} color='primary' >
                                <CloseIcon style={{color:'#7dd2ff'}} />
                            </Button>
                            <Button disabled={loadingMagic} onClick={handleClickLogin}  variant='contained'  style={{backgroundColor:'#7dd2ff'}} color='primary' >
                                <DoneIcon style={{color:'#1f2022'}} />
                            </Button>
                        </div>
                      </>
                    }


                    {!openEmail && <>

                      <div align="center" style={{marginBottom:10, marginTop:10}}>
                        
                        <button class="email-button" onClick={()=>setOpenEmail(true)} style={{width:'90%'}}>
                          <EmailIcon style={{width:20, height:20, marginRight:7}}/>
                          <span class="email-text">Sign in with Email</span>
                        </button>

                      </div>

                      <div align="center">
                        
                        <button class="google-button" style={{width:'90%'}} onClick={handleClickGoogle}>
                          <GoogleIcon style={{width:20, height:20, marginRight:7}}/>
                          <span class="google-text">Sign in with Google</span>
                        </button>

                      </div>

                    </>

                    }

                    {/* <div align="center">
                      
                      <button class="facebook-button" style={{width:'80%'}} onClick={handleClickFacebook}>
                        <FacebookIcon style={{width:20, height:20, marginRight:7}}/>
                        <span class="facebook-text">Sign in with Facebook</span>
                      </button>

                    </div> */}


                </div>

            </Dialog>
        
        </>
    );

}