import { useEffect, useState } from 'react';
import { useLichessTournamentCardStyles } from '../../MuiStyles';
import { getPerf } from '../../../Lichess/api';
import ColorsIcon from '../Icons/ColorsIcon';
import { getUserPublic } from '../../../Lichess/api';
import {memo} from 'react';
import { Skeleton } from '@mui/material';

function Players({p, whiteUsername, blackUsername}){

    const classes = useLichessTournamentCardStyles();

    const [titleW, setTitleW] = useState(null);
    const [ratingW, setRatingW] = useState(null);

    const [titleB, setTitleB] = useState(null);
    const [ratingB, setRatingB] = useState(null);


    async function initOpponent(){

        if (p == null || whiteUsername == null || blackUsername == null) return;

        let w = await getUserPublic(whiteUsername);

        let b = await getUserPublic(blackUsername);
        
        if (w) {
            setRatingW(w['perfs'][p].rating);
            if(w['title'] != null && w['title'] != '') setTitleW(w['title']);
        }

        if (b) {
            setRatingB(b['perfs'][p].rating);
            if(b['title'] != null && b['title'] != '') setTitleB(b['title']);
        }

    }


    useEffect(()=>{

        initOpponent();

    },[whiteUsername, blackUsername])

    return(

        <div className='card' style={{marginBottom:20}}>
                
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>

                <div className={classes.cardHeaderLeftTextContainer} style={{marginLeft:0}}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        {(titleW != null) && <p className='descrip5' style={{fontSize:17, marginRight:5}}>{titleW}</p>}
                        <p style={{fontSize:18}}>{whiteUsername}</p>
                    </div>
                    <div style={{height:14.5, alignItems:'flex-start'}}>
                        {!ratingW ? <Skeleton width={45} height={14}/> : <p
                        style={{textAlign:'left', fontSize:12, color:'gray', fontWeight:'bold'}}>{ratingW}</p>}
                    </div>
                </div>

                {/* <p style={{padding:0, margin:0, fontSize:14}}>White</p> */}

                <ColorsIcon color={'white'} size={19}/>

            </div>

            <div style={{marginTop:20, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              
                <div className={classes.cardHeaderLeftTextContainer} style={{marginLeft:0}}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        {(titleB != null) && <p className='descrip5' style={{fontSize:17, marginRight:5}}>{titleB}</p>}
                        <p style={{fontSize:18}}>{blackUsername}</p>
                    </div>
                    <div style={{height:14.5, alignItems:'flex-start'}}>
                        {!ratingB ? <Skeleton width={45} height={14}/> : <p
                        style={{textAlign:'left', fontSize:12, color:'gray', fontWeight:'bold'}}>{ratingB}</p>}
                    </div>
                </div>

                {/* <p style={{padding:0, margin:0, fontSize:14}}>Black</p> */}

                <ColorsIcon color={'black'} size={19}/>


            </div>

        </div>

    );


}

export default memo(Players);