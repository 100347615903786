import {atom} from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { options } from './Lichess/tournamentForm';
import { options as wagerOptions } from './Lichess/wagerForm';
const { persistAtom } = recoilPersist();



// LEGAL
export const atom_ipLocation = atom({
    key: 'ATOM-IP-LOCATION-CACHE',
    default: {locationData:null, timestamp:10000000000000},
    effects_UNSTABLE:[persistAtom]
})

export const atom_blockAppLocation = atom({
    key: 'BLOCK-LOCATION',
    default: false
})

export const atom_ageChecked = atom({
    key: 'AGE-CHECKED',
    default: false,
    effects_UNSTABLE:[persistAtom]
})



// FEES

export const atom_payoutFees = atom({
    key: 'PAYOUT-FEES',
    default: {wagerFee:8, tournamentFee:10},
    effects_UNSTABLE:[persistAtom]
})

export const atom_paymentFees = atom({
    key: 'PAYMENT-FEES',
    default: {feePercent:.03, feeFixed:.52},
    effects_UNSTABLE:[persistAtom]
})




export const atom_Currency = atom({
    key: 'CURRENCY',
    default: 'USD',
    effects_UNSTABLE:[persistAtom],
})


// BUY TICKETS

export const atom_lichessTicketModal = atom({
    key: 'SHOW-LICHESS-TICKET-MODAL',
    default: false
})

export const atom_lichessTicketCount = atom({
    key: 'LICHESS-TICKET-MODAL-COUNT',
    default: 10,
})


// LICHESS OAUTH

export const atom_lichessOauthToken = atom({
    key: 'LICHESS-OAUTH-TOKEN',
    default: '',
    effects_UNSTABLE:[persistAtom]
})



// CREATE LICHESS TOURNAMENT

export const atom_createLichessTournamentFormDateTime = atom({
    key: 'LICHESS-TOURNAMENT-FORM-DATETIME',
    default: 0,
    effects_UNSTABLE:[persistAtom],
})

export const atom_createLichessTournamentForm = atom({
    key: 'LICHESS-TOURNAMENT-FORM',
    default: options.DEFAULT,
    effects_UNSTABLE:[persistAtom],
})

export const atom_createLichessTournamentFormConfirm = atom({
    key: 'LICHESS-TOURNAMENT-FORM-CONFIRM',
    default: false,
    effects_UNSTABLE:[persistAtom],
})

export const atom_lichessTournamentBonusFundsCode = atom({
    key: 'LICHESS-TOURNAMENT-BONUS-CODE',
    default: '',
    effects_UNSTABLE:[persistAtom],
})

// CREATE LICHESS WAGER

export const atom_createLichessWagerForm = atom({
    key: 'LICHESS-WAGER-FORM',
    default: wagerOptions.DEFAULT,
    effects_UNSTABLE:[persistAtom],
})

export const atom_createLichessWagerFormConfirm = atom({
    key: 'LICHESS-WAGER-FORM-CONFIRM',
    default: false,
    effects_UNSTABLE:[persistAtom],
})


// SITE STATS

export const atom_siteStats = atom({
    key: 'SITE-STATS',
    default: null,
    effects_UNSTABLE:[persistAtom],
})


// LANDING CACHE


export const atom_featuredTournament = atom({
    key: 'FEATURED-TOURNAMENT',
    default: null,
    effects_UNSTABLE:[persistAtom]
})