import React from 'react';
import { useSupportStyles } from './MuiStyles';
import { copyLink } from '../utils';
import { Divider } from '@mui/material';


export default function Support(){

    const classes = useSupportStyles();

    let supportEmail = 'support@ampedchess.com';
    let contactEmail = 'davidg3@protonmail.com';

    let supportText = 'Have a question? Need help buying tickets or completing a payout? Want to appeal a ban? Please direct support requests to the following email.'
    let contanctText = 'Want to work with Amped Chess? Please direct business inquiries to the following email.';

    return(
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', padding:20, paddingTop:0}}>
            <div id='support' className={classes.container}>
                
                <p className={classes.titleText}>Support</p>

                <Divider />

                <p className={classes.bodyText}>{supportText}</p>
                <p onClick={()=>copyLink(supportEmail, true, 'Copied to clipboard')} className={classes.emailText}>{supportEmail}</p>

                <p id='contact' className={classes.titleText} style={{marginTop:100}}>Contact</p>

                <Divider />

                <p className={classes.bodyText}>{contanctText}</p>

                <p onClick={()=>copyLink(contactEmail, true, 'Copied to clipboard')} className={classes.emailText}>
                    {contactEmail}
                </p>

                <div className={classes.addressRowContainer}>
                    <div className={classes.addressContainer}>
                        <p className={classes.addressRow1}>530-B Harkle Road STE 100</p>
                        <p className={classes.addressRow2}>Santa Fe, NM 87505</p>
                        <p className={classes.addressDescription}>Legal </p>

                    </div>
                    <div className={classes.addressContainer} >
                        <p className={classes.addressRow1}>580 Arastradero Rd Apt 405</p>
                        <p className={classes.addressRow2}>Palo Alto, CA 94306</p>
                        <p className={classes.addressDescription}>Mailing</p>
                    </div>
                </div>

            </div>
        </div>
        
    );
}
