// import React, { useState } from 'react';
// import { Button } from '@material-ui/core';

// function LastUpdate({ timestamp }) {
//     const [time, setTime] = useState(new Date(timestamp));
//     const [formatted, setFormatted] = useState('');

//     React.useEffect(() => {
//         // Update the time state whenever the timestamp prop changes
//         setTime(new Date(timestamp));

//         // Format the time
//         const formattedTime = time.toLocaleString('default', {
//             hour: '2-digit',
//             minute: '2-digit',
//             second: '2-digit',
//             hour12: false,
//             timeZoneName: 'short',
//         });

//         setFormatted(formattedTime);
//     }, [timestamp, time]);

//     return (
//         <Button variant='outlined'>
//             {formatted}
//         </Button>
//     );
// };

// export default LastUpdate;


import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';

function LastUpdate({ timestamp }) {
    const [timeDiff, setTimeDiff] = useState('');

    useEffect(() => {
        // This function will be called every time the component updates
        const interval = setInterval(() => {
            // Get the current time and the timestamp as Date objects
            const now = new Date();
            const timeOfEvent = new Date(timestamp);

            // Calculate the difference in milliseconds
            const diffInMilliseconds = now - timeOfEvent;

            // Convert the difference to seconds
            const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

            // Determine the appropriate display message
            let displayMessage;

            if (diffInSeconds < 1) {
                displayMessage = "Last move <1 second ago";
            } else if (diffInSeconds >= 1 && diffInSeconds < 2) {
                displayMessage = "Last move 1 second ago";
            } else {
                displayMessage = `Last move ${diffInSeconds} seconds ago`;
            }

            // Update the state with the display message
            setTimeDiff(displayMessage);
        }, 100); // Update every 100 milliseconds for smoother display

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [timestamp]);

    return (
        <Button variant='outlined' style={{width:280}}>
            {timeDiff}
        </Button>
    );
};

export default LastUpdate;
