import React, {memo, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { acceptLichessWager, cancelLichessWager, getLichessWager, lichessWagersDB, markLichessWagerComplete } from '../../../Firebase';
import WagerRow from './WagerRow';
import { Skeleton } from '@mui/material';
import LichessButton from '../LichessButton';
import { Button } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { atom_lichessOauthToken } from '../../../Atoms';
import useUser from '../../../hooks/useUser';
import { getPerf, getUserPublic } from '../../../Lichess/api';
import { useLichessTournamentCardStyles } from '../../MuiStyles';
import TournamentIcon from '../Icons/TournamentIcon';
import { pieces } from './utils';
import { Conditions } from './Conditions';
import DuelIconn from '../Icons/DuelIcon';
import { numberWithCommas } from '../../../utils';
import useWindowSize from '../../../hooks/WindowDimensions';
import { CircularProgress, Divider } from '@material-ui/core';
import GavelIcon from '@mui/icons-material/Gavel';
import ColorsIcon from '../Icons/ColorsIcon';
import Players from './Players';
import WarningBar from '../../WarnningBar';
import { WAGER_PAYOUT_WAITING_PERIOD } from '../../../Lichess/wagerForm';

function Wager() {

    const classes = useLichessTournamentCardStyles();

    let { wagerId } = useParams();

    const [user, auth, userLoading] = useUser();

    const lichessOauthToken = useRecoilValue(atom_lichessOauthToken);

    const [wager, setWager] = useState(null);


    React.useEffect(()=>{

        if (wagerId){

            let wid = String(wagerId);

            const subscribe = lichessWagersDB.doc(wid).onSnapshot((snapshot) => {
                let w = snapshot.data();
                if (w != null) {
                    w['id'] = snapshot.id;
                    setWager(w);
                }
                if (w == null) setWager(-1);
            });
        
            return ()=>{
                subscribe();
            }

        }
    
    },[wagerId])
    
  

    useEffect(()=>{

        window.scrollTo(0, 0);

    },[])


    async function handleClickAccept(){

        if (wager){

            let accepted = await acceptLichessWager(lichessOauthToken, wager['id']);

            if (accepted == true) window.location.reload();
            

        }

    }


    function Acceptor(){


        return(

            <div style={{padding:15, border:'1px solid #c4c4c4', borderRadius:5}}>

                <Conditions/>

                <>
                              
                    <LichessButton/>

                    <Button onClick={handleClickAccept} disabled={lichessOauthToken==''} style={{width:'100%', height:50, marginTop:10}} variant='contained'>
                        <b>Accept</b>
                    </Button> 
        
                </>

            </div>
        )

    }


   
  


    async function handleClickCancel(){

        if (wager) {
            let canceled = await cancelLichessWager(wager['id']);
            if (canceled) window.location.reload();
        }

    }


    function Risk(){
        return '$' + numberWithCommas(wager.tickets);
    }
   
     function Earn(){
       return '$' + numberWithCommas(2 * wager.tickets);
    }

    function Prizes(){

        const {width} = useWindowSize();


        return(


            <div style={{ textAlign:'center', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <div style={{width:'50%', display:'flex',  alignItems:'center', justifyContent:'left', flexDirection:'column'}}>
                    <p style={{color:'#ff1744', textAlign:'center', fontSize:(width>450)?18:17, fontWeight:800, margin:0, padding:0, marginBottom:5}}>{Risk()}</p>
                    <p style={{fontWeight:500, color:'#6c6d6f', textAlign:'center', margin:0, padding:0}}>Risk</p>
                </div>
                <Divider orientation="vertical" flexItem style={{marginLeft:(width>450)?27:15, marginRight:(width>450)?27:15}} />
                <div style={{width:'50%', display:'flex', alignItems:'center', justifyContent:'right', flexDirection:'column'}}>
                    <p style={{color:'#36AA3E', textAlign:'center', fontSize:(width>450)?18:17, fontWeight:800, margin:0, padding:0, marginBottom:5}}>{Earn()}</p>
                    <p style={{fontWeight:500, color:'#6c6d6f', textAlign:'center', margin:0, padding:0 }}>Win</p>
                </div>

            </div>

        );


    }

    function ClocksStart(){

        const [timeRemaining, setTimeRemaining] = React.useState('');

        function startTimer() {
    
            setInterval(function () {
      
                let timeRemaining = (wager['expireAt'] - Date.now());
      
                if(timeRemaining < 1000){
                    setTimeRemaining('00:00');
                    return;
                }
      
                timeRemaining = Math.trunc(timeRemaining/1000);
      
                let hours = parseInt(timeRemaining / (60 * 60), 10);
                let minutes = parseInt((timeRemaining / 60) % 60, 10);
                let seconds = parseInt(timeRemaining % 60, 10);
      
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
      
                if (hours < 1){
                    setTimeRemaining(minutes+':'+seconds);
                } else {
                    hours = hours < 10 ? "0" + hours : hours;
                    setTimeRemaining(hours+':'+minutes+':'+seconds);
                }    
      
            }, 1000);
        }
    
        useEffect(()=>{
        
            if (wager && wager.status == 'ACCEPTED'){
    
                startTimer();
    
            } else {
    
                setTimeRemaining('00:00');
            }
        
        },[wager])


        return(

            <>
                {(!wager || !user) ? <></> :
                    
                    <div style={{marginTop:0, marginBottom:15}}>

                    
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>

                            <>
                            {timeRemaining!=''?
                                <Button disabled={true} style={{height:45, width:'100%'}} variant='contained'>
                                    Clocks Start{'\u00A0'}{'\u00A0'}<b>{timeRemaining}</b>
                                </Button>
                                    :
                                <Skeleton height={45} width={'100%'}/>
                                }
                            </>


                        </div>

                    </div>
                
                }
            </>

        );



    }
    


    function JoinButton({text}){

        return(

            <div style={{marginTop:20, display:'flex', width:'100%', flexDirection:'row'}}>
                <a href={`https://www.lichess.org/${wager.challengeId}`} style={{width:'100%', textDecoration:'none'}} target='_blank'>
                    <Button style={{height:45, width:'100%'}}  variant='outlined'>
                        <b>{text}</b>
                    </Button>
                </a>
            </div> 

        );

    }

    function ClockText(){

        if (wager) return  `${wager['clockTime']}+${wager['clockIncrement']}`

    }

    function Status(){


        const [timeRemainingToPayout, setTimeRemainingToPayout] = React.useState('');

        function startTimerPayout() {
      
          setInterval(function () {
      
              let timeRemaining = ((wager['expireAt'] + (WAGER_PAYOUT_WAITING_PERIOD * 60 * 60 * 1000 + 60000)) - Date.now());
      
              if(timeRemaining < 1000){
                setTimeRemainingToPayout('00:00');
                  return;
              }
      
              timeRemaining = Math.trunc(timeRemaining/1000);
      
              let hours = parseInt(timeRemaining / (60 * 60), 10);
              let minutes = parseInt((timeRemaining / 60) % 60, 10);
              let seconds = parseInt(timeRemaining % 60, 10);
      
              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;
      
              if (hours < 1){
                setTimeRemainingToPayout(minutes+':'+seconds);
              } else {
                  hours = hours < 10 ? "0" + hours : hours;
                  setTimeRemainingToPayout(hours+':'+minutes+':'+seconds);
              }    
      
          }, 1000);
        }
      
        React.useEffect(()=>{
                
          startTimerPayout();
      
        },[wager])


        return(

            <div className='card'>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                    
                    <DuelIconn size={38}/>

                    {
                        (wager.disputed == true) ? 

                            <div style={{display:'flex', flexDirection:'column'}}>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                                    <GavelIcon  style={{color:'#ff1744', width:21, height:22, marginRight:5}} />
                                    <p style={{padding:0, margin:0, fontSize:18, color:'#ff1744'}}>DISPUTED</p>
                                </div>
                                <p style={{padding:0, margin:0, fontSize:10, textAlign:'right', marginTop:8, color:'#6c6d6f'}}>Please wait for more details</p>
                            </div>

                            :

                        <>

                            {(wager.status == 'REFUND') &&

                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                                        <p style={{padding:0, margin:0, fontSize:18, color:'#ff1744'}}>REFUND</p>
                                    </div>
                                    <p style={{padding:0, margin:0, fontSize:10, marginTop:8, textAlign:'right', color:'#6c6d6f'}}>{wager.refundDescription}</p>
                                </div> 
                                
                            }

                            {(wager.status == 'NO-SHOW') &&

                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                                        <p style={{padding:0, margin:0, fontSize:18, color:'#ff1744'}}>NO-SHOW</p>
                                    </div>
                                    <p style={{padding:0, margin:0, fontSize:10, marginTop:8, textAlign:'right', color:'#6c6d6f'}}>{'Entry fees will be refunded shortly'}</p>
                                </div> 

                            }

                            {(wager.status == 'COMPLETE') &&

                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-end'}}>
                                    {/* <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                                        <p style={{padding:0, margin:0, fontSize:18, color:'gray'}}>COMPLETE</p>
                                    </div> */}
                                    {/* <Button disabled={true} variant='contained' >
                                        <CircularProgress style={{marginRight:10}} size={20}/>
                                        <b> BOT DETECTION ACTIVE</b>

                                    </Button> */}

                                    <Button style={{width:90, minHeight:20}} disabled={true} variant='contained' >
                                        {/* <CircularProgress style={{marginRight:10}} size={20}/> */}
                                        <b>{timeRemainingToPayout}</b>
                                    </Button>

                                    <p style={{padding:0, margin:0, fontSize:10, textAlign:'right', marginTop:8,  color:'#6c6d6f'}}>{'BOT DETECTION ACTIVE'}</p>

                                    {/* {wager.winner == 'white' && <p style={{padding:0, margin:0, fontSize:10, marginTop:8, textAlign:'right', color:'#6c6d6f'}}>{'White Wins'}</p>}
                                    {wager.winner == 'black'  && <p style={{padding:0, margin:0, fontSize:10, marginTop:8, textAlign:'right', color:'#6c6d6f'}}>{'Black Wins'}</p>}
                                    {wager.winner == null && <p style={{padding:0, margin:0, fontSize:10, marginTop:8, textAlign:'right', color:'#6c6d6f'}}>{'Draw'}</p>}  */}

                                </div>

                            }

                            {(wager.status == 'BLACK-WINS') &&

                                <div style={{display:'flex', flexDirection:'column'}}>
                                      <p style={{padding:0, margin:0, fontSize:18, color:'gray'}}>BLACK-WINS</p>
                                </div> 

                            }


                            {(wager.status == 'WHITE-WINS') &&

                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <p style={{padding:0, margin:0, fontSize:18, color:'gray'}}>WHITE-WINS</p>
                                </div> 

                            }

                        </>
                    }

                </div>
            </div>

        );

    }

    function Header(){


        return(
            <div className='card' style={{marginBottom:20}}>
            
                <div className={classes.cardHeaderContainer}>
        
                    <div className={classes.cardHeaderContainerLeft} style={{marginBottom:0}}>
                        
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <TournamentIcon size={38} perf={getPerf(wager.variant, wager.clockTime, wager.clockIncrement)} variant={wager.variant} />
                        </div>
                        
                        <div className={classes.cardHeaderLeftTextContainer}>
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <p style={{fontSize:18}}>{'Wager'}</p>
                            </div>
                            <div style={{height:14.5, alignItems:'flex-start'}}>
                                <p style={{textAlign:'left', fontSize:12, color:'gray', fontWeight:'bold'}}>{ClockText()}</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div style={{marginTop:5, display:'flex', flexDirection:'row',alignItems:'flex-end', justifyContent:'space-between'}}>

                    <p style={{fontSize:10, padding:0, margin:0}}>id: {wagerId.slice(0,8)}</p>
                    <Prizes/>

                </div>

            </div>

        );
                


    }


    function Select(){

        if (wager == -1) return <WarningBar pText={'Wager expired, canceled, or does not exist'}/>

        if (!wager) return <></>

        let p = getPerf(wager.variant, wager.clockTime, wager.clockIncrement);

        if (!user && wager.status == 'ACTIVE'){

            return(
                <>
                <WagerRow wager={wager}/>
                <WarningBar pText={'Login to accept wager'}/>
                </>
            )

        }

        if (!user && wager.status != 'ACTIVE'){

            return(
                <>
                <WagerRow wager={wager}/>
                {/* <WarningBar pText={'Login to accept wager'}/> */}
                </>
            )

        }

        if (wager.status == 'ACTIVE' &&  wager.uid != user.uid) {


            return(
                <>
                    <WagerRow wager={wager}/>
                    <Acceptor/>
                </>
            );

        }

        if (wager.status == 'ACTIVE' &&  wager.uid == user.uid) {

            return(
                <>
                    <WagerRow wager={wager}/>
                    <Button onClick={handleClickCancel} style={{width:'100%', height:40, marginTop:0}} variant='outlined'>
                        <b>Cancel</b>
                    </Button>
                </>
            );

        }


        if (wager.status == 'CANCELED') {

            return(
                
                <WagerRow wager={wager}/>
                    
            );

        }

        if (wager.status == 'EXPIRED') {

            return(
                
                <WagerRow wager={wager}/>
                    
            );

        }


        if (wager.status == 'ACCEPTED') {

            return(
                <>
                    <ClocksStart />
                    <Header />
                    <Players p={p} whiteUsername={wager.whiteUsername} blackUsername={wager.blackUsername}/>
                    <Status/>
                    <JoinButton text={'Join Game'}/>
                </>
                    
            );

        }


        if (wager.status == 'NO-SHOW') {

            return(
                <>
                   <ClocksStart />
                    <Header/>
                    <Players p={p} whiteUsername={wager.whiteUsername} blackUsername={wager.blackUsername}/>
                    <Status/>
                    <JoinButton text={'Game'}/>
                </>
                    
            );

        }


        if (wager.status == 'STARTED') {

            return(
                <>
                    <ClocksStart />
                    <Header />
                    <Players p={p} whiteUsername={wager.whiteUsername} blackUsername={wager.blackUsername}/>
                    <Status/>
                    <JoinButton text={'Game'}/>
                </>
                    
            );

        }

        if (wager.status == 'REFUND') {

            return(
                <>
                    {/* <ClocksStart /> */}
                    <Header/>
                    <Players p={p} whiteUsername={wager.whiteUsername} blackUsername={wager.blackUsername}/>
                    <Status/>
                    <JoinButton text={'Game'}/>
                </>
                    
            );

        }


        if (wager.status == 'COMPLETE') {

            return(
                <>
                    {/* <ClocksStart /> */}
                    <Header/>
                    <Players p={p} whiteUsername={wager.whiteUsername} blackUsername={wager.blackUsername}/>
                    <Status/>
                    <JoinButton text={'Game'}/>
                </>
                    
            );

        }



        if (wager.status == 'BLACK-WINS') {

            return(
                <>
                    {/* <ClocksStart /> */}
                    <Header/>
                    <Players p={p} whiteUsername={wager.whiteUsername} blackUsername={wager.blackUsername}/>
                    <Status/>
                    <JoinButton text={'Game'}/>
                </>
                    
            );

        }

        if (wager.status == 'WHITE-WINS') {

            return(
                <>
                    {/* <ClocksStart /> */}
                    <Header/>
                    <Players p={p} whiteUsername={wager.whiteUsername} blackUsername={wager.blackUsername}/>
                    <Status/>
                    <JoinButton text={'Game'}/>
                </>
                    
            );

        }

    }



    return (

        <div style={{padding:20, display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>


            {!wager ?
                <Skeleton  style={{borderRadius:5, margin:0, padding:0, marginTop:-38}} height={180} />
                    :
                <>
                    <Select/>
                </>

            }

            {



            }

        </div>

    );

}

export default memo(Wager);
