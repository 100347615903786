import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function RowsLoading({height}) {

    return (

        <div style={{width:'100%'}}>
            <Skeleton style={{borderRadius:5, marginBottom:20}} variant='rounded'  height={height} />
            <Skeleton style={{borderRadius:5, marginBottom:20}} variant='rounded'  height={height} />
            <Skeleton style={{borderRadius:5, marginBottom:20}} variant='rounded'  height={height} />
            <Skeleton style={{borderRadius:5, marginBottom:20}} variant='rounded'  height={height} />
            <Skeleton style={{borderRadius:5, marginBottom:20}} variant='rounded'  height={height} />
        </div>

    );

}
