export async function getTournamentLichessAPI(id){

    let url = `https://lichess.org/api/tournament/${id}`;
  
    let data = null;
  
    try {
  
      await fetch(url, {method: 'get'}).then(async (res)=>{
        
        if (res.status == 200) data = await res.json();
  
      })
  
    } catch (e) {
  
      console.log(e);
  
    }

    // console.log(data);
  
    return data;
  
}

const readStream = processLine => response => {
  const stream = response.body.getReader();
  const matcher = /\r?\n/;
  const decoder = new TextDecoder();
  let buf = '';

  const loop = () =>
    stream.read().then(({ done, value }) => {
      if (done) {
        if (buf.length > 0) processLine(JSON.parse(buf));
      } else {
        const chunk = decoder.decode(value, {
          stream: true
        });
        buf += chunk;

        const parts = buf.split(matcher);
        buf = parts.pop();
        for (const i of parts.filter(p => p)) processLine(JSON.parse(i));
        return loop();
      }
    });

  return loop();
}

export async function getTournamentResultsLichessAPI(id){


  function sortTournamentResults(a, b){

    return a['rank'] - b['rank'];

  }

  function meanRating(data){

    var sum = 0;

    for (var i = 0; i < data.length; i++){

      sum += data[i].rating;

    }

    return sum / data.length;

  }


  let url = `https://lichess.org/api/tournament/${id}/results`;


  try {

    let data = [];

    let stream = fetch(url, {method: 'get'});

    const onMessage = obj => {data.push(obj)};

    const onComplete = () => {};
    
    await stream.then(readStream(onMessage)).then(onComplete);

    if(data.length > 0) data = data.sort(sortTournamentResults);

    return {
      data,
      meanRating: Math.trunc(meanRating(data))
    }
      
  } catch(e) {

    console.log(e);
    return -1;
   
  }

}

export async function getMyAccount(token){

  const headers = {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }

  let url = `https://lichess.org/api/account`;

  let data = null;

  try {

    await fetch(url, {headers, method: 'get'}).then(async (res)=>{
      
      if (res.status == 200) data = await res.json();

    })

  } catch (e) {

    console.log(e);

  }

  return data;

}

export async function exportGame(gameId) {

  if (!gameId) return null;

  let game = null;
  
  try {

    const url = `https://lichess.org/game/export/${gameId}?pgnInJson=true`;
  
    const headers = {
      'Accept': 'application/json'
    };
    
    const response = await fetch(url, { headers });

    let ok = response.ok;
    let status = response.status;

    if (status >= 200 && status <= 299) game = await response.json();


  } catch(e) {

    console.log(e);
   
  }

  return game;
 
}

export async function crossTable(username1, username2) {

  if (!username1 || !username2) return null;

  let table = null;
  
  try {

    const url = `https://lichess.org/api/crosstable/${username1}/${username2}`;
  
    const headers = {
      'Accept': 'application/json'
    };
    
    const response = await fetch(url, { headers });

    let status = response.status;

    if (status >= 200 && status <= 299) table = await response.json();


  } catch(e) {

    console.log(e);
   
  }

  return table;
 
}

const SERVER_COUNT = 7; // Total number of servers
const MAX_RETRIES = SERVER_COUNT - 1; // Maximum number of retries

export async function getUserPublic(username) {

  let retries = 0;
  let serverNumber = Math.floor(Math.random() * SERVER_COUNT); // Randomly select a server number between 0 and 3

  while (retries < MAX_RETRIES) {
    const serverUrl = `https://lichess-api-server${serverNumber}.herokuapp.com`;
    const url = `${serverUrl}/user/${username}`;

    try {

      const response = await fetch(url);

      if (response.status == 200) {
        const data = await response.json();
        return data;
      } else if (response.status === 429) {
        // Rate limit exceeded, try a different server
        console.log(response.status);
        serverNumber = (serverNumber + 1) % SERVER_COUNT; // Select the next server in a circular manner
        retries++;
        continue;
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (e) {
      console.log(e);
      retries++;
    }
  }

  console.log(`Exceeded maximum number of retries.`);
  return null;

  
}

export function getPerf(variant, clockTime, clockIncrement) {

  if (variant != 'standard') return variant;

  let totalGameTime = clockTime * 60 + clockIncrement * 40;

  if (totalGameTime < 180){

    return 'bullet';

  } else if (totalGameTime < 480) {

    return 'blitz';

  } else if (totalGameTime < 1500){

    return 'rapid';

  } else {

    return 'classical';

  }

};

export async function lichessTournamentContainsUsername(tid, username){

  try {

    let r = await getTournamentResultsLichessAPI(tid);

    if (r == -1) return false;

    let data = r['data'];

    let contains = false;

    for (var i = 0; i < data.length; i++){
      r = data[i];
      if (r['username'] == username) contains = true;
    }

    return contains;

  } catch (e){

    console.log(e);

  }

  return false;

}


