import React from 'react';
import { getStats } from '../Firebase';
import useWindowSize from '../hooks/WindowDimensions';
import hero from '../art/hero.webp';
import hero2 from '../art/hero2.webp';
import { Avatar } from '@material-ui/core';
import { numberWithCommas } from '../utils';
import { useLandingStyles } from './MuiStyles';
import Tooltip from '@mui/material/Tooltip';
import FeaturedTournament from './Lichess/Tournaments/FeaturedTournament';
import Policies from './Policies';
import PayoutChart from './Lichess/Payouts/PayoutChart';
import { Skeleton } from '@mui/material';
import { useRecoilState } from 'recoil';
import { atom_siteStats } from '../Atoms';


export default function Landing(){

    const classes = useLandingStyles();

    const {width} = useWindowSize();

    const [siteStats, setSiteStats] = useRecoilState(atom_siteStats);

    async function refreshStats(){
        let stats = await getStats('chess', ['users', 'tickets', 'bonusTickets']);
        setSiteStats({userCount:stats['users'], volume:300 + (stats['tickets'] + stats['bonusTickets'])})

    }

    React.useEffect(()=>{
      refreshStats();
    },[])


    function HeroWidth(){

        let w =  .5*width-30
        if (w < 650) return w*.9;
        return 320;
    }

    function titleTextFontSize(width){


      if (width < 375){

        return 20

      }
      else if (width < 429){


        return 25

      }

      else if (width < 490){

        return 30

      }
      else if (width < 550){

        return 35

      } else if (width < 650) {

        return 40 

      } else if (width < 670) {

        return 45

      } else if (width < 710){

        return 45
      }
      else {

        return 45

      }

    }

    function heroText(width){



      if (width < 440) {

        return 12

      } else if (width < 485) {

        return 14

      } else if (width < 530) {

        return 16

      } else if (width < 565) {

        return 18

      } else if (width < 615) {

        return 20

      } else if (width < 660) {

        return 22

      } else {

        return 25
      }

    }

    function sloganTextSize(width){



      let base = 16;

      let s1 = heroText(width);

      if (s1 < 16) return s1;

      return base;

    }

 
    return(
        
      <>

        {/* Banner */}
        <div style={{display:'flex', backgroundColor:'#1f2022', backgroundColor:'#31d0c1', backgroundColor:'#5865f2', backgroundColor:"#36AA3E", flexDirection:'row', justifyContent:'center', alignItems:'center',   borderTop:'1px solid #332f2f', height:70}}>
            <p style={{textAlign:'center', fontSize:sloganTextSize(width), padding:0,  margin:0, color:'white', fontWeight:600}}>
              The premier site for real money chess 💸
            </p>
            
        </div>

        {/* Hero 1 */}
        <div  className='animated-gradient'  style={{minHeight:170, position: 'relative', padding:20, paddingBottom:0, paddingTop:0}}>
          <div  style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <div className={classes.headerBody}  >
              <div style={{paddingRight:10,}}>
                <p style={{textAlign:'left', fontSize:heroText(width), padding:0, margin:0,  color:'#5976e5', color:'white', fontWeight:650}}>
                  Win big in exciting prize pool tournaments
                </p>
              </div>
              <img src={hero} style={{maxWidth:270, maxHeight:270}} width={HeroWidth()} height={HeroWidth()} />
            </div>
          </div>
        </div>

        {/* Hero 2 */}
        <div   style={{minHeight:170,  position: 'relative', padding:20, paddingBottom:0, paddingTop:0}}>
          <div  style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <div className={classes.headerBody}  >
                <img src={hero2} style={{maxWidth:270, maxHeight:270}} width={HeroWidth()} height={HeroWidth()} />
                <p style={{color:'#5976e5', color:'#3c81f5',color:'#260624', textAlign:'right', fontSize:heroText(width), padding:0, paddingLeft:10, margin:0, fontWeight:650}}>
                  Compete head to head in staked wagers
                </p>
            </div>
          </div>
        </div>

        {/* Stats */}
        {/* <div style={{display:'flex', flexDirection:'row', width:'100%', height:125}}>
   
        </div> */}


        <div  style={{display:'flex', height:125, backgroundColor:(siteStats == null)?'':'#1f2022',flexDirection:'row', justifyContent:'center', alignItems:'center',}}>


          {(siteStats == null) ? 
              <Skeleton style={{height:208.34}} width={'100%'}/>
            :
           <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', maxWidth:800, width:'100%'}}>
            <div style={{width:'40%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
              <p className='descrip5' style={{color:'white', color:'#2FB761', color:'#36AA3E', color:'white', textAlign:'center', fontSize:21, fontWeight:800, margin:0, padding:0, marginBottom:10}}>{numberWithCommas(siteStats['userCount'])}</p>
              <p style={{fontWeight:500, color:'white',color:'white', textAlign:'center', margin:0, padding:0}}>Players</p>
            </div>
            <div style={{width:'40%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
              <p className='descrip0' style={{color:'white',color:'#2FB761', color:'white', textAlign:'center', fontSize:21, fontWeight:800, margin:0, padding:0, marginBottom:10}}>{'$' + numberWithCommas(siteStats['volume'])}</p>
              <p style={{fontWeight:500, color:'white',color:'white', textAlign:'center', margin:0, padding:0, }}>Prizes</p>
            </div>
          </div> 
          }
        </div>

         {/* Tournament Spotlight */}
        <div style={{padding:0, paddingLeft:20, paddingRight:20}}>
          <div style={{display:'flex', marginTop:45, marginBottom:40, flexDirection:'row', justifyContent:'center'}}>
              <div style={{ width: "100%", maxWidth: 690 }}>
              <p style={{fontWeight:800, marginBottom:20, fontSize:Math.min(24, titleTextFontSize(width))}} className='descrip1' >
                Tournament Spotlight
              </p>
              <FeaturedTournament tid={'hq20giM3'}/>
            </div>
          </div>

        </div>


        {/* Features */}
        <div  className='animated-gradient' style={{height:125, backgroundColor:'#1f2022', paddingLeft:20, paddingRight:20, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', maxWidth:800, width:'100%'}}>
            <div style={{width:'33.333%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <p style={{fontWeight:'bold',fontSize:sloganTextSize(width),  color:'white',color:'white', textAlign:'center', margin:0, padding:0}}>Lichess <br/> Gameplay</p>
            </div>
            <div style={{width:'33.333%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <p style={{fontWeight:'bold',fontSize:sloganTextSize(width),  color:'white',color:'white', textAlign:'center', margin:0, padding:0, }}>Anti-Cheating <br/> Measures </p>
            </div>
            <div style={{width:'33.333%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <p style={{fontWeight:'bold', fontSize:sloganTextSize(width),  color:'white',color:'white', textAlign:'center', margin:0, padding:0, }}>Low <br/> Fees</p>
            </div>
          </div>
        </div>

        <div   style={{ padding:20, paddingTop:40, paddingBottom:40, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
          <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', width:'100%', maxWidth:690}}>
            <div className='card' style={{backgroundColor:'white'}}>
              <PayoutChart width={width*.7}  entryTickets={5} n={100} max={400} min={25} bonusTickets={0} formula={'amped'}/>
            </div>
            {/* <p  style={{textAlign:'left', fontSize:heroText(width), padding:0, margin:0, marginLeft:20,  color:'white', color:'#5976e5', fontWeight:650}}>
              Advanced payout <br/> system
            </p> */}
          </div>
        </div>
               
        {/* Teams / Clubs */}

        <div style={{backgroundColor:'#1f2022', paddingLeft:20, paddingRight:20}}>

          <div style={{display:'flex', marginTop:45, marginBottom:40, flexDirection:'row', justifyContent:'center'}}>
              <div style={{ width: "100%", maxWidth: 660 }}>

                <p style={{fontSize:Math.min(24, titleTextFontSize(width))}} className='teams-clubs-text' >
                  TEAMS / CLUBS
                </p>
              
                <div style={{marginTop:20, display: "flex", flexDirection: "row" }}>

                <Tooltip title={<b>Chessminds</b>}>
                  <Avatar
                    style={{
                      border: ".5px solid gray",
                      width: 70,
                      height: 70,
                      marginRight: 15,
                    }}
                    src="https://i.ibb.co/wJ2q0wV/In2cir-GOPus.png"
                  />
                  </Tooltip>
                <Tooltip title={<b>UC Berkeley Chess Club</b>}>
                  <Avatar
                    style={{
                      border: ".5px solid gray",
                      width: 70,
                      height: 70,
                      marginRight: 15,
                    }}
                    src="https://i.ibb.co/xDKG2v2/763b185b-b8ae-45d9-b090-dece6bce3abce4f3f2fe-4219-42c3-9c6b-7898fadd2386.jpg"
                  />
                  </Tooltip>
                  <Tooltip title={<b>Rice University Chess Club</b>}>
                    <Avatar
                      style={{ border: ".5px solid gray", width: 70, height: 70 }}
                      src="https://i.ibb.co/k8WDB98/Cix-Fr-Yfv-Rsr-QNPY0-Pki-A-iks2-GFpn0-P1-UDP25.png"
                    />
                  </Tooltip>
                  
                </div>
                
              </div>
          </div>

        </div>

         {/* Policies */}

        <div style={{display:'flex', marginBottom:40, flexDirection:'row', justifyContent:'center', paddingTop:20, paddingBottom:20}}>
          <div style={{ width: "100%", maxWidth: 720 }}>

            <Policies allOpen={true}/>

          </div>
        </div>

      </>

    );

}
