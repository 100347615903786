import React, {useMemo, memo, useEffect} from 'react';
import { getFutureLichessTournaments, getLichessAsyncFutureTournaments } from '../../../Firebase';
import TournamentCard from './TournamentCard';
import RowsLoading from '../../RowsLoading';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Emitter from '../../../Emitter';
import TournamentAsyncCard from '../TournamentsAsync/TournamentAsyncCard';


function Tournaments() {

    const [select, setSelect] = React.useState(1);

    const handleChangeSelect = (event, newValue) => {
        setSelect(newValue);
    };


    useEffect(()=>{


        console.log(select);

    },[select])



    const [loading, setLoading] = React.useState([]);
    const [tournaments, setTournaments] = React.useState({endurance:[], hourly:[], user:[]});
    const [tournamentsAsync, setTournamentsAsync] = React.useState({day:[], week:[]});


    async function init() {
        setLoading(true);
        let ts = [getFutureLichessTournaments(), getLichessAsyncFutureTournaments()]
        await Promise.all(ts).then((d)=>{
            setTournaments(d[0]);
            setTournamentsAsync(d[1]);
        })
        setLoading(false);
    }

    
    React.useEffect(()=>{

        window.scrollTo(0, 0);

        init();

        Emitter.on('TOURNAMENT-JOIN-SUCCESS', () => {
            init();
        })
     
        return () => {Emitter.off('TOURNAMENT-JOIN-SUCCESS')};


    },[])


    function selectTournaments(select){
        if(select == 0) return tournaments.user;
        // if(select == 1) return tournaments.hourly;
        if(select == 1) return tournaments.user;
    }



    function TTabs({select, handleChangeSelect }) {
        

        // TODO change text size

        const render = useMemo(() => {

            return(
                // <Tabs   value={select} onChange={handleChangeSelect} >
                //     <Tab style={{borderTopLeftRadius:5, fontSize:(width < 460)?12:14}} label='Club' />
                //     <Tab style={{borderTopRightRadius:5, fontSize:(width < 460)?12:14}} label='Hourly' />
                // </Tabs>
                <Tabs   value={select} onChange={handleChangeSelect} >
                    <Tab style={{borderTopLeftRadius:5, fontSize:12}} label='Flex Play' />
                    <Tab style={{borderTopLeftRadius:5, fontSize:12}} label='Arena' />
                    {/* <Tab style={{borderTopRightRadius:5, fontSize:12}} label='Hourly' /> */}
                </Tabs>
            )
       
        }, []);
      
        return <>{render}</>;
    }


    function Empty(){

        return (

            <div  style={{display:'flex',flexDirection:'row', alignItems:'center', borderRadius:7, justifyContent:'center'}}>

                <p style={{fontWeight:'bold', fontSize:14}}>None</p>

            </div>

        );


    }

    function TournamentRows({ t }) {
      const render = useMemo(() => {
        let renderedRows = [];
    
        for (let i = 0; i < t.length; i++) {
          renderedRows.push(<TournamentCard key={`tcard-${t[i]['id']}`} tournament={t[i]} />);
        }
    
        if (renderedRows.length > 0) return renderedRows;
        return <><Empty/></>
      }, [t]);
    
      return <> {render}</>;
    }

    function TournamentRowsAsync({ t }) {
        const render = useMemo(() => {
          let renderedRows = [];
      
          for (let i = 0; i < t.length; i++) {
            renderedRows.push(<TournamentAsyncCard key={`tcard-${t[i]['id']}`} tournament={t[i]} />);
          }
      
          if (renderedRows.length > 0) return renderedRows;
          return <><Empty/></>
        }, [t]);
      
        return <>{render}</>;
      }
      
    


    return (

        <div style={{ padding:20}}>

            <div style={{display:'flex', alignItems:'flex-end', marginBottom:20, flexDirection:'row', justifyContent:'space-between'}}>
                {/* <Clock/> */}
                <p></p>
                <TTabs select={select} handleChangeSelect={handleChangeSelect}/>
            </div>

          

            {(loading)?
                <RowsLoading height={125}/>
                    :
                <>
                    {/* <TournamentRowsAsync t={tournamentsAsync['week']}/> */}
                    {/* {((tournamentsAsync.week.length != 0) &&(tournaments.endurance.length > 0 || tournaments.hourly.length > 0 || tournaments.user.length > 0)) && <TextDivider text={'Arena'}/>} */}
                    {/* {(select == 0) && <TournamentRows t={selectTournaments(0)}/>} */}
                    {(select == 0) && <TournamentRowsAsync t={tournamentsAsync['week']}/>}
                    {(select == 1) && <TournamentRows t={selectTournaments(1)}/>}
                </>
            }
          
        </div>

    );

}


export default memo(Tournaments);