import React, {useMemo, memo} from 'react';
import { getMyLichessTournamentsAsync } from '../../../Firebase';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useUser from '../../../hooks/useUser';
import RowsLoading from '../../RowsLoading';
import Emitter from '../../../Emitter';
import TournamentAsyncCard from './TournamentAsyncCard';


function MyTournamentsAsync() {


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [user, auth, userLoading] = useUser();

    const [loading, setLoading] = React.useState([]);
    const [tournaments, setTournaments] = React.useState([]);


    React.useEffect(()=>{
        window.scrollTo(0, 0);
    },[])


    async function init() {
        if(user && auth){
            setLoading(true);
            setTournaments(await getMyLichessTournamentsAsync());
            setLoading(false);
        }
    }


    React.useEffect(()=>{

    
        init();


    },[user, auth])

    React.useEffect(()=>{

        init();

        Emitter.on('TOURNAMENT-JOIN-SUCCESS', () => {
            init();
        })
     
        return () => {Emitter.off('TOURNAMENT-JOIN-SUCCESS')};


    },[])


    function Empty(){

        return (

            <div  style={{display:'flex',flexDirection:'row', alignItems:'center', borderRadius:7, justifyContent:'center'}}>

                <p style={{fontWeight:'bold', fontSize:14}}>None</p>

            </div>

        );


    }


    function TournamentRows({select, t}){

        let upcoming = [];
        let completed = [];

        let data = t.data;

        for(var i = 0; i < data.length; i++){

            let card = <TournamentAsyncCard key={`tcard-${data[i]['id']}`} tournament={data[i]} />;

            if(Date.now() >= data[i]['endDate']){
                completed.push(card);
            } else {
                upcoming.push(card);
            }

        }


        if(completed.length > 0) completed.reverse();
        if (upcoming.length == 0) upcoming = Empty();
        if (completed.length == 0) completed = Empty();

        return(
            <>
                {(select == 0) && upcoming}
                {(select == 1) && completed}
            </>
        )

    }


    function TTabs({select, handleChangeSelect }) {
        

        // TODO change text size

        const render = useMemo(() => {

            return(
              
                <Tabs  style={{ borderRadius:10}} value={select} onChange={handleChangeSelect} >
                    <Tab  style={{fontSize:12}}  color='secondary' label='Active'  />
                    <Tab  style={{fontSize:12}} label='Completed' />
                </Tabs>
            )
       
        }, []);
      
        return <>{render}</>;
    }

    return (

        <div style={{padding:20}}>

            <div style={{display:'flex', marginBottom:20, flexDirection:'row', justifyContent:'right'}}>

            <TTabs select={value} handleChangeSelect={handleChange}/>

            </div>
          

            {loading?
                <RowsLoading height={125}/>
                    :
                    
                <TournamentRows select={value} t={tournaments}/>
            }
     
        </div>

    );

}


export default memo(MyTournamentsAsync);
