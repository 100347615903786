
import { Divider } from '@mui/material';
import React, { useEffect } from 'react';


export default function Podium({awards, showMystery}) {

    console.log(showMystery);

    return (

        <>

            {(awards == {} || !awards )?

            
                <div className={'card'}  style={{ display:'flex',  marginBottom:20, backgroundColor:'white',  flexDirection:'column'}}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'left'}}>
                            <p style={{fontSize:14, padding:0, margin:0}}>Opponents Defeated</p>
                            <p style={{fontSize:12, textAlign:'left', color:'gray', padding:0, margin:0, marginTop:5}}>{awards['a']['username']}</p>
                        </div>
                        <p style={{color:'#907aeb', marginLeft:5, fontSize:14,padding:0, margin:0}}>{awards['a']['opponentsDefeated']}</p>
                    </div>

                    <div style={{marginTop:15, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'left'}}>
                            <p style={{fontSize:14, padding:0, margin:0}}>Games Played</p>
                            <p style={{fontSize:12, textAlign:'left', color:'gray', padding:0, margin:0, marginTop:5}}>{awards['c']['username']}</p>
                        </div>
                        <p style={{color:'#907aeb', marginLeft:5, fontSize:14,padding:0, margin:0}}>{awards['c']['gamesGrinded']}</p>
                    </div>

                    <div style={{marginTop:15, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'left'}}>
                            <p style={{fontSize:14, padding:0, margin:0}}>Biggest Comeback</p>
                            <p style={{fontSize:12, textAlign:'left', color:'gray', padding:0, margin:0, marginTop:5}}>{awards['d']['username']}</p>
                        </div>
                        <p style={{color:'#907aeb', marginLeft:5, fontSize:14,padding:0, margin:0}}>{awards['d']['deficit']}</p>
                    </div>
                


                    <div style={{marginTop:15, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'left'}}>
                            <p style={{fontSize:14, padding:0, margin:0}}>Checkmates</p>
                            <p style={{fontSize:12, textAlign:'left', color:'gray', padding:0, margin:0, marginTop:5}}>{awards['e']['username']}</p>
                        </div>
                        <p style={{color:'#907aeb',marginLeft:5, fontSize:14,padding:0, margin:0}}>{awards['e']['checkmates']}</p>
                    </div>
                
                    <div style={{marginTop:15, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'left'}}>
                            <p style={{fontSize:14, padding:0, margin:0}}>Captured Pieces PPG</p>
                            <p style={{fontSize:12, textAlign:'left', color:'gray', padding:0, margin:0, marginTop:5}}>{awards['f']['username']}</p>
                        </div>
                        <p style={{color:'#907aeb',marginLeft:5, fontSize:14,padding:0, margin:0}}>{awards['f']['score']}</p>
                    </div>

               {showMystery &&<>  <Divider style={{marginTop:15}} />

                    <div style={{marginTop:12, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'left'}}>
                            <p style={{fontSize:14, padding:0, margin:0}}>Mystery Winner</p>
                            {/* <p style={{fontSize:12, textAlign:'left', color:'gray', padding:0, margin:0, marginTop:5}}>{awards['c']['username']}</p> */}
                        </div>
                        <p style={{marginLeft:5, fontSize:14,padding:0, margin:0}}>{awards['b']['username']}</p>
                    </div></>  
                }
                
                
                
                </div>
                
                
            :

            <></>}


        </>

    );


}

