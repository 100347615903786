import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuDrawer from './MenuDrawer';

export default function MenuButton() {

    const [stateDrawer, setStateDrawer] = React.useState({top: false,left: false,bottom: false, right: false});

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setStateDrawer({ ...stateDrawer, [anchor]: open });
    };

    return (
        <>
            <IconButton color='inherit' onClick={toggleDrawer('right', true)}>
                <MenuIcon  />
            </IconButton>
            <MenuDrawer open={stateDrawer['right']} onClose={toggleDrawer('right', false)}/>
        </>
    );
}
