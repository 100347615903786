import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import logo from '../art/world.png'
import { useRecoilValue } from 'recoil';
import { atom_blockAppLocation } from '../Atoms';

function RegionUnsupportedDialog() {
    
    const open = useRecoilValue(atom_blockAppLocation);

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={()=>{}}
            PaperProps={{
                style:{
                borderTopRightRadius:7,
                borderTopLeftRadius:7,
                borderBottomLeftRadius:7,
                borderBottomRightRadius:7,
                padding:10
                }
            }} 
        >
            <DialogTitle>Unsupported Region</DialogTitle>
            <DialogContent>
                <DialogContentText style={{marginTop:-10}}>
                    We're sorry, but this app is not supported in your region.
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    <div style={{marginTop:20, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <img src={logo} style={{width:'100%', height:'auto', maxWidth:400}}/>
                    </div>
                </DialogContentText>
            </DialogContent>
       
        </Dialog>
    )
}

export default RegionUnsupportedDialog;
