import React, { useEffect } from "react";
import ActiveUsers from "../../ActiveUsers";
import TournamentIcon from "../Icons/TournamentIcon";
import { crossTable, exportGame } from "../../../Lichess/api";
import { Clock } from "@mui/x-date-pickers/ClockPicker/Clock";
import LastUpdate from "./LastUpdate";
import { chessMatchOutcome } from "../../../Lichess/odds";
import ScoreTable from "./ScoreTable";
import DuelIcon from "../Icons/DuelIcon";




export default function GameInfo({lastUpdate, challengeId,}){

    const [loading, setLoading] = React.useState(false);
    const [isEror, setIsError] = React.useState(false);

    const [perf, setPerf] = React.useState(null);
    const [variant, setVariant] = React.useState(null);

    const [clockTime, setClockTime] = React.useState(null);
    const [clockIncrement, setClockIncrement] = React.useState(null);

    const [whiteUsername, setWhiteUsername] = React.useState(null);
    const [blackUseranme, setBlackUsername] = React.useState(null);

    const [whiteScore, setWhiteScore] = React.useState(null);
    const [blackScore, setBlackScore] = React.useState(null);

    const [ratingWhite, setRatingWhite] = React.useState(null);
    const [ratingBlack, setRatingBlack] = React.useState(null);

    const [probabilityWhite, setProbabilityWhite] = React.useState(null);
    const [probabilityBlack, setProbabilityBlack] = React.useState(null);
    const [probabilityDraw, setProbabilityDraw] = React.useState(null);


    async function init(){

        setLoading(true);

        let game = await exportGame(challengeId);

        if (game){


            setPerf(game['perf']);
            setVariant(game['variant']);

            setClockTime(game['clock']['initial']/60)
            setClockIncrement(game['clock']['increment'])

            let usernameWhite = game['players']['white']['user']['id'] || null;
            let usernameBlack = game['players']['black']['user']['id'] || null;


            if (usernameWhite && usernameBlack){

                setWhiteUsername(usernameWhite);
                setBlackUsername(usernameBlack);

                let scores = await crossTable(usernameWhite, usernameBlack);

                if (scores){
                    setWhiteScore(scores['users'][usernameWhite]);
                    setBlackScore(scores['users'][usernameBlack]);
                }

                setRatingWhite(game['players']['white']['rating'] );
                setRatingBlack(game['players']['black']['rating']);
                
            }
            

        }


        setLoading(false);


    }


    useEffect(()=>{

        let outcomes =  chessMatchOutcome(ratingWhite, ratingBlack, whiteScore, blackScore, 35, false);



        setProbabilityWhite(outcomes.winProbability);
        setProbabilityBlack(outcomes.lossProbability);
        setProbabilityDraw(outcomes.drawProbability);


    },[ratingWhite, ratingBlack, whiteScore, blackScore])



    React.useEffect(()=>{

        init();

    },[challengeId])




    return (


        <>

        <ScoreTable whiteUsername={whiteUsername} blackUsername={blackUseranme} whiteScore={whiteScore} blackScore={blackScore} whiteProb={probabilityWhite} blackProb={probabilityBlack} drawProb={probabilityDraw} />

        {loading && <div className="c-skeleton" style={{height:120, maxWidth:690, width:'100%'}}>
            
            
            
            
            
        </div>}

        {!loading && <div className="card" style={{borderRadius:5, maxWidth:690, width:'100%'}}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:"space-between"}}>

                <div style={{display:'flex', flexDirection:'row', marginBottom:20}}>
                    <DuelIcon size={40}/>

                    <div style={{marginLeft:15, display:'flex', flexDirection:'column', alignItems:'flex-start'}}>

                        <p style={{padding:0, margin:0}}>{challengeId}</p>
                        <p style={{padding:0, margin:0, color:'gray', fontSize:14}}>{`${clockTime}+${clockIncrement}`}</p>
                    </div>
                   


                </div>

                <ActiveUsers show={true}/>


            </div>

          

         
            
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"space-between"}}>
                <p></p>
                <LastUpdate timestamp={lastUpdate} />
            </div>
            
        </div>
}
        </>


    )



}