import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';


export default function ErrorCard({height}){

    return(
        <div className='card' style={{ display:'flex', alignItems:'center', height:height, flexDirection:'row', justifyContent:'center', backgroundColor:'white',}}>
            <Button onClick={()=>window.location.reload()} color='error' variant='contained'>
                <RefreshIcon style={{marginRight:5}}/>
                <b>ERROR</b>
            </Button>
        </div>
    )

}
