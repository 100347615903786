import React from 'react';
import Policies from './Policies';


export default function PoliciesPage(){


    return(
        <div style={{width:'100%', display:"flex", flexDirection:'row', justifyContent:'center'}}>

            <div style={{maxWidth:800, marginTop:20, marginBottom:20}}>
        <Policies allOpen={true}/>
        </div>
        </div>
    );
}
