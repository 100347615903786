import { Divider, IconButton, MenuItem, Switch, TextField } from '@material-ui/core';
import React, { useState }  from 'react';
import { numberWithCommas } from '../../../utils';
import AddIcon from '@mui/icons-material/Add';
import { Button, InputAdornment } from '@mui/material';
import { options } from '../../../Lichess/wagerForm.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import ClearIcon from '@mui/icons-material/Clear';
import { atom_createLichessWagerForm, atom_createLichessWagerFormConfirm, atom_lichessOauthToken } from '../../../Atoms';
import LichessButton from '../LichessButton';
import RemoveIcon from '@mui/icons-material/Remove';
import { createLichessWagerSlip } from '../../../Firebase';
import { useNavigate } from "react-router-dom";
import { Conditions } from './Conditions';
import InfoBar from '../../InfoBar';
import WarningBar from '../../WarnningBar';



function CreateWager() {

    const navigate = useNavigate();


    const lichessOauthToken = useRecoilValue(atom_lichessOauthToken);
    const [wagerForm, setTournamentForm] = useRecoilState(atom_createLichessWagerForm);
    const [confirm, setConfirm] = useRecoilState(atom_createLichessWagerFormConfirm);

    const [creating, setCreating] = useState(false);

    function resetForm(){
        let tf = {...options.DEFAULT}
        setTournamentForm(tf);
    }
        
    function handleClickCancel(){
        resetForm();
        setConfirm(false);
        window.scrollTo(0, 0);
    }

    async function handleClickCreate(){

        if (creating) return;

        setCreating(true);
        let created = await createLichessWagerSlip(lichessOauthToken, wagerForm);
        setCreating(false);

        if(created) {
            handleClickCancel();
            navigate('/myWagers');
        } else {

        }
        // setTournamentForm(options.DEFAULT)

    }
 

    // Handle form changes

  


    const handleChangeVariant = (event) => {
        var tf = {...wagerForm}
        tf['variant'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeClock = (event) => {
        var tf = {...wagerForm}
        tf['clockTime'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeIncrement = (event) => {
        var tf = {...wagerForm}
        tf['clockIncrement'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeMinRating = (event) => {
        var tf = {...wagerForm}
        tf['minRating'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeMaxRating = (event) => {
        var tf = {...wagerForm}
        tf['maxRating'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeColor = (event) => {
        var tf = {...wagerForm}
        tf['color'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangeEntryTickets = (val) => {

        if (val > 0){ 
            var tf = {...wagerForm}
            tf['tickets'] = val
            setTournamentForm(tf);
        }

    }

    const handleChangeExpire = (event) => {
        var tf = {...wagerForm}
        tf['expire'] = event.target.value;
        setTournamentForm(tf);
    }

    const handleChangePrivate = (event) => {
        var tf = {...wagerForm}
        tf['private'] = event.target.checked;
        setTournamentForm(tf);
    }


    // init form with time
    React.useEffect(()=>{

        if (wagerForm == null) {
            resetForm();
        } 
        // else if(wagerForm.startDate == 0){
        //     resetForm();
        // }

    },[])



    function ChangeTickets(){

        return(
            <div className='flexRowCentered' >
               <IconButton disabled={confirm} onClick={()=>handleChangeEntryTickets(wagerForm.tickets -1)} style={{width:25, height:25, marginRight:5}}>
                    <RemoveIcon />
                </IconButton>
                
                <IconButton disabled={confirm} onClick={()=>handleChangeEntryTickets(wagerForm.tickets + 1)} style={{width:25, height:25}}>
                    <AddIcon />
                </IconButton>
            </div> 

        )

    }

    function Amount(multiplier){

         return '$' + numberWithCommas(multiplier * wagerForm.tickets);

    }

    


    return (
        <div style={{padding:20, marginBottom:60, display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <div style={{ display:'flex', maxWidth:850, flexDirection:'column'}}>
            
                <p style={{fontSize:22, fontWeight:'bold'}}>Create Wager</p>

                <Divider  />

                <div  >

                    <TextField  disabled={confirm} select value={wagerForm.variant} onChange={handleChangeVariant} style={{width:152, marginTop:20, marginRight:10}} label='Variant' variant='outlined' >
                        {options.VARIANTS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={wagerForm.clockTime} onChange={handleChangeClock} style={{width:152, marginTop:20, marginRight:10}} label='Clock' variant='outlined' >
                        {options.CLOCK.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={wagerForm.clockIncrement} onChange={handleChangeIncrement} style={{width:152, marginTop:20, marginRight:10}} label='Increment' variant='outlined' >
                        {options.INCREMENT.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>


                    <TextField   disabled={confirm} select value={wagerForm.minRating} onChange={handleChangeMinRating} style={{width:152, marginTop:20, marginRight:10}} label='Min Rating' variant='outlined' >
                        {options.MIN_RATING.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={wagerForm.maxRating} onChange={handleChangeMaxRating} style={{width:152, marginTop:20, marginRight:10}} label='Max Rating' variant='outlined' >
                        {options.MAX_RATING.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField  disabled={confirm} select value={wagerForm.color} onChange={handleChangeColor} style={{width:152, marginTop:20, marginRight:10}} label='Color' variant='outlined' >
                        {options.COLOR.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </div>

                            
                
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}> 
            

                    <TextField
                    disabled={confirm}
                        value={'Private Match'}
                        style={{marginTop:20, marginBottom:20, caretColor:'transparent'}}
                        variant='outlined'
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                
                        <Switch
                        onChange={handleChangePrivate}
                        checked={wagerForm.private}
                        color='primary'
                        inputProps={{ 'aria-label': 'controlled' }}
                        />
                            </InputAdornment>
                        ),
                        }}
        
                    />

                    <div style={{minHeight:24, padding:15, display:'flex', flexDirection:'row', alignItems:'center', border:'1px solid #c4c4c4', borderRadius:5}}>
                        <p style={{ margin:0, padding:0}}>
                        {'Private wagers will not be displayed in the lobby. Share the match link with your competitor.'}
                        </p>
                    </div>

                    {/* <InfoBar pText={'Private wagers will not be displayed in the lobby. Share the match link with your competitor.'}/> */}




                </div>
             

   
                <div style={{display:'flex', marginTop:20, alignItems:'flex-end', flexDirection:'row', justifyContent:'space-between'}}>

                    <p style={{fontWeight:'normal', fontSize:18}}>Expiration Time</p>

                </div>
                <Divider style={{marginBottom:20, }}/>

                <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}> 
                    <TextField  disabled={confirm} style={{marginBottom:20}} select value={wagerForm.expire} onChange={handleChangeExpire}  variant='outlined' >
                        {options.EXPIRE.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                              
                    <div style={{minHeight:24, padding:15, display:'flex', flexDirection:'row', alignItems:'center', border:'1px solid #c4c4c4', borderRadius:5}}>
                        <p style={{ margin:0, padding:0}}>
                        {'The wager must be accepted within the selected amount of time.'}
                        </p>
                       
                    </div>
                    {/* <InfoBar pText={'The wager must be accepted within the selected amount of time.'}/> */}


            

                </div>


                

              
                


                <div style={{display:'flex', marginTop:20, alignItems:'flex-end', flexDirection:'row', justifyContent:'space-between'}}>

                <p style={{fontWeight:'normal', fontSize:18}}>Amount</p>
                {/* <p style={{marginRight:5,  fontWeight:'bold', color:'#36AA3E', fontSize:18}}>{Amount(1)}</p> */}

                </div>
                <Divider style={{marginBottom:20}}/>


                <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}> 
                <TextField disabled={confirm}   label='Tickets'   id="outlined-number"
                    value={wagerForm.tickets}
                    style={{ caretColor:'transparent', marginRight:5, width:'100%'}}
                    variant='outlined'
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>
                            <ChangeTickets/>
                            </InputAdornment>,
                    }}
                    InputLabelProps={{
                        shrink: true,


                }}/>

                </div>
             
               
                <div style={{marginTop:40, display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <div style={{width:'100%', maxWidth:680, overflow:'hidden'}}>
                    <div className='wager-card' >
                        <div style={{width:'50%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                            <p style={{color:'#ff1744', textAlign:'center', fontSize:20, fontWeight:800, margin:0, padding:0, marginBottom:10}}>{Amount(1)}</p>
                            <p style={{fontWeight:500, color:'#6c6d6f', textAlign:'center', margin:0, padding:0}}>Risk</p>
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div style={{width:'50%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                            <p style={{color:'#36AA3E', textAlign:'center', fontSize:20, fontWeight:800, margin:0, padding:0, marginBottom:10}}>{Amount(2)}</p>
                            <p style={{fontWeight:500, color:'#6c6d6f', textAlign:'center', margin:0, padding:0 }}>Win</p>
                        </div>

                    </div>
                    </div>
                </div>



                <div style={{marginTop:40, display:'flex', alignItems:'flex-end', flexDirection:'row', justifyContent:'space-between'}}>
                    <p style={{fontWeight:'normal', fontSize:18}}>Confirm</p>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                        {confirm && <IconButton onClick={()=>setConfirm(false)}  variant='contained'>
                            <ClearIcon/>
                        </IconButton>}
                    </div>

                 
                </div>

                   



                <Divider style={{marginBottom:20}}/>


                <div style={{padding:15, border:'1px solid #c4c4c4', borderRadius:5}}>
                    <Conditions/>

                    {!confirm ? 
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            {/* <Button  onClick={handleClickCancel} style={{marginRight:10}} color='primary' variant='outlined'><b>Cancel</b></Button> */}
                           
                            <Button  onClick={handleClickCancel} style={{marginRight:5, height:40, width:90}} variant='outlined'>
                                <b>Reset</b>
                            </Button> 
                           
                            <Button onClick={()=>setConfirm(true)}  style={{height:40, width:90}}   color='primary' variant='contained'>
                                <b>Agree</b>
                            </Button>
                        </div>
                            :
                        <>
                              
                            <LichessButton/>

                            <Button  onClick={handleClickCreate} disabled={lichessOauthToken==''}  style={{width:'100%', height:50, marginTop:10}} variant='contained'>
                                <AddIcon style={{marginRight:5}}/>
                                <b>CREATE</b>
                            </Button> 
      

                        
                      
                        </>
                    
                    }


                </div>
                
               


            </div>
        </div>


    );

}


export default CreateWager;