
import React from 'react';
import { Button, Dialog } from '@mui/material';
import { joinLichessTournament } from '../../../Firebase';
import LichessButton from '../LichessButton';
import { atom_lichessOauthToken } from '../../../Atoms';
import { useRecoilValue } from 'recoil';


export default function JoinTournamentDialogue({tournament, openJoinDialogue, setOpenJoinDialogue}) {

  const lichessOauthToken = useRecoilValue(atom_lichessOauthToken);
 
  async function handleJoinTournamnet(){
    setOpenJoinDialogue(false);
    let joined = await joinLichessTournament(tournament['id'], lichessOauthToken);
    // if (joined) window.location.reload()
  }


  function AgreementText(){
    
    return (
      <p style={{padding:0, margin:0}}>

        <b>Tournament Enroll </b>- You must play at least 1 game to be eligible for payouts. 
      
        Tournaments that contain <a href='/#anti-cheatinng-policy'>anti-cheating violations</a> or valid disputes will result in a refund. 
        If the minimum player requirement is not met, or no games are played, a refund will be issued. 
        By clicking "Join," you agree to all of Amped Chess's gaming <a href='/policies'>policies</a> .

        {/* <b>WARNING</b> - Amped Chess does not take responsibility for players who misrepresent their skill level or who have unauthorized third parties make moves for them. */}



      </p>
    )

  }

  function handleClose(){
    setOpenJoinDialogue(false);
  }

  return (

    
        <Dialog fullWidth  open={openJoinDialogue} onClose={handleClose} 
        
            PaperProps={{
                style:{
                  borderTopRightRadius:7,
                  borderTopLeftRadius:7,
                  borderBottomLeftRadius:7,
                  borderBottomRightRadius:7,
                }
            }} >

            <div style={{padding:20, display:'flex', flexDirection:'column', alignItems:'center'}}>

                <AgreementText/>

                <div style={{width:'100%', marginTop:20}}>

                <LichessButton/>

                <Button  onClick={handleJoinTournamnet} disabled={lichessOauthToken==''}  style={{width:'100%', height:50, marginTop:10}} variant='contained'>
                  <b>JOIN</b>
                </Button> 
  
                </div>

            </div>

        </Dialog>

  );


}

