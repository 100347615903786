import { LazyLoadImage } from "react-lazy-load-image-component";
import {memo} from 'react';
import white from '../../../art/white.png';
import black from '../../../art/black.png';

function ColorsIcon({color, size}){

    if (color == 'white') return <img src={white} width={size} height={size} />
    if (color == 'black') return <img src={black} width={size} height={size} />

}

export default memo(ColorsIcon);