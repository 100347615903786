import React from 'react';
import { useRecoilState } from 'recoil';
import { atom_lichessOauthToken, atom_paymentFees, atom_payoutFees } from './Atoms';
import Emitter from './Emitter';
import { getAmpedChessPayoutFees, getLichessPaymentFees } from './Firebase';


export default function StateModifier(){

    const [lichessOauthToken, setLichessOauthToken] = useRecoilState(atom_lichessOauthToken);
    const [paymentFees, setPaymentFees] = useRecoilState(atom_paymentFees);
    const [payoutFees, setPayoutFees] = useRecoilState(atom_payoutFees);

    function initLichessOauth(){

        let lichessAuthObj = localStorage.getItem('oauth2authcodepkce-state');

        if(lichessAuthObj){

            lichessAuthObj = JSON.parse(lichessAuthObj);

            if (lichessAuthObj.accessToken) {

                let token = lichessAuthObj.accessToken.value;
                setLichessOauthToken(token);

            }
           

        } else {
            setLichessOauthToken('');
        }


    }

    async function initPaymentFees(){
        let fees = await getLichessPaymentFees();
        if (fees != -1) setPaymentFees(fees);
    }

    async function initPayoutFees(){
        let fees = await getAmpedChessPayoutFees();
        if (fees != -1) setPayoutFees(fees);
    }

    React.useEffect(()=>{

        initLichessOauth();
        initPaymentFees();
        initPayoutFees();

        Emitter.on('LICHESS-OAUTH-SET', () => {
            initLichessOauth();
        })
    
        return ()=>{
            Emitter.off('LICHESS-OAUTH-SET');
        }
          
        
    },[])

    return(<></>);

}