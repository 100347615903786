// export function chessMatchOutcome(whiteElo, blackElo, scoreWhite, scoreBlack, whiteAdvantage = 35) {
//     // Adjust White's ELO for the inherent advantage of playing white
//     const adjustedWhiteElo = whiteElo + whiteAdvantage;

//     // Calculate the expected score for White
//     const ratingDifference = adjustedWhiteElo - blackElo;
//     let expectedScoreWhite = 1 / (1 + Math.pow(10, -ratingDifference / 400));

//     // Incorporate historical performance
//     const totalGames = scoreWhite + scoreBlack;
//     if (totalGames > 0) {
//         const performanceFactor = (scoreWhite - scoreBlack) / totalGames;
//         expectedScoreWhite = adjustForHistory(expectedScoreWhite, performanceFactor);
//     }

//     // Normalize the probabilities to ensure they sum up to 1
//     const normalizedScores = normalizeScores(expectedScoreWhite);

//     return {
//         winProbability: normalizedScores.win,
//         drawProbability: normalizedScores.draw,
//         lossProbability: normalizedScores.loss
//     };
// }

// function adjustForHistory(expectedScore, performanceFactor) {
//     // Adjust the expected score based on historical performance
//     // This factor can be fine-tuned for more accurate predictions
//     const adjustmentFactor = 0.05; // Example value, can be adjusted
//     return Math.max(0, Math.min(1, expectedScore + (adjustmentFactor * performanceFactor)));
// }

// function normalizeScores(expectedScoreWhite) {
//     // Estimate draw probability - this can be adjusted
//     const k = 0.5; // Adjust based on empirical data
//     let drawProbability = k * Math.min(expectedScoreWhite, 1 - expectedScoreWhite);

//     // Ensure total probability sums to 1
//     if (expectedScoreWhite + drawProbability > 1) {
//         drawProbability = 1 - expectedScoreWhite;
//     }

//     const winProbability = expectedScoreWhite;
//     const lossProbability = 1 - winProbability - drawProbability;

//     return {
//         win: winProbability,
//         draw: drawProbability,
//         loss: lossProbability
//     };
// }
 

export function chessMatchOutcome(whiteElo, blackElo, scoreWhite, scoreBlack, whiteAdvantage = 35, showVegasOdds = false) {
    let winProbability, drawProbability, lossProbability;

    const totalGames = scoreWhite + scoreBlack;

    // Check if historical score is significant
    if (scoreWhite > 15 || scoreBlack > 15) {
        // Calculate probabilities based purely on historical performance
        let historicalWinProbability = scoreWhite / totalGames;
        let historicalLossProbability = scoreBlack / totalGames;

        // Adjust for white advantage
        historicalWinProbability += whiteAdvantage / 1000; // Example adjustment, can be tuned
        historicalLossProbability = Math.max(0, historicalLossProbability - whiteAdvantage / 1000); // Ensuring no negative probabilities

        // Recalculate draw probability
        drawProbability = 1 - historicalWinProbability - historicalLossProbability;
        winProbability = historicalWinProbability;
        lossProbability = historicalLossProbability;
    } else {
        // Use Glicko-2 rating difference for calculation
        const adjustedWhiteElo = whiteElo + whiteAdvantage;
        const ratingDifference = adjustedWhiteElo - blackElo;
        let expectedScoreWhite = 1 / (1 + Math.pow(10, -ratingDifference / 400));

        if (totalGames > 0) {
            const performanceFactor = (scoreWhite - scoreBlack) / totalGames;
            expectedScoreWhite = adjustForHistory(expectedScoreWhite, performanceFactor);
        }

        // Normalize the probabilities
        const normalizedScores = normalizeScores(expectedScoreWhite);
        winProbability = normalizedScores.win;
        drawProbability = normalizedScores.draw;
        lossProbability = normalizedScores.loss;
    }

    // Round probabilities to one decimal place and convert to percentages
    const roundedWin = roundToOneDecimal(winProbability * 100);
    const roundedDraw = roundToOneDecimal(drawProbability * 100);
    const roundedLoss = roundToOneDecimal(100 - roundedWin - roundedDraw); // Ensure sum is 100

    if (showVegasOdds) {
        return {
            winProbability: convertToVegasOdds(winProbability),
            drawProbability: convertToVegasOdds(drawProbability),
            lossProbability: convertToVegasOdds(lossProbability)
        };
    }

    return {
        winProbability: roundedWin,
        drawProbability: roundedDraw,
        lossProbability: roundedLoss
    };
}

// Convert probability to Vegas-style odds
function convertToVegasOdds(probability) {
    if (probability >= 0.5) {
        return "-" + Math.round(100 / (probability / (1 - probability)));
    } else {
        return "+" + Math.round(100 / ((1 - probability) / probability));
    }
}


function adjustForHistory(expectedScore, performanceFactor) {
    const adjustmentFactor = 0.05;
    return Math.max(0, Math.min(1, expectedScore + (adjustmentFactor * performanceFactor)));
}

function normalizeScores(expectedScoreWhite) {
    const k = 0.5;
    let drawProbability = k * Math.min(expectedScoreWhite, 1 - expectedScoreWhite);

    if (expectedScoreWhite + drawProbability > 1) {
        drawProbability = 1 - expectedScoreWhite;
    }

    const winProbability = expectedScoreWhite;
    const lossProbability = 1 - winProbability - drawProbability;

    return {
        win: winProbability,
        draw: drawProbability,
        loss: lossProbability
    };
}

function roundToOneDecimal(num) {
    return parseFloat(num.toFixed(1));
}

