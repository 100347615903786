


export function Conditions(){


    return(

        <p style={{margin:0, padding:0, marginBottom:30}}>

            If this wager is accepted, you must join the created challenge within the remaining expiration time. There is a 5 minute grace period. If either player has not yet moved after the grace period, clocks will start automatically. Players who routinely no-show or cancel the challenge will be banned.
            
            <br/><br/>
            No-shows and draws will result in a refund. 

            <br/><br/>

            Matches that contain <a href='/#anti-cheatinng-policy'>anti-cheating violations</a> or valid disputes will result in a refund.


            <br/><br/>
            {/* <b>WARNING - </b>Wagers accepted at your own risk. Amped Chess does not take responsibility for players who misrepresent their skill level or who have unauthorized third parties make moves for them. Amped Chess recommends alternative variants, such as Chess960 and fast time controls, to limit bad actors. */}
            By clicking "Create," you agree to all of Amped Chess's payout and gaming <a href='/policies'>policies</a>.           

        
        </p>





    )
}