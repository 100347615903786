const logo = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/logo.gif?alt=media&token=dc4531cd-d549-4e4b-b98b-25e12de52c0f'



export default function ChessLogoLoading({size}){

    return(
        <img src={logo} width={size} height={size} />

    );

}


// const logo = 'https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/logo.gif?alt=media&token=dc4531cd-d549-4e4b-b98b-25e12de52c0f';

// export default function ChessLogoLoading({size}){

//     return(
//         <img src={logo} width={size} height={size} />

//     );

// }
