import React, { useState } from 'react';
import { getMyLichessWagers } from '../../../Firebase';
import WagerRow from './WagerRow';
import RowsLoading from '../../RowsLoading';
import { Divider } from '@material-ui/core';
import ErrorCard from '../ErrorCard';


export default function MyWagers() {

    const [loading, setLoading] = useState(false);
    const [wagers, setWagers] = useState([]);

    async function init() {
        setLoading(true);
        setWagers(await getMyLichessWagers());
        setLoading(false);
    }

    React.useEffect(()=>{

        window.scrollTo(0, 0);

        init();

    },[])




    function Empty(){

        return(

            <div  style={{display:'flex',flexDirection:'row', alignItems:'center', borderRadius:7, justifyContent:'center'}}>
                
                <p style={{fontWeight:'bold', fontSize:14}}>None</p>

            </div>

        );

    }


    function WagerRows({w}){

        if (!w || 1 == -1) return <ErrorCard/>;

        let render = [];

        for(var i = 0; i < w.length; i++){

            render.push(<WagerRow key={`wrow-${w[i]['id']}`} wager={w[i]}/>)

        }

        if (render.length == 0){

           render = <Empty/>
        }

        return(
            <>
                {render}
            </>
        )

    }


    return (

        <div style={{padding:20}}>   

            <p style={{ fontSize:22, fontWeight:'bold'}}>My Wagers</p>

            <Divider style={{marginBottom:20}}  />

            {loading?
                <RowsLoading height={100}/>
                    :
                <WagerRows w={wagers}/>

            }
      
        </div>

    );

}

