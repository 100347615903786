import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FaDiscord, FaTwitter, FaReddit, FaInstagram} from 'react-icons/fa'
import { Divider, IconButton } from '@mui/material';
import PaypalLogo from './PaypalLogo';
import { openInNewTab } from '../utils';
import { useFooterStyles } from './MuiStyles';
import GPayLogo from './GPayLogo';


export default function Footer() {

    const classes = useFooterStyles();

    let twitterLink = 'https://twitter.com/amped_chess';
    let discordInvite = 'https://discord.gg/trcb7DGXGS';
    let redditLink = 'https://www.reddit.com/user/ampedchess';
    let instagramLink = 'https://www.instagram.com/ampedchess/';

    return (


        <footer className={classes.footer}>
       
            <div  className={classes.topRow} >

                <div className={classes.topRowLeft} >
                    <LockOutlinedIcon  style={{width:18, height:18, marginRight:5}}/>
                    <p className={classes.secureText}>SSL Secured</p>
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'right', alignItems:'center'}}>
                    <GPayLogo width={75}/>
                    <PaypalLogo width={75}/>


                </div>

            </div>

            <Divider/>

            <div className={classes.body}>

                <div className={classes.socialRow}>
                    <IconButton onClick={()=>openInNewTab(discordInvite)} style={{marginRight:5}}>
                        <FaDiscord/>
                    </IconButton>
                    <IconButton onClick={()=>openInNewTab(twitterLink)}>
                        <FaTwitter/>
                    </IconButton>
                    <IconButton onClick={()=>openInNewTab(redditLink)}>
                        <FaReddit/>
                    </IconButton>
                    <IconButton onClick={()=>openInNewTab(instagramLink)}>
                        <FaInstagram/>
                    </IconButton>
                </div>

                <div className={classes.pagesRow} >
                    <Link to='/support#support'>
                        <p className={classes.pageText}>Support</p>
                    </Link>
                    <p className={classes.pageText} style={{marginLeft:10, marginRight:10}}> | </p>
                    <Link to="/support#contact">
                    <p className={classes.pageText}>Contact</p>
                    </Link>
                </div>

                <p className={classes.companyText}>© 2022 Amped Chess LLC</p>
                {/* <p className={classes.versionText}>v1.1.1</p> */}
        
            </div>

        </footer>

    );
}
