import React, { useEffect, useState } from 'react';
import firebase from '../FirebaseClient'; // Adjust the import path as needed
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AvatarStack from './AvatarStack';

const ActiveUsers = ({show}) => {
    const [onlineUsers, setOnlineUsers] = useState([]);
   
    useEffect(() => {
        const usersRef = firebase.database().ref('/users');

        const onValueChange = usersRef.on('value', snapshot => {
            if (snapshot.exists()) {
                const usersData = snapshot.val();
                const onlineUserUids = Object.keys(usersData).filter(uid => usersData[uid].online);
                setOnlineUsers(onlineUserUids);
            } else {
                setOnlineUsers([]); // No online users found
            }
        }, error => {
            console.error('Firebase Data Error:', error); // Log any errors
        });

        // Cleanup function to unsubscribe from the listener
        return () => {
            console.log('Unsubscribing from the listener');
            usersRef.off('value', onValueChange);
        };
    }, []);


    useEffect(()=>{


    },[onlineUsers])

    return (

        <>
        
        
            {show && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
                <p style={{ padding: 0, margin: 0, marginRight: 5, color: 'black', fontSize: 14, fontWeight: 'bold' }}>{String(onlineUsers.length)}</p>
                <PersonIcon  style={{color:"black"}} />
                {/* <div style={{top:'25%', left:0, position:'fixed'}}>
                    <AvatarStack  height={200} spacing={-.2} avatars={onlineUsers} avatarSize={40}  maxCount={3} orientation='vertical' />
                </div> */}
            </div>}
        
        </>
       
    );
};

export default ActiveUsers;