import rainbow from '../art/rainbowSpinner.svg';



export default function CustomCircularProgress({size}){

    return(
        <img src={rainbow} width={size} height={size} />

    );

}
