
import React from 'react';
import { Button, Dialog, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { beginLichessTournamentPayout } from '../../../Firebase';
import {Divider} from '@mui/material';
import { useLichessTournamentCardStyles } from '../../MuiStyles';
import PaypalLogo from '../../PaypalLogo';
import { numberWithCommas } from '../../../utils';
import { atom_payoutFees } from '../../../Atoms';
import { useRecoilValue } from 'recoil';
import { countryCodeToFlag } from '../../../utils';
import PhoneIcon from '@mui/icons-material/Phone'; // Import the phone icon
import GPayLogo from '../../GPayLogo';

export default function PayoutDialogue({payout, open, setOpen}) {

  const classes = useLichessTournamentCardStyles();


  const [confirm, setConfirm] = React.useState(false);



  const payoutFees = useRecoilValue(atom_payoutFees);





  const [wagerFee, setWagerFee] = React.useState(payoutFees['wagerFee']);
  const [tournnamentFee, setTournamentFee] = React.useState(payoutFees['tournamentFee']);

  
  const [email, setEmail] = React.useState('');


  const [countryCode, setCountryCode] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');


  function handleClose(){
    setEmail('');
    setCountryCode('');
    setPhoneNumber('');
    setConfirm(false);
    setOpen(false);

  }

  async function handleRedeem(){
    handleClose();
    // await beginLichessTournamentPayout(payout['id'], email);
    await beginLichessTournamentPayout(payout['id'],email, countryCode, phoneNumber);
  }

  function RecievedValue(){

    if (payout.waiveLeagueFee == true){
      return '$' + numberWithCommas(Number(((1-2/100)*payout['value']).toFixed(2)));
    } 

    if (payout.type == 'wager'){
      return '$' + numberWithCommas(Number(((1-wagerFee/100)*payout['value']).toFixed(2)));
    }

    if (payout.type == 'tournament'){
      return '$' + numberWithCommas(Number(((1-tournnamentFee/100)*payout['value']).toFixed(2)));
    }

  }

  function PlatformFee(){

    if (payout.waiveLeagueFee == true){
      return '$' + numberWithCommas(Number((2/100)*payout['value']).toFixed(2));
    } 

    if (payout.type == 'wager'){
      return '$' + numberWithCommas(Number(((wagerFee/100)*payout['value']).toFixed(2)));
    }

    if (payout.type == 'tournament'){
      return '$' + numberWithCommas(Number(((tournnamentFee/100)*payout['value']).toFixed(2)));
    }

  }

  function validateEmail(email) {
    // Simple regular expression for basic email structure
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(email);
}



  return (

    
        <Dialog fullWidth  open={open} onClose={handleClose} 
        
            PaperProps={{
                style:{
                borderTopRightRadius:7,
                borderTopLeftRadius:7,
                borderBottomLeftRadius:7,
                borderBottomRightRadius:7,
                }
            }} >

              <div   style={{padding:15}}>

                  <div className='logo-wrap'>
                    <div className='logo-wrap-content' style={{marginBottom:5}}>
   
                      <div style={{display:'flex',   height:50,  flexDirection:'row', alignItems:'flex-end', justifyContent:'space-between'}}>
                          <p style={{fontSize:14, fontWeight:'bold'}}>Payout</p>
                          <div style={{marginBottom:8}} ><PaypalLogo width={80}/></div>
                      </div>


                      <Divider/>
                      <div style={{height:90, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start'}}>
              
                      <div style={{height:30,  width:'100%', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-between'}}>

                          <p style={{fontSize:14, fontWeight:'bold'}}>Value</p>
                          <p style={{fontSize:14, fontWeight:'bold'}}>{'$' + numberWithCommas(payout['value'])}</p>
                      </div>
                      <div style={{height:30,  width:'100%', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-between'}}>
                          <p style={{fontSize:14, fontWeight:'bold'}}>{(payout.waiveLeagueFee)?'Processor Fee':`Fee`}</p>
                          {(payout.type == 'wager') &&  <p style={{fontSize:14, fontWeight:'bold'}}>{PlatformFee()}</p>}
                      </div>

                      </div>

                      <Divider/>


                      <div style={{height:50,   width:'100%', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-between'}}>
                          <p style={{fontSize:14, fontWeight:'bold'}}>Recieved</p>
                          <p style={{fontSize:14, fontWeight:'bold'}}>{RecievedValue()}</p>
                      </div>

                      {/* <Divider style={{marginBottom:20}}/> */}


                  
                    </div>

                </div>


                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginBottom:10}}>
                  <TextField 
                    id='country-code' 
                    label='Country' 
                    variant='filled'
                    value={countryCode}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Check if the current value is either empty or a valid number
                      if (value === '' || (/^\d+$/.test(value))) {
                        setCountryCode(value);
                      }
                    }}
                    style={{ width: '80px', marginRight: '10px' }} // Make field smaller and add space
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{countryCodeToFlag[countryCode]}</span>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField 
                    id='phone-number' 
                    label='Phone Number' 
                    variant='filled'
                    value={phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Check if the current value is either empty or a valid number
                      if (value === '' || (/^\d+$/.test(value))) {
                        setPhoneNumber(value);
                      }
                    }}
                    style={{flexGrow: 1}} // Take up remaining space
                    InputProps={{
                      endAdornment: <InputAdornment position='end'><PhoneIcon /></InputAdornment>, 
                      
                    }}
                  />
                </div> 

                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                  <TextField 
                      id='filled-basic' 
                      label='Email' 
                      variant='filled'
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}

                      style={{width:'100%'}}

                      // InputProps={{
                      //   endAdornment: <InputAdornment position='end'><PaypalLogo width={50}/></InputAdornment>,
                      // }}
                  />
                </div>
               
                
                

                {!confirm && <div className={classes.dialogueButtonContainer} >
                    <Button onClick={handleClose} style={{width:90, marginRight:10}} color='primary' variant='outlined'><b>Cancel</b></Button>
                    <Button style={{width:90}} disabled={!validateEmail(email) || countryCode.length == 0 || phoneNumber.length==0} onClick={()=>{setConfirm(true)}} color='primary' variant='contained'><b>Confirm</b></Button>
                </div>}

                {confirm && <div className={classes.dialogueButtonContainer} >
                    <Button onClick={()=>{setConfirm(false)}} style={{width:90, marginRight:10}} color='primary' variant='outlined'><b>Cancel</b></Button>
                    <Button style={{width:90}} disabled={!confirm} onClick={handleRedeem} color='primary' variant='contained'><b>Send</b></Button>
                </div>}


                



            </div>


        </Dialog>

  );


}

