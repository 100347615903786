import { Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { getActiveLichessWagers, lichessWagersDB } from '../../../Firebase';
import WagerRow from './WagerRow';
import RowsLoading from '../../RowsLoading';
import ErrorCard from '../ErrorCard';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Emitter from '../../../Emitter';
import useUser from '../../../hooks/useUser';
import AvatarStack from '../../AvatarStack';
import LiveStreamerGames from '../LiveStreamGames';




export default function WagerLobby() {

    const navigate = useNavigate();


    const [user, auth, userLoading] = useUser();


    const [loading, setLoading] = useState(false);
    const [wagers, setWagers] = useState([]);




    React.useEffect(()=>{


        setLoading(true);

        const subscribe = lichessWagersDB.where('status', '==', 'ACTIVE').where('expireAt', '>', Date.now()).onSnapshot((querySnapshot) => {

            let w = [];

            let docs = querySnapshot.docs;
    
            for (var i = 0; i < docs.length; i++){

                let doc = docs[i];

                let wager = doc.data();

                wager['id'] = doc.id;

                if (wager.private == false) w.push(wager);

            }

            setWagers(w);
        
        })

        setLoading(false);

        return ()=>{
            subscribe();
        }
        
    
    },[])



    React.useEffect(()=>{

        window.scrollTo(0, 0);

        // init();

    },[])



    function Empty(){

        return(

            <div  style={{display:'flex',flexDirection:'row', alignItems:'center', borderRadius:7, justifyContent:'center'}}>
                
                <p style={{fontWeight:'bold', fontSize:14}}>None</p>

            </div>

        );

    }


    function WagerRows({w}){

        if (w == -1) return <ErrorCard/>;

        let render = [];

        for(var i = 0; i < w.length; i++){

            render.push(<WagerRow key={`wrow-${w[i]['id']}`} wager={w[i]}/>)

        }

        if (render.length == 0) render = <Empty/>

        return(
            <>
                {render}
            </>
        )

    }


    function clickCreate(){


        if (user && auth){

            navigate('/create-wager');

        } else {

            Emitter.emit('NO-AUTH', Date.now());


        }


    }


    return (

        <div style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center'}}>   

            <div style={{width:'100%'}}>

                <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end', justifyContent:'space-between'}}>
                    <p style={{ fontSize:22, fontWeight:'bold'}}>Wager Lobby</p>

                    {/* <AvatarStack spacing={-.2} avatars={['', '', '', '', '']} avatarSize={40}  maxCount={3} /> */}
                    
                    {/* <Link to='/create-wager' style={{textDecoration:'none'}}> */}
                        <Button  onClick={clickCreate} variant='contained' style={{marginBottom:18, backgroundColor:'#36AA3E'}}>
                            <AddIcon style={{fontSize:20,marginRight:5 }}/>
                            <b>Create</b>
                        </Button>
                    {/* </Link> */}
                </div>

                <Divider style={{marginBottom:20}}  />

                {loading?
                    <RowsLoading height={100}/>
                        :
                    <WagerRows w={wagers}/>

                }
                {/* <LiveStreamerGames/> */}
        
            </div>

        </div>

    );

}

