import React from 'react';
import { Divider, Skeleton} from '@mui/material';
import { numberWithCommas } from '../../../utils';
import { useLichessTournamentCardStyles } from '../../MuiStyles';
import { Link } from 'react-router-dom';
import TournamentIcon from '../Icons/TournamentIcon';
import { getLichessTournament } from "../../../Firebase";
import Results from './Results';
import { useRecoilState } from 'recoil';
import { atom_featuredTournament } from '../../../Atoms';

function TCard({tournament}) {

  const classes = useLichessTournamentCardStyles();


  function TitleText(){
    
    var name = '';

    if(tournament['perf'] == 'blitz') name = 'Super Blitz';
    if(tournament['perf'] == 'chess960') name =  'Chess 960';

    if(tournament['tag'] == 'user') name = tournament['name'];

    return name;

  }


  function ClockText(){
    if(tournament) return  `${tournament['clockTime']}+${tournament['clockIncrement']}`
  }


  function averageRating(){

    let results = tournament['results'];

    let total = 0;

    for (var i = 0; i < results.length; i++){

      let rating = results[i]['rating'];
      total += rating;
    }

    return Math.ceil(total / results.length);

  }


  function prizeFund(){

    return `$${numberWithCommas((tournament['users'].length * tournament['tickets'] + tournament['bonusTickets']))}`;

  }



  return (

    <>


      <div className='card' style={{display:'flex',  marginBottom:20, backgroundColor:'white',  flexDirection:'column'}}>

    

        <Link to={`/tournament/${tournament['id']}`} style={{textDecoration:'none'}}>
          
          <div className={classes.cardHeaderContainer}>
                    
            <div className={classes.cardHeaderContainerLeft}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <TournamentIcon size={38} perf={tournament['perf']} variant={tournament['variant']} />
              </div>
              <div className={classes.cardHeaderLeftTextContainer}>
                <p style={{fontSize:15}}>{TitleText()}</p>
                <p className={classes.subText}>{ClockText()}</p>
              </div>
            </div>
         

          </div>

        </Link>

        <div style={{display:'flex', marginBottom:10, height:75, alignItems:'center', width:'100%', flexDirection:'row', justifyContent:'space-around'}}>

            <div style={{display:'flex', width:'33.3333%' ,flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <p style={{fontSize:21, color:'#7836AA'}}>{tournament['results'].length}</p>
              <p style={{fontSize:11}}>{'Total Players'}</p>
            </div>
          
            <div style={{display:'flex', width:'33.3333%',  flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <p style={{fontSize:21, color:'#36AA3E'}}>{`${prizeFund()}`}</p>
              <p style={{fontSize:11}}>{'Prize Fund'}</p>
            </div>

            <div style={{display:'flex', width:'33.3333%', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <p style={{fontSize:21, color:'#AA3658'}}>{averageRating()}</p>
              <p style={{fontSize:11}}>{'Average Rating'}</p>
            </div>
        
          
        </div>

        <Divider />
    
        <Results tournament={tournament} limit={5} hideCard={true} />


      </div>


    </>

  );


}




export default function FeaturedTournament({tid}){

    const [tournament, setTournament] = useRecoilState(atom_featuredTournament);

    async function init(){
        let t = await getLichessTournament(tid);
        setTournament(t);
    }

    React.useEffect(()=>{

        init();

    },[]);


    return(

        <>
            {(tournament == null) ?

                <div className='c-skeleton' style={{height:319.5, marginBottom:20, borderRadius:5}}>
                  {/* <p>asads</p> */}
                </div>

                    :

                <TCard tournament={tournament}/>

            }
        </>

    )

   
}



