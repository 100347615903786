import { Button } from '@material-ui/core';
import React from 'react';
import duelIconWhite from '../art/duel-white.svg';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import { lichessWagersDB, lichessTournamentsDB, lichessTournamentsAsyncDB } from '../Firebase';
import useWindowSize from '../hooks/WindowDimensions';

function PlayButton(){


  const {width} = useWindowSize();


  const [wagerCount, setWagerCount] = React.useState(0);

  const [tournamentCount, setTournamentCount] = React.useState(0);
  const [tournamentAsyncCount, setTournamentAsyncCount] = React.useState(0);


  React.useEffect(()=>{

    const subscribeWagers = lichessWagersDB.where('status', '==', 'ACTIVE').where('expireAt', '>', Date.now()).onSnapshot((querySnapshot) => {
        let docs = querySnapshot.docs;

        let c = 0

        for (var i = 0; i < docs.length; i++){
          let doc = docs[i].data()
          if (doc['private']== true){
            continue;
          }
          c += 1;
        }
        
        setWagerCount(c);

    })

    const subscribeTournaments = lichessTournamentsDB.where('status', '==', 'ACTIVE').where('endDate', '>', Date.now()).onSnapshot((querySnapshot) => {
      let docs = querySnapshot.docs;

      let count = 0;

      for (var i = 0; i < docs.length; i++){
        let t = docs[i].data();
        if (t['teamId'] == "" && t['private'] == false){ count += 1;}
      }

      setTournamentCount(count);

    })

    const subscribeTournamentsAsync = lichessTournamentsAsyncDB.where('status', '==', 'ACTIVE').where('endDate', '>', Date.now()).onSnapshot((querySnapshot) => {
      let docs = querySnapshot.docs;
      let count = docs.length
      setTournamentAsyncCount(count);

    })

    return ()=>{
      subscribeWagers();
      subscribeTournaments();
      subscribeTournamentsAsync();
    }
    
  },[])


  return (
    <div className='floating-button' style={{display:'flex', flexDirection:'row'}}>
        <Link to={'/tournaments'}  style={{textDecoration:'None'}}>
          <Badge badgeContent={tournamentCount + tournamentAsyncCount} color="error" style={{width:170}}>
            <Button className='play-button' variant='contained' style={{width:170}}>
                <EmojiEventsIcon style={{marginRight:10, color:'white', fontSize:20}}/>
                <p  style={{padding:0, margin:0, fontSize:14, color:'white', fontWeight:'bold'}}>Tournaments</p>
            </Button>
          </Badge>
        </Link>
        <Link to={'/wager-lobby'} style={{textDecoration:'None'}}>
          <Badge badgeContent={wagerCount} color="error" style={{marginLeft:10, width:170, marginRight:(width < 400)?12:20}}>
            <Button className='play-button' variant='contained' style={{width:170, textTransform: 'lowercase' }}>
                <img style={{marginRight:10, color:'white', fontSize:20}} src={duelIconWhite} width={20} height={20}/>
                {/* <p  style={{padding:0, margin:0, fontSize:14, color:'white', fontWeight:'bold'}}> */}
                  <span style={{padding:0, margin:0, fontSize:14, color:'white', fontWeight:'bold', textTransform: 'uppercase' }}>1</span>
                  <span style={{padding:0, margin:0, fontSize:14, color:'white', fontWeight:'bold', textTransform: 'lowercase' }}>v</span>
                  <span style={{padding:0, margin:0, fontSize:14, color:'white', fontWeight:'bold', textTransform: 'uppercase' }}>1 MATCHES</span>
                {/* </p> */}
            </Button>
          </Badge>
        </Link>
    </div>
  )
}

export default PlayButton;