import { useEffect } from 'react';
import firebase from '../FirebaseClient';
import { lastSeen } from '../Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function useUser() {

    const [user, loading, error] = useAuthState(firebase.auth());

    useEffect(() => {

        if(user){
            lastSeen();
            const userId = user.uid;
            const userStatusDatabaseRef = firebase.database().ref(`/users/${userId}/online`);
            userStatusDatabaseRef.set(true);
            userStatusDatabaseRef.onDisconnect().set(false);
        }



    }, [user]);

    return [user, user?true:false, loading];

}
