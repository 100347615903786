// import React from 'react';
// import { useSupportStyles } from './MuiStyles';
// import { Divider } from '@mui/material';
// import { useRecoilValue } from 'recoil';
// import { atom_payoutFees } from '../Atoms';
// import PieChart from './PieChart';
// import { Card } from '@material-ui/core';
// import useWindowSize from '../hooks/WindowDimensions';


// export default function Policies(){

//     const classes = useSupportStyles();

//     const payoutFees = useRecoilValue(atom_payoutFees);
    
//     const {width} = useWindowSize();


//     let text1 = 'Amped Chess integrates with Lichess, the most trusted and reliable online chess platform. Lichess offers familiar gameplay and exceptional server performance crucial in high stakes matches.';
//     let text4 = 'Debit, Credit, Paypal. There is a ~3.5% fee added by the payment processor.';
//     let text5 = 'All deposits are final. If you join a tournament that does not meet the minimum player requirment, no games are played, or a valid dispute has been accepted, your entry fee will credited to your account.';


//     let spacing = 30;

//     return(
//         <div style={{display:'flex', flexDirection:'row', justifyContent:'center', padding:20, paddingTop:0}}>
//             <div  className={classes.container} style={{maxWidth:680}}>
                
//                 <p className={classes.titleText} style={{marginBottom:15}}>Lichess Gameplay</p>

//                 <Divider />

//                 <p className={classes.bodyText}>{text1}</p>

//                 <p  className={classes.titleText} style={{marginTop:spacing, marginBottom:15}}>Prize Pool</p>

//                 <Divider />

//                 <p className={classes.bodyText}>
                    // Experience the thrill of competing for a substantial prize pool. By participating in our tournaments, you have the chance to win a share of the accumulated entry fees in addition to any bonus funds. 
                    // <br/><br/>
                    // Entry fees accumulate into a pot until the tournament begins. Upon completion, prizes are awarded based on the chosen payout distribution for the event. 
                 
               



//                 </p>

//                 <p id='anti-cheatinng-policy'  className={classes.titleText} style={{marginTop:spacing, marginBottom:15}}>Anti-Cheating Measures </p>

//                 <Divider />

              

                    // <p    className={classes.bodyText}>  


                    // Amped Chess requires a 1 month minimum account age on Lichess and a variable number of games played prior to joining prized events.
                    // <br/><br/>  

                    // Payouts can be withdrawn 48 hours after prized events. This waiting period allows for dispute resolution and the automated detection of <a href='https://lichess.org/terms-of-service'>Lichess Terms of Service</a> violations, including cheating. 
                    // Tournaments and matches involving violating players or valid disputes will result in an entry fee / risked amount refund. Players using engines will be caught with high accuracy.
                    // Amped Chess recommends alternative variants, such as Chess960 and fast time controls, to limit bad actors. Payouts will not be issued until all disputes are resolved, even if it takes more than 48 hours. Amped Chess does not take responsibility for players who misrepresent their skill level or who have third parties make moves for them.

                    // <br/><br/>  
                   
                    

                
                    // To create a dispute, use the <a href='/dispute-form'>dispute form</a>.
                    
                    // <br/><br/>  

                    // Amped Chess may refund any tournament or match based on unusual game activity.

                    // <br/><br/>  

                    // In events involving unusual game activity, Amped Chess may require a skill confirmation test prior to making a payout. This skill test will involve playing additional online ranked matches within a specified deadline. In the most extreme case, livestreaming one's gameplay with a player facing camera turned on will be required.              
                    
                    // </p>


//                 <p  className={classes.titleText} style={{marginTop:spacing, marginBottom:15}}>Payment Methods</p>

//                 <Divider />

//                 <p className={classes.bodyText}>{text4}</p>

//                 <p  className={classes.titleText} style={{marginTop:spacing, marginBottom:15}}>Refunds</p>

//                 <Divider />

//                 <p className={classes.bodyText}>{text5}</p>

//                 <p  className={classes.titleText} style={{marginTop:spacing, marginBottom:15}}>Fees</p>

//                 <Divider />
                
                // <p className={classes.bodyText}>
                //     {payoutFees.wagerFee}% on wager payouts <br/><br/>
                //     {payoutFees.tournamentFee}% on tournament payouts <br/> <br/>
                //     Fee proceeds are split with tournament organizer in hosted events.
                // </p> 
                
// {/* 
//                 <div style={{display:'flex', flexDirection:(width < 500)?'column':'row', justifyContent:'center', alignItems:'center'}}>
//                     <div style={{display:'flex', marginRight:(width < 500)?0:30, flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
//                         <PieChart title={""} size={220} values={[['You', 92], ['Amped Chess', 8]]}/>
//                         <p style={{ padding:0, margin:0, marginTop:10,marginBottom:(width < 500)?15:0, fontSize:14, fontWeight:'normal'}}>1v1 Matches</p>
//                     </div>
//                     <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
//                         <PieChart title={'1v1 Matches'} size={220} values={[['You', 88], ['Host', 7.2], ['Amped Chess', 4.8]]}/>
//                         <p style={{padding:0, margin:0, marginTop:10, fontSize:14, fontWeight:'normal'}}>Tournaments</p>
//                     </div>
//                 </div> */}

               
                    

//             </div>
//         </div>
        
//     );
// }






// import React from 'react';
// import { useSupportStyles } from './MuiStyles';
// import { Divider } from '@mui/material';
// import { useRecoilValue } from 'recoil';
// import { atom_payoutFees } from '../Atoms';


// export default function Policies(){

//     const classes = useSupportStyles();

//     const payoutFees = useRecoilValue(atom_payoutFees);

//     let text1 = 'Amped Chess integrates with Lichess, the most trusted and reliable online chess platform. Lichess offers familiar gameplay and exceptional server performance crucial in high stakes matches.';
//     let text4 = 'Debit, Credit, Paypal. There is a ~3.5% fee added by the payment processor.';
//     let text5 = 'All deposits are final. If you join a tournament that does not meet the minimum player requirment, no games are played, or a valid dispute has been accepted, your entry fee will credited to your account.';


//     let spacing = 30;

//     return(
//         <div style={{display:'flex', flexDirection:'row', justifyContent:'center', padding:20, paddingTop:0}}>
//             <div  className={classes.container} style={{maxWidth:1200}}>


//                 <div className='card2' style={{borderRadius:5, marginBottom:20}}>
//                     <p className={classes.titleText} style= {{marginBottom:15, fontSize:17}}>Lichess Gameplay</p>
//                     <p className={classes.bodyText}>{text1}</p>
//                 </div>
                

//                 <div className='card2' style={{borderRadius:5, marginBottom:20}}>
//                     <p className={classes.titleText} style={{marginBottom:15, fontSize:17}}>Prize Pool</p>
//                     <p className={classes.bodyText}>
//                     Experience the thrill of competing for a substantial prize pool. By participating in our tournaments, you have the chance to win a share of the accumulated entry fees in addition to any bonus funds. 
//                     <br/><br/>
//                     Entry fees accumulate into a pot until the tournament begins. Upon completion, payouts are distributed based on the chosen payout distribution for the event. 
//                 </p>
//                 </div>

//                 <div className='card2' style={{borderRadius:5, marginBottom:20}}>
//                     <p className={classes.titleText} style={{marginBottom:15, fontSize:17}}>Anti-Cheating Measures</p>
//                       <p    className={classes.bodyText}>  


//                     Amped Chess mandates a 1 month minimum account age on Lichess and a set number of games played prior to joining prized events.
//                     <br/><br/>  

//                     Payouts can be withdrawn 48 hours after prized events. This waiting period allows for dispute resolution and the automated detection of <a href='https://lichess.org/terms-of-service'>Lichess Terms of Service</a> violations, including cheating. 
//                     Tournaments and matches involving violating players or valid disputes will result in an entry fee / risked amount refund. Players using engines will be caught with high accuracy.
//                     Amped Chess recommends alternative variants, such as Chess960 and fast time controls, to limit bad actors. Payouts will not be issued until all disputes are resolved, even if it takes more than 48 hours.

//                     <br/><br/>  
                   
                    

                
//                     To create a dispute, use the <a href='/dispute-form'>dispute form</a>.
                    
//                     <br/><br/>  

//                     Amped Chess reserves the right to refund any tournament or match based on unusual game activity.

//                     <br/><br/>  

//                     In events involving unusual game activity, Amped Chess reserves the right to require a skill confirmation test prior to making a payout. This skill test will involve playing additional online ranked matches within a specified deadline. In the most extreme case, livestreaming one's gameplay with a player facing camera turned on will be required.              
                    
//                     </p>

//                 </div>
                
//                 <div className='card2' style={{borderRadius:5, marginBottom:20}}>
//                     <p className={classes.titleText} style={{marginBottom:15, fontSize:17}}>Payment Methods</p>
//                     <p className={classes.bodyText}> {text4}</p>
//                 </div>

//                 <div className='card2' style={{borderRadius:5, marginBottom:20}}>
//                     <p className={classes.titleText} style={{marginBottom:15, fontSize:17}}>Fees</p>
//                     <p className={classes.bodyText}>
//                     {payoutFees.wagerFee}% on wager payouts <br/><br/>
//                     {payoutFees.tournamentFee}% on tournament payouts <br/> <br/>
//                     Fee proceeds are split with tournament organizer in hosted events.
//                 </p>
//                 </div>

//                 <div className='card2' style={{borderRadius:5, marginBottom:20}}>
//                     <p className={classes.titleText} style={{marginBottom:15, fontSize:17}}>Refunds</p>
//                     <p className={classes.bodyText}>{text5}</p>
//                 </div>


//             </div>
//         </div>
        
//     );
// }


import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useRecoilValue } from 'recoil';
import { atom_payoutFees } from '../Atoms';

const FAQItem = ({ question, answer, allOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const questionStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
    padding: '20px 0 20px 0', // Padding on the top, right, and bottom, none on the left
    fontSize: '16px',
    fontWeight: 'bold',
    paddingRight:0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: !(isOpen || allOpen) ? '1px solid #ccc': 'None',// Divider
    marginBottom: '0', // No margin at the bottom of the question
    fontWeight:'bold',
    textDecoration:'None',
    color:'#1f2022'
  };

  const answerStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    maxHeight: (isOpen || allOpen) ? '1000px' : '0',
    overflow: 'hidden',
    transition: 'max-height 0.1s ease',
    color:'gray',
  };

  const containerStyle = {
    marginBottom: (isOpen || allOpen) ? '20px' : '0', 
  };

  return (
    <div style={containerStyle}>
      <button
        style={questionStyle}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={(isOpen || allOpen)}
        aria-controls="faq-answer"
      >
        {question}
        {isOpen  ? <ExpandLessIcon style={{color:"gray", display:(allOpen)?'None':''}} /> : <ExpandMoreIcon style={{color:'gray', display:(allOpen)?'None':''}} />}
      </button>
      <div style={answerStyle} id="faq-answer">
        {answer}
      </div>
    </div>
  );
};

const Policies = ({allOpen}) => {
  const faqContainerStyle = {
    // maxWidth: '720px',
    // margin: 'auto',
    fontFamily: '"Arial", sans-serif',
    overflow: 'hidden',
    justifyContent:'center',
    paddingLeft:25, paddingRight:25,
   
  };

  const headingStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'left',
    // padding: '20px 0 0 0', // Padding on top and right only
  };

  let text1 = 'Amped Chess integrates with Lichess, the most trusted and reliable online chess platform. Lichess offers familiar gameplay and exceptional server performance crucial in high stakes matches.';

  const payoutFees = useRecoilValue(atom_payoutFees);

  return (
    <div style={{  display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
      <div style={faqContainerStyle}>
       {/* {showTitle && <h2 style={headingStyle}>Policies</h2>} */}
        <FAQItem
        allOpen={allOpen}
          question="Prize Pool"
          answer={<p style={{fontSize:16, padding:0, margin:0, color:'gray'}}>                    Experience the thrill of competing for a substantial prize pool. By participating in our tournaments, you have the chance to win a share of the accumulated entry fees in addition to any bonus funds. 
            <br/><br/>
            Entry fees accumulate into a pot until the tournament begins. Upon completion, prizes are awarded based on the chosen payout distribution for the event. 
         
       </p>}
        />
        
        
        <FAQItem
          question="Lichess Gameplay"
          answer={text1}
          allOpen={allOpen}
        />

       
        <FAQItem
         allOpen={allOpen}
          question="Anti-Cheating Measures"
          answer={
            <p   style={{fontSize:16, padding:0, margin:0, color:'gray'}}>  


            Amped Chess requires a 2 week minimum account age on Lichess and a variable number of games played prior to joining prized events.
            <br/><br/>  

            Payouts can be withdrawn 48 hours after prized events. This waiting period allows for dispute resolution and the automated detection of <a href='https://lichess.org/terms-of-service'>Lichess Terms of Service</a> violations, including cheating. 
            Tournaments and matches involving violating players or valid disputes will result in an entry fee / risked amount refund. Players using engines will be caught with high accuracy.
            Amped Chess recommends alternative variants, such as Chess960 and fast time controls, to limit bad actors. Payouts will not be issued until all disputes are resolved, even if it takes more than 48 hours. Amped Chess does not take responsibility for players who misrepresent their skill level or who have third parties make moves for them.

            <br/><br/>  
           
            

        
            To create a dispute, use the <a href='/dispute-form'>dispute form</a>.
            
            <br/><br/>  

            Amped Chess may refund any tournament or match based on unusual game activity.

            <br/><br/>  

            In events involving unusual game activity, Amped Chess may require a skill confirmation test prior to making a payout. This skill test will involve playing additional online ranked matches within a specified deadline. In the most extreme case, livestreaming one's gameplay with a player facing camera turned on will be required.              
            
            </p>}
        />

       
        
        <FAQItem
         allOpen={allOpen}
          question="Disqualifications"
          answer={"Amped Chess may disqualify you from any event and issue a refund if your win/loss record significantly deviates from your rating's expected outcomes. This policy is to safeguard against misrepresentation of one's skill level through 'sandbagging' or account misuse by someone other than the owner."}
        />

        <FAQItem
         allOpen={allOpen}
          question="Payment Methods"
          answer={'Debit, Credit, Paypal. There is a ~3.5% fee added by the payment processor.'}
        />

        <FAQItem
         allOpen={allOpen}
          question="Refunds"
          answer={'All deposits are final. If you join a tournament that does not meet the minimum player requirment, no games are played, or a valid dispute has been accepted, your entry fee will credited to your account.'}
        />
          <FAQItem
           allOpen={allOpen}
          question="Fees"
          answer={                
            <p  style={{fontSize:16, padding:0, margin:0, color:'gray'}}>
                {payoutFees.wagerFee}% on wager payouts <br/><br/>
                {payoutFees.tournamentFee}% on tournament payouts <br/> <br/>
                Fee proceeds are split with tournament organizer in hosted events.
            </p> }
        />
      </div>
    </div>
  );
};

export default Policies;
