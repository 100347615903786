import React, {useMemo, memo} from 'react';
import useUser from '../../hooks/useUser';



function Lobby() {


    const [user, auth, userLoading] = useUser();

    React.useEffect(()=>{
        window.scrollTo(0, 0);
    },[])


    // function Empty(){

    //     return (

    //         <div  style={{display:'flex',flexDirection:'row', alignItems:'center', borderRadius:7, justifyContent:'center'}}>

    //             <p style={{fontWeight:'bold', fontSize:14}}>None</p>

    //         </div>

    //     );

    // }






    return (

        <div style={{padding:20}}>

            <div className='card'>
                <p>Tournaments</p>
            </div>

            <div className='card'>
                <p>Wagers</p>
            </div>

           
        </div>

    );

}


export default memo(Lobby);
